import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NAV_FEATURE_KEY, REPLACE_NAV_LOCATIONS } from '@dstv-web-leanback/dstv-frontend-services'

const blacklist = ['/play', '/contentpage/']
const ignoreDuplicates = ['/contentpage/']

export const useNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { history } = useSelector((state) => state[NAV_FEATURE_KEY])

	const navigateBack = useCallback(
		(options) => {
			const newHistory = [...history]

			// remove current history item
			const currentHistoryItem = newHistory.pop()

			//
			if (
				ignoreDuplicates.some((item) => newHistory[newHistory.length - 1].pathname.includes(item)) &&
				!ignoreDuplicates.some((item) => currentHistoryItem.pathname.includes(item))
			) {
				// allow back navigation even if on blacklist if coming from another page
			} else {
				// remove all illegal entries if any
				while (blacklist.some((item) => newHistory[newHistory.length - 1]?.pathname.includes(item))) {
					newHistory.pop()
				}
			}

			// indicate back movement
			const lastItem = newHistory.pop()
			newHistory.push({
				...lastItem,
				back: true,
			})

			dispatch(REPLACE_NAV_LOCATIONS(newHistory))
			navigate(-(history.length - newHistory.length), options)
		},
		[history, navigate, dispatch]
	)

	return {
		location: history[history.length - 1] ?? { back: false, pathname: '' },
		navigate,
		navigateBack,
	}
}

export default useNavigation
