import styles from './KeyboardActionButton.module.scss'

export function KeyboardNotFocusedButton({ action, cssClass, label, spriteImage }) {
	return (
		<div className={`${styles.keyboard_action_button_wrapper} ${cssClass ? styles[cssClass] : ''}`}>
			<div
				className={`${spriteImage ? styles.keyboard_sprite_button : styles.keyboard_action_button} `}
				style={{ backgroundImage: spriteImage ? `url(${spriteImage})` : 'none' }}
			>
				{label}
			</div>
		</div>
	)
}
export default KeyboardNotFocusedButton
