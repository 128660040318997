import { useEffect, useState } from 'react'

import styles from './MidRailBillboard.module.scss'
import { Billboard, Carousel } from '@dstv-web-leanback/dstv-frontend-components'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useSelector } from 'react-redux'

export function MidRailBillboard({
	cards,
	title,
	type,
	id,
	offset,
	carouselIndex,
	initialIndex,
	saveLastFocusedChild,
	getCardDetails,
	onCarouselFocus,
	onSelect,
	features,
}) {
	const billboardState = useSelector((state) => state.billboard)
	const [billboardInfo, setBillboardInfo] = useState({
		...cards?.[0],
		poster: cards?.[0].poster_image_landscape,
	})

	const { focusKey, ref, hasFocusedChild } = useFocusable({
		trackChildren: true,
		saveLastFocusedChild: saveLastFocusedChild,
	})

	useEffect(() => {
		if (hasFocusedChild) {
			setBillboardInfo(billboardState.info)
		}
	}, [hasFocusedChild, billboardState.info])

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={`${styles.midrail_billboard_container}`} ref={ref}>
				{title && <h3 className={`${styles.title} ${hasFocusedChild ? styles.active : ''}`}>{title}</h3>}
				<div className={`${styles.midrail_billboard}`}>
					<Billboard
						detailsVisible={true}
						card={true}
						playbackEnabled={hasFocusedChild}
						billboardInfo={billboardInfo}
					/>

					<Carousel
						cards={cards}
						title={title}
						type={type}
						id={id}
						offset={offset}
						carouselIndex={carouselIndex}
						initialIndex={initialIndex}
						saveLastFocusedChild={true}
						getCardDetails={getCardDetails}
						onCarouselFocus={onCarouselFocus}
						onSelect={onSelect}
						features={features}
					/>
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default MidRailBillboard
