import styles from './Loader.module.scss'
import LottieAnimation from '../LottieAnimation/LottieAnimation'
import { FocusContext, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect } from 'react'
import { useNavigation } from '@dstv-web-leanback/dstv-frontend-services'

export function Loader({ overlay = true, focusable = false, fullscreen = false }) {
	const { navigateBack } = useNavigation()
	const { focusKey, ref, focusSelf } = useFocusable({
		focusable: focusable,
		onBackPress: () => {
			navigateBack()
		},
	})
	useEffect(() => {
		focusable && focusSelf()
	}, [focusSelf])

	return (
		<FocusContext.Provider value={focusKey}>
			<div
				ref={ref}
				className={`${styles.tv_loader__wrapper} ${overlay && styles.overlay} ${fullscreen ? styles.full_screen : ''}`}
			>
				<LottieAnimation loop={true} style={{ height: '9vw' }} />
			</div>
		</FocusContext.Provider>
	)
}
export default Loader
