import { useEffect } from 'react'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import { SidePanelItem } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './SidePanel.module.scss'

export const SidePanel = ({ options, handleBack, title, subTitle }) => {
	const { focusKey, ref } = useFocusable({
		focusKey: 'SIDE_PANEL',
		isFocusBoundary: true,
		preferredChildFocusKey: 'SIDE_PANEL_ITEM_0',

		onBackPress: handleBack,
	})

	useEffect(() => {
		setFocus(focusKey)
	}, [options])

	return (
		<div className={styles.sidepanel_container}>
			<FocusContext.Provider value={focusKey}>
				<div ref={ref} className={styles.sidepanel}>
					<div className={styles.sidepanel_details}>
						<div className={styles.title}>{title}</div>
						<div className={styles.sub_title}>{subTitle}</div>
					</div>
					<div className={styles.sidepanel_options}>
						{options.map((option, i) => (
							<SidePanelItem {...option} index={i} />
						))}
					</div>
				</div>
			</FocusContext.Provider>
		</div>
	)
}

export default SidePanel
