import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	UPDATE_SEARCH_KEY,
	GET_ALL_SEARCH_RESULTS,
	GET_SEARCH_SUGGESTIONS,
	SEARCHED,
} from '@dstv-web-leanback/dstv-frontend-services'
import styles from './SearchInput.module.scss'
import { useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

export function SearchInput({ suggestionSelected, ...props }) {
	const dispatch = useDispatch()
	const keyboardState = useSelector((state) => state.keyboard)
	const searchState = useSelector((state) => state.search)

	const onSearchKeyUpdate = () => {
		searchState.searchKey?.length > 0 && dispatch(GET_ALL_SEARCH_RESULTS({ searchKey: searchState.searchKey }))
		searchState.searchKey?.length > 0 &&
			!suggestionSelected &&
			dispatch(GET_SEARCH_SUGGESTIONS({ searchKey: searchState.searchKey }))
		searchState.searchKey?.length > 0 &&
			dispatch(SEARCHED({ searchKey: searchState.searchKey, suggestionSelected: suggestionSelected }))
	}

	const searchDebounce = useDebounce(1000, undefined, onSearchKeyUpdate)

	useEffect(() => {
		searchDebounce()
	}, [searchState.searchKey])

	useEffect(() => {
		dispatch(UPDATE_SEARCH_KEY({ searchKey: keyboardState.value, suggestionSelected }))
	}, [keyboardState.value])

	return (
		<div className={`${styles['TvSearchBar__search_bar']} ${styles.active}`}>
			<span className={styles['search_input']}>
				<span className={styles['search_placeholder']}>
					<input
						type="text"
						className={styles['center']}
						placeholder="Search by actor, title, genre, cast"
						value={keyboardState.value}
						readOnly
					/>
				</span>
			</span>
		</div>
	)
}
export default SearchInput
