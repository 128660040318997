import styles from './KeyboardActionButton.module.scss'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function KeyboardActionButton({ action, cssClass, label, selectAction, spriteImage, focusKey }) {
	const { ref, focused } = useFocusable({
		onEnterPress: () => {
			selectAction(action)
		},
		focusKey: `KEYBOARD_${focusKey.toUpperCase()}_BUTTON`,
	})

	return (
		<div ref={ref} className={`${styles.keyboard_action_button_wrapper} ${cssClass ? styles[cssClass] : ''}`}>
			<div
				className={`${spriteImage ? styles.keyboard_sprite_button : styles.keyboard_action_button} ${
					focused ? styles.focus : ''
				}`}
				style={{ backgroundImage: spriteImage ? `url(${spriteImage})` : 'none' }}
			>
				{label}
			</div>
		</div>
	)
}
export default KeyboardActionButton
