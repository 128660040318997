// FloatingGuide.js

import styles from './FloatingGuide.module.scss'

export const FloatingGuide = () => {
	return (
		<div className={styles.FloatingGuide_floatingGuide}>
			<div className={styles.FloatingGuide_floatingContainer}>
				<span>Hold</span>
				<span className={styles.hold_prompt}>OK</span>
				<span>for more options</span>
			</div>
		</div>
	)
}
