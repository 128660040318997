import { DEVICE_ID, HARDWARE_ID } from './constants'
import { getUserID, saveToLocalStorage } from './storageHelper'
import SHA1 from 'crypto-js/sha1'

export const generateDeviceID = (hardwareId) => {
	try {
		let preparedString = `${hardwareId}${getUserID()}`.toLowerCase()
		let deviceId = SHA1(preparedString).toString()
		saveToLocalStorage(HARDWARE_ID, hardwareId)
		saveToLocalStorage(DEVICE_ID, deviceId)
	} catch (error) {
		console.error('Could not generate device ID.')
	}
}
