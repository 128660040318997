import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Keyboard, getNewProfileDetails } from '@dstv-web-leanback/dstv-frontend-components'
import { PROFILE_FEATURE_KEY, SET_NEW_PROFILE_DETAILS, useNavigation } from '@dstv-web-leanback/dstv-frontend-services'

import styles from './ProfileNameEdit.module.scss'
import dstvLogo from '../../../../assets/images/dstv_logo_fallback.png'

export function ProfileNameEdit(props) {
	const { id } = useParams()
	const location = useLocation()

	let fromSave = location.pathname.includes('/save/edit/name/new')
	const { navigateBack } = useNavigation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const profiles = useSelector((state) => state[PROFILE_FEATURE_KEY].data?.items)
	const keyboardState = useSelector((state) => state.keyboard)
	const newProfile = useSelector((state) => state[PROFILE_FEATURE_KEY].newProfile)

	const isNewProfile = id === 'new'

	let selectedProfile = null

	if (!isNewProfile) {
		selectedProfile = profiles?.find((item) => item.id === id)
	}
	let { alias, avatarUri } = getNewProfileDetails(selectedProfile, newProfile)

	return (
		<div className={styles.profile__name__component}>
			<img className={styles.dstv_logo} alt="DStv logo" src={dstvLogo} />
			<div className={styles.info__container}>
				<img alt="Selected Avatar" className={styles.avatar} src={avatarUri} />
			</div>

			<div className={styles.grid__container}>
				<div className={styles.title}>{isNewProfile ? 'Enter your name' : 'Edit your name'}</div>
				<div>
					<div className={styles.input}>{keyboardState.value ? keyboardState.value : 'Name'}</div>
					<Keyboard
						initialValue={alias}
						maxLength={10}
						actions={['space', 'clear', 'case', keyboardState.value ? 'ok' : 'ok_not_focused']}
						okAction={() => {
							if (keyboardState.value) {
								dispatch(SET_NEW_PROFILE_DETAILS({ newProfile: { alias: keyboardState.value } }))
								navigate('/profiles/save/' + id)
							}
						}}
						backAction={() => {
							if (fromSave && id === 'new') {
								navigateBack()
							} else if (id === 'new') {
								navigate('/profiles/' + id)
							} else {
								navigate('/profiles/')
							}
						}}
					/>
				</div>
			</div>
		</div>
	)
}
export default ProfileNameEdit
