import styles from './Synopsis.module.scss';
export function Synopsis({ synopsis }) {
	return (
		<div className={styles['container']}>
			{synopsis && (
				<section className={styles.synopsis_wrap}>
					<p className={styles.heading}>
						{synopsis.heading}
						<span className={styles.rating}>{synopsis.rating}</span>
					</p>
					<p className={styles.synopsis}>{synopsis.details}</p>
				</section>
			)}
		</div>
	);
}
export default Synopsis;
