import { useEffect, useRef, useState } from 'react'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useSelector } from 'react-redux'

import { SkipArrow } from '@dstv-web-leanback/dstv-frontend-components'
import { useNavigation, configKeyEnabled } from '@dstv-web-leanback/dstv-frontend-services'

import iconRewind from '../../../../assets/images/icons/icon-player-rewind.svg'
import iconForward from '../../../../assets/images/icons/icon-player-forward.svg'

import styles from '../PlayerControls.module.scss'

export function VODSkipControls({ video, seekTo, showControls, stopStream }) {
	let skippingTimer = useRef(0)

	const SKIP_INTERVAL = 10
	const SKIP_CAP = 60

	const [skipping, setSkipping] = useState(false)
	const [skipAmount, setSkipAmount] = useState(0)
	const [skipDirection, setSkipDirection] = useState(null)
	const configState = useSelector((state) => state.config)
	const stopStreamEnabled = configKeyEnabled(configState, 'stop_stream_enabled') ?? true

	const { navigateBack } = useNavigation()

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'VOD_SKIP_CONTROLS',
		isFocusBoundary: true,
		onBackPress: () => {
			navigateBack()
			if (stopStreamEnabled) {
				stopStream()
			}
		},
	})

	// Effects
	useEffect(() => {
		focusSelf()
	}, [])

	// Functions
	const handleArrowPress = (direction) => {
		switch (direction) {
			case 'left':
			case 'right':
				handleSkip(direction)
				break
			default:
				showControls()
				setFocus('PLAY_PAUSE')
				break
		}
	}

	const handleSkip = (direction) => {
		let amount = skipAmount

		if (direction === skipDirection) {
			if (skipAmount < SKIP_CAP) {
				amount = skipAmount + SKIP_INTERVAL
			}
		} else {
			amount = SKIP_INTERVAL
		}

		setSkipAmount(amount)
		setSkipDirection(direction)
		setSkipping(true)

		clearTimeout(skippingTimer.current)
		skippingTimer.current = setTimeout(() => {
			seekTo(video.currentTime + (direction === 'left' ? -amount : amount))
			setSkipAmount(0)
			setSkipping(false)
		}, 1000)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.skip_controls_wrapper}>
				<SkipArrow
					skipDirection={skipDirection}
					direction="left"
					icon={iconRewind}
					handleArrowPress={handleArrowPress}
					skipAmount={skipAmount}
					skipping={skipping}
				/>
				<SkipArrow
					skipDirection={skipDirection}
					direction="right"
					icon={iconForward}
					handleArrowPress={handleArrowPress}
					skipAmount={skipAmount}
					skipping={skipping}
				/>
			</div>
		</FocusContext.Provider>
	)
}
export default VODSkipControls
