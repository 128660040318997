import { useEffect } from 'react'
import styles from './Chip.module.scss'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function Chip({
	active,
	selected = false,
	id,
	onItemFocus,
	onSelect,
	title,
	handleArrow,
	selectedStyles = null,
	onLoadScroll,
}) {
	const { ref, focused, focusKey } = useFocusable({
		onEnterPress: onSelect,
		onFocus: () => onItemFocus(ref),
		focusKey: `CHIP_${id.toString()?.toUpperCase()}`,
		onArrowPress: (direction) => {
			handleArrow?.(direction)
		},
	})

	useEffect(() => {
		if (selected) onLoadScroll(ref, focusKey)
	}, [selected])

	return (
		<div
			ref={ref}
			className={`${styles.chip} ${selected && !focused ? selectedStyles || styles.trayHighlight : ''} ${
				active || focused ? styles.active : ''
			}`}
		>
			{title && <span className={`${styles.chip_text} ${active || focused ? styles.active : ''}`}>{title}</span>}
		</div>
	)
}
export default Chip
