import { useEffect, useMemo, useState } from 'react'
import { useFocusable, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'
import {
	ProgressBar,
	navigateToShowPage,
	navigateToContentPage,
	getTimePassedInSeconds,
} from '@dstv-web-leanback/dstv-frontend-components'
import play_icon from '../../../../assets/images/smarttv/play_cw_icon.svg'
import play_icon_active from '../../../../assets/images/smarttv/play_cw_focus_icon.svg'
import thumbFallback from '../../../../assets/images/thumb_fallback.png'
import { useDispatch, useSelector } from 'react-redux'
import {
	GET_GRID_LIST,
	GET_PREVIOUS_INDEX,
	getCardLogoURL,
	getVideoId,
	NAV_PUSH,
	replaceVirtualLinks,
	useNavigation,
} from '@dstv-web-leanback/dstv-frontend-services'
import TrailerPlayer from '../../TrailerPlayer/TrailerPlayer'
import { dateUtils, useCancelDebounce } from '@dstv-web-leanback/dstv-frontend-utils'
import styles from './CardVOD16x9.module.scss'
import Image from '../../../core/Image/LazyImage'

export function CardVOD16x9({
	index,
	carouselIndex,
	features,
	rowTitle,
	item,
	rowId,
	key,
	cardType,
	itemFocus,
	type,
	from,
	currentSection,
	viewAll,
	showFloatingGuide,
	...rest
}) {
	const dispatch = useDispatch()
	const billboardState = useSelector((state) => state.billboard)
	const { location, navigate } = useNavigation()
	const { onSelect, watchListGrid } = rest
	const LONG_PRESS_DURATION = 1000

	const [stateVideo, setStateVideo] = useState(false)
	const [isTrailerPlaying, setIsTrailerPlaying] = useState(false)
	const [playStartTime, setPlayStartTime] = useState(null)
	const [channelLogoSrc, setChannelLogoSrc] = useState(getCardLogoURL(item, 'channel_logo'))

	const [debouncePress, debouncePressCancel, debounceReset] = useCancelDebounce(LONG_PRESS_DURATION, (longPress) => {
		if (currentSection !== 'mystuff' && !watchListGrid) {
			if (!hasPlayIcon && cardType !== 'contentpage' && item.type !== 'categories') {
				let autoplayTime
				if (features?.includes('play_in_billboard') || features?.includes('play_in_container')) {
					autoplayTime = getTimePassedInSeconds(billboardState.playStartTime)
				} else if (features?.includes('play_in_card')) {
					autoplayTime = getTimePassedInSeconds(playStartTime)
				}
				dispatch(
					NAV_PUSH({
						...item,
						carouselIndex,
						rowTitle,
						features,
						autoplayTime,
						key: getCurrentFocusKey(),
						location: location.pathname,
						rowIndex: carouselIndex,
						rowId: rowId,
						id: item?.id,
						index: index,
					})
				)
				navigateToShowPage(item, location, navigate, getVideoId)
			} else {
				if (longPress && showFloatingGuide) {
					if (
						(['resume', 'watch'].includes(item?.resume_state) &&
							item?.resume_video_id &&
							item?.id &&
							!item?.playerDetails &&
							onSelect) ||
						item.type === 'recording'
					) {
						onSelect({ ...item, carouselIndex, rowTitle, features, rowId, index, longPress: true })
					} else if (cardType === 'contentpage') {
						onSelect({ ...item, carouselIndex, rowTitle, features, rowId, index, longPress: true })
					}
				} else {
					onSelect({ ...item, carouselIndex, rowTitle, features, rowId, index, longPress: false })
				}
			}
		} else {
			dispatch(
				NAV_PUSH({
					key: getCurrentFocusKey(),
					location: location.pathname,
					rowIndex: carouselIndex,
					rowId: rowId,
					id: item?.id,
					index: index,
					rowTitle,
				})
			)
			if (item.type === 'categories') {
				dispatch(
					GET_GRID_LIST({
						url: replaceVirtualLinks(item.links[0].href),
						title: rowTitle,
						description: item.description,
						poster_image: item.poster_image_landscape,
					})
				)
				navigate('/mystuff/grid', { state: { key: getCurrentFocusKey() } })
			} else {
				if (longPress && showFloatingGuide) {
					onSelect({ ...item, carouselIndex, rowTitle, features, rowId, index, longPress: true })
				} else if (item.type === 'layout' || item.type === 'event') {
					onSelect({ ...item, carouselIndex, rowTitle, features, rowId, index, longPress: false })
				} else {
					navigateToShowPage(item, location, navigate, getVideoId)
				}
			}
		}
	})

	const { ref, focused } = useFocusable({
		onFocus: () => {
			debounceReset()
			handleVideoPlayback(false)
			if (ref) itemFocus(ref, item, index)
			if (rest.getCardDetails && !item.fetched_info) {
				getCardDetails(item, rowId, index)
			}
			if (cardType === 'containedcard') {
				dispatch(GET_PREVIOUS_INDEX({ index: undefined }))
			}
		},
		onBlur: () => {
			if (cardType === 'containedcard') {
				dispatch(GET_PREVIOUS_INDEX({ index: index }))
			}
		},
		onEnterPress: () => {
			debouncePress()
		},
		onEnterRelease: () => {
			debouncePressCancel()
		},
		focusKey: `CARD_${rowId ? rowId : ''}${index}`,
	})

	const { getCardDetails, showTitle } = rest

	useEffect(() => {
		if (index === 0 && item?.tracking_info?.row === 0) getCardDetails?.(item, rowId)
	}, [])

	const bigCard = cardType === 'big16x9'
	const vodWithProgress = cardType === 'vod_with_progress'
	const containedCard = cardType === 'containedcard'
	const episodecard = cardType === 'episodecard16x9'
	const recording = item?.type === 'event'
	const trailerUrl = item?.trailerDetails
	const hasPlayIcon = (item.resume_state && vodWithProgress) || episodecard || recording
	const hasTrailerPlayback = bigCard && focused && isTrailerPlaying

	const handleVideoPlayback = (data) => {
		setStateVideo(data)
		setIsTrailerPlaying(false)
	}

	const handleTrailerStart = (video) => {
		video.play()
		setIsTrailerPlaying(true)
		const startTime = new Date()
		setPlayStartTime(startTime)
	}

	const fallbackTitleStyle = useMemo(
		() => (!item?.poster_image_landscape ? styles.trailer_title_fallback : styles.hide_title),
		[item]
	)

	const handleImageError = (e, channel_logo) => {
		e.target.onerror = null
		if (channel_logo) {
			setChannelLogoSrc(null)
		}
	}

	return (
		<div
			ref={ref}
			className={`${styles.card_container} ${bigCard && styles.bigcard_container} ${focused ? styles.active : ''}`}
		>
			<div className={`${containedCard ? styles['contained_card_image_container'] : styles['card_image_container']}`}>
				{!hasTrailerPlayback && (
					<Image
						className={styles['card_image']}
						src={
							episodecard
								? item?.images[1]?.href
								: item?.images[0]
								? item?.images[0]?.href
								: item?.poster_image_landscape || thumbFallback
						}
						alt="Card Poster"
						loading="lazy"
						fallback={thumbFallback}
					/>
				)}
				{trailerUrl !== undefined && bigCard && focused && !stateVideo ? (
					<TrailerPlayer url={trailerUrl} videoEnd={handleVideoPlayback} onPlay={handleTrailerStart} />
				) : (
					''
				)}
			</div>

			<div className={styles['card_overlay']}>
				{recording && (
					<div className={styles.channel_logonumber}>
						{channelLogoSrc ? (
							<img
								className={styles.channel_logo_icon}
								alt="Channel logo"
								src={channelLogoSrc}
								loading="lazy"
								onError={(e) => handleImageError(e, true)}
							/>
						) : null}
						<div className={styles.channel_no}>{item?.number ? item.number : item.channel_number}</div>
					</div>
				)}
				{hasPlayIcon && (
					<img
						alt=""
						className={`${styles.card_playButton} ${styles.centered_both}`}
						src={focused ? play_icon_active : play_icon}
					/>
				)}
				{item?.label ? <p className={styles.event_label}>{item.label.text}</p> : null}
				{vodWithProgress && (
					<p className={styles.time_left}>{dateUtils.convertMinsToHrsMins(item.resume_remaining_mins)}</p>
				)}
				{vodWithProgress && item.resume_state && item.resume_progress_percentage > -1 && (
					<div className={`${styles.progress_wrapper} ${vodWithProgress && styles.centered}`}>
						<ProgressBar progress={item.resume_progress_percentage} active={focused ? true : false} />
					</div>
				)}
			</div>
			{(vodWithProgress || showTitle) && <span className={styles.card_title}>{item.title}</span>}
			{vodWithProgress && item.season_number && item.episode_number ? (
				<span className={styles.card_metadata}>
					S{item.season_number} Ep{item.episode_number}
				</span>
			) : (
				''
			)}
			{bigCard && <div className={focused ? styles.trailer_title : fallbackTitleStyle}>{item.title}</div>}
			{showTitle && item.duration_in_seconds && !item.season_number ? (
				<span className={styles.time_info}>{dateUtils.convertSecsToHrsMins(item.duration_in_seconds)}</span>
			) : (
				''
			)}
		</div>
	)
}
export default CardVOD16x9
