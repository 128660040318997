import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusable, FocusContext, setFocus, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'

import {
	DialogFull,
	SearchInput,
	Keyboard,
	Loader,
	Toast,
	toastInfo,
	ChipList,
	SearchContainer,
} from '@dstv-web-leanback/dstv-frontend-components'

import {
	CLEAR_KEYBOARD,
	CLEAR_SEARCH,
	SET_KEYBOARD_VALUE,
	GET_SEARCH_LANDING_CONTENT,
	useNavigation,
} from '@dstv-web-leanback/dstv-frontend-services'

import { focusMainNavigation } from '../../utils/helper'
import styles from './Search.module.scss'

export function Search() {
	const dispatch = useDispatch()
	const { location, navigateBack } = useNavigation()

	const searchState = useSelector((state) => state.search)
	const keyboardState = useSelector((state) => state.keyboard)

	const [searchContentRow, setSearchContentRow] = useState([])
	const [selectedChip, setSelectedChip] = useState(searchState?.selectedChip)
	const [showToast, setShowToast] = useState(false)
	const [autoFocus, setAutoFocus] = useState(!!searchState?.selectedChip)

	// Functions
	const onChipSelect = (suggestion) => {
		dispatch(SET_KEYBOARD_VALUE({ value: suggestion.title }))
		setSelectedChip(suggestion.title)
		setAutoFocus(true)
	}

	const { focusKey, ref, focusSelf } = useFocusable({
		onBackPress: () => {
			setFocus('KEYBOARD_KEY_0_a')
			focusMainNavigation()
			dispatch(CLEAR_KEYBOARD())
			dispatch(CLEAR_SEARCH())
		},
	})

	useEffect(() => {
		if (keyboardState.value?.length === 0) {
			dispatch(CLEAR_SEARCH())
			setSelectedChip(null)
		}
	}, [keyboardState])

	useEffect(() => {
		if (searchState?.contents?.length) {
			setSearchContentRow(searchState.contents)
		}
	}, [searchState.contents])

	useEffect(() => {
		if (autoFocus && selectedChip) {
			if (searchState?.items?.length) {
				setFocus('CARD_00')
			}
			setAutoFocus(false)
		}
		focusSelf()
	}, [searchState.items, focusSelf])

	useEffect(() => {
		if (location.back && location.focusKey && location.pathname?.includes?.('search')) {
			setFocus(location.focusKey)
			if (!getCurrentFocusKey()) {
				focusSelf()
			}
		}
	}, [location.back, focusSelf])

	const handleCloseToast = () => {
		setShowToast(false)
	}

	const handleSetToast = (value) => {
		setShowToast(value)
	}

	if (searchState.error) {
		return (
			<DialogFull
				error={searchState.error}
				serverError={searchState.serverError}
				retryAction={() => {
					dispatch(GET_SEARCH_LANDING_CONTENT())
					focusMainNavigation()
				}}
				backAction={navigateBack}
			/>
		)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={styles.page_search} ref={ref}>
				<SearchInput suggestionSelected={autoFocus} />
				{searchState?.isLoading && <Loader focusable={true} fullscreen={true} />}
				<Toast
					show={showToast}
					duration={5000}
					message={searchState.toastMessage}
					icon={toastInfo}
					className={styles.search_coming_up_toast}
					onClose={handleCloseToast}
				/>
				{keyboardState.value?.length && searchState?.notFound ? (
					<div className={styles.text_item}>
						<span className={styles.no_search_text}>No results for {keyboardState.value}.</span>
					</div>
				) : null}
				{searchState?.suggestions?.length && searchState?.items?.length ? (
					<div className={styles.suggestion_container}>
						<ChipList
							chips={searchState.suggestions}
							onSelectItem={onChipSelect}
							selectedChip={selectedChip}
							selectedStyles={styles.selected}
						/>
					</div>
				) : null}
				<div className={styles['search_container']}>
					<div className={styles['keyboard_grid']}>
						<Keyboard actions={['space', 'symbols', 'clear', 'delete']} />
					</div>

					<div
						className={`${styles.grid_wrapper} ${searchState?.items?.length ? styles.grid_with_search : ''}
							${keyboardState.value?.length && searchState?.notFound ? styles.content_with_no_result : ''}`}
					>
						<SearchContainer
							searchContents={searchContentRow}
							searchedItems={searchState?.items}
							handleSetToast={handleSetToast}
						></SearchContainer>
					</div>
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default Search
