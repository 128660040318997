/**
 * @constant HAS_SYMBOL_SUPPORT are Symbols supported
 */
export const HAS_SYMBOL_SUPPORT = typeof Symbol === 'function' && typeof Symbol.for === 'function'

/**
 * @constant REACT_ELEMENT the symbol / number specific to react elements
 */
const REACT_ELEMENT = HAS_SYMBOL_SUPPORT ? Symbol.for('react.element') : 0xeac7

/**
 * @constant REGEX_INT Regex to test for Integers
 */
export const REGEX_INT = /^[-\d]+$/g

/**
 * @constant isArray extracted Array.isArray
 */

export const isArray = Array.isArray
/**
 *
 * @function isFunction
 *
 * @description
 * is the datatype a function
 *
 * @param obj the datatype to test
 * @returns is the datatype a function
 */
export const isFunction = (obj) => !!obj && typeof obj === 'function'

/**
 *
 * @function isObject
 *
 * @description
 * is the path an object
 *
 * @param obj the datatype to test
 * @returns is the datatype an object
 */
export const isObject = (obj) => !!obj && typeof obj === 'object'

/**
 *
 * @function isIndex
 *
 * @description
 * is the datatype an index path value
 *
 * @param obj the datatype to test
 * @returns is the datatype an index path value
 */
export const isIndex = (obj) => ['string', 'number', 'array'].includes(getType(obj))
/**
 *
 * @function isIndexable
 *
 * @description
 * is the datatype an Array or Object
 *
 * @param obj the datatype to test
 * @returns is the datatype an Array or Object
 */
export const isIndexable = (obj) => ['array', 'object'].includes(getType(obj))

/**
 *
 * @function isString
 *
 * @description
 * is the datatype an index path value
 *
 * @param obj the datatype to test
 * @returns is the datatype a string
 */
export const isString = (obj) => !!obj && typeof obj === 'string'

/**
 *
 * @function isNumber
 *
 * @description
 * is the datatype an index path value
 *
 * @param obj the datatype to test
 * @returns is the datatype a string
 */
export const isNumber = (obj) => !!obj && typeof obj === 'number'

/**
 *
 * @function isStrNum
 *
 * @description
 * is the datatype an index path value
 *
 * @param obj the datatype to test
 * @returns is the datatype a string
 */
export const isStrNum = (obj) => isString(obj) || isNumber(obj)

/**
 *
 * @function isPlainObject
 *
 * @description
 * is the datatype a plain object. This is important for serializability
 *
 * @param obj the datatype to test
 * @returns is the datatype a plain object
 */
export const isPlainObject = (obj) =>
	!isReactElement(obj) && isObject(obj) && ['[object Object]', '[object Array]'].includes(tag(obj))

/**
 *
 * @function isNullOrUndefined
 *
 * @description
 * is the datatype null or undefined
 *
 * @param obj the datatype to test
 * @returns is the datatype a null or undefined
 */
export const isNullOrUndefined = (obj) => obj === null || obj === undefined
export const isNotNullUndefined = (obj) => obj !== null && obj !== undefined
/**
 *
 * @function isReactElement
 *
 * @description
 * is the datatype a ReactElement
 *
 * @param obj the datatype to test
 * @returns is the datatype a ReactElement
 */
export const isReactElement = (obj) => obj.$$typeof === REACT_ELEMENT

export const getType = (obj) => !!obj && (isArray(obj) ? 'array' : typeof ob)
export const tag = (v) => Object.prototype.toString.call(v, v)

/**
 * @function isCloneable
 *
 * @description
 * is the object passed considered cloneable
 *
 * @param object the object that is being checked for cloneability
 * @returns whether the object can be cloned
 */
export const isCloneable = (object) =>
	!isReactElement(object) && isObject(object) && ['[object Date]', '[object RegExp]'].includes(tag(object))

/**
 * @function isEmptyPath
 *
 * @description
 * is the path passed an empty path
 *
 * @param path the path to check for emptiness
 * @returns whether the path passed is considered empty
 */
export const isEmpty = (obj) =>
	!!obj && (Array.isArray(obj) || isFunction(obj) || isString(obj) ? obj : Object.keys(obj)).length > 0
export const isEmptyPath = (path) => path === null || (isArray(path) && !path.length)

/**
 * @function isGlobalConstructor
 *
 * @description
 * is the fn passed a global constructor
 *
 * @param fn the fn to check if a global constructor
 * @returns whether the fn passed is a global constructor
 */
export const isGlobalConstructor = (fn) => isFunction(fn) && !!~tag(fn).indexOf('[native code]')

export const isNull = (obj) => obj === null
export const isBoolean = (obj) => getType(obj) === 'boolean'
export const isUndefined = (obj) => getType(obj) === 'undefined'
export const isInteger = (obj) => (isNumber(obj) ? Number.isSafeInteger(obj) : REGEX_INT.test(`${obj}`))
export const isFalse = (obj) => isBoolean(obj) === false
export const isSimpleType = (obj) => /(string|number|boolean)/i.test(typeof obj)
export const isPropEnum = (obj, k) => Object.prototype.propertyIsEnumerable.call(obj, k)
export const isOwnProp = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key)
export const isThenable = (obj) => isObject(obj) && isOwnProp(obj, 'then') && isFunction(obj.then)
