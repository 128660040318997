import styles from './BackToLiveButton.module.scss'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function BackToLiveButton({ id, onItemFocus, onSelect, title }) {
	const { ref, focused } = useFocusable({
		onEnterPress: onSelect,
		onFocus: onItemFocus,
		focusKey: `BUTTON_${id.toString()?.toUpperCase()}`,
	})

	return (
		<div ref={ref} className={`${styles.button} ${focused ? styles.active : ''}`}>
			<span className={`${styles.dot} ${focused ? styles.invert : ''}`}></span>
			<span className={`${styles.title} ${focused ? styles.focus : ''}`}>{title}</span>
		</div>
	)
}
export default BackToLiveButton
