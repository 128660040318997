import moment from 'moment'

export const formatTimeToHoursAndMinutes = (time) => {
	return moment(time).format('HH:mm')
}

export const getTimeBetweenDates = (date1, date2, unit = 'minutes') => {
	return date1.diff(date2, unit)
}

export const convertMinsToHrsMins = (minutes) => {
	if (minutes < 60) {
		return `${minutes}m left`
	} else {
		let hours = Math.floor(minutes / 60)
		let mins = minutes % 60
		return `${hours}h ${mins > 0 ? mins + 'm' : ''} left`
	}
}

export const convertSecsToHrsMins = (seconds) => {
	if (!seconds) return null

	const hours = Math.floor(seconds / 3600)
	const mins = Math.floor((seconds % 3600) / 60)

	if (hours > 0) {
		return `${hours}h ${mins}m`
	} else if (mins > 0) {
		return `${mins}m`
	} else {
		return `${seconds}s`
	}
}

export const formatDate = (date, formatType = null) => {
	if (!date) return ''

	const now = moment()
	const formatDate = moment(date)
	if (formatType) return formatDate.format(formatType)

	const diffDays = formatDate.startOf('day').diff(now.startOf('day'), 'days')

	if (diffDays === -1) return 'Yesterday'
	if (diffDays === 0) return 'Today'
	if (diffDays === 1) return 'Tomorrow'
	if (diffDays > 1) return formatDate.format('MMM D')

	return ''
}
