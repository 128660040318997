import base64 from 'base64-js'
import {
	getAdsPlatformName,
	getEpisodeDisplayItemTitle,
	getOSVersion,
	getSeasonEpisodeDetails,
	isLiveEvent,
} from '@dstv-web-leanback/dstv-frontend-services'
import moment from 'moment'

export const getPulseAdsConfig = (ssaiEnabled, type, pid, userPackage, adRequest) => {
	const isLinearScheduling = ssaiEnabled?.payload?.scheduling === 'Linear'
	const adOptions = isLinearScheduling ? ssaiEnabled.payload.status ?? '' : ''

	const tagInfo = `PA:${userPackage}${adOptions ? `,AO:${adOptions}` : ''}`

	let tags = tagInfo

	if (adRequest) {
		let tagsInfoAd = `${adRequest?.t.join(',')},${tagInfo}`.replace(/\s+/g, '')
		tags = tagsInfoAd
	}
	const platform = getAdsPlatformName()
	const userAgentVersion = getOSVersion()

	const baseConfig = {
		pid: pid,
		dcid: platform,
		t: tags,
		pf: 'html5_' + userAgentVersion,
	}

	if (ssaiEnabled?.flagValue === 'true' && type === 'live') {
		return preparePulseAdParameters({
			...baseConfig,
			gdpr: 0,
			gdpr_consent: 0,
			gdpr_pd: 0,
		})
	}

	return preparePulseAdParameters({
		...baseConfig,
		acp: {
			package: userPackage,
		},
	})
}

export const preparePulseAdParameters = (pulseAdsConfig) => {
	let queryParam = ''

	Object.keys(pulseAdsConfig).forEach((key) => {
		if (
			typeof pulseAdsConfig[key] === 'object' &&
			pulseAdsConfig[key] !== null &&
			Object.keys(pulseAdsConfig[key]).length > 0
		) {
			Object.keys(pulseAdsConfig[key]).forEach((j) => {
				queryParam += (!queryParam ? '' : '&') + (key + '.' + j) + '=' + pulseAdsConfig[key][j]
			})
		} else {
			queryParam += (!queryParam ? '' : '&') + key + '=' + pulseAdsConfig[key]
		}
	})

	const textEncoder = new TextEncoder()
	const bytes = textEncoder.encode(queryParam)
	const encodedString = base64.fromByteArray(bytes)

	return encodedString
}
export const getPlayerUrl = (playerState) => {
	let playerUrl = null

	if (playerState.isReverseEpgEvent && !playerState.watchFromStartLive) {
		playerUrl = playerState.details.reverseEpgEventUrl
	} else if (playerState.watchFromStartLive) {
		playerUrl = playerState.timeShiftUrl
	} else {
		playerUrl = playerState.url
	}

	return playerUrl
}

export const getStreamUrl = (type, manifestUrl, ucp_filter, cdnAuthToken, ssaiQueryParam, isSSAIStream) => {
	const hdcpFilters = ucp_filter.replace('?', '')
	const pulseAdsSSAIStr = isSSAIStream ? `ssai=${ssaiQueryParam}` : ''
	const watermarkingToken = `hdnts=${encodeURIComponent(cdnAuthToken)}`

	const [url, ...queryParams] = manifestUrl.split('?')
	const playerUrl = `${url}/.mpd`

	return buildURL(
		playerUrl,
		type === 'live' ? [pulseAdsSSAIStr, hdcpFilters, watermarkingToken, ...queryParams] : [hdcpFilters, ...queryParams]
	)
}

function buildURL(baseUrl, queryParams) {
	const queryString = queryParams.filter((param) => param.length).join('&')
	return `${baseUrl}?${queryString}`
}

export const isTimeShift = (playerState) => {
	return !playerState.watchFromStartLive && !playerState.backToLive
}

const prepareConfigObject = (originalConfig) => {
	let parsedConfig = {}

	for (let key in originalConfig) {
		try {
			parsedConfig[key] = originalConfig[key].includes('.')
				? parseFloat(originalConfig[key])
				: parseInt(originalConfig[key])
		} catch (err) {
			parsedConfig[key] = null
		}
	}

	return parsedConfig
}

export const getParameterByName = (name, url) => {
	if (!url) url = window.location.href
	name = name.replace(/[[]]/g, '\\$&')
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url)
	if (!results) return null
	if (!results[2]) return ''
	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getNonReverseEpgData = (playerEventDetails, event) => {
	const displayItemDetailedTitle = getEpisodeDisplayItemTitle(
		event.seasonNumber || event.season_number,
		event.episodeNumber || event.episode_number,
		event.episode_title
	)
	return {
		...playerEventDetails.current,
		items: [
			{
				...event,
				tag: 'Watching',
				displayTitle: event.title,
				displayItemDetailedTitle,
				episodeSeason: getSeasonEpisodeDetails(
					event.seasonNumber || event.season_number,
					event.episodeNumber || event.episode_number
				),
				genres: event.primaryGenre?.split('|'),
				ratingDetails: `${event.rating}  ${event.maturityClassification?.split(',').join('')}`,
				startTime: moment(event.startDateTime).format('HH:mm'),
				endTime: moment(event.endDateTime).format('HH:mm'),
				start_date_time: event.startDateTime,
				end_date_time: event.endDateTime,
				episode_title: displayItemDetailedTitle,
				isLive: isLiveEvent(event.startDateTime, event.endDateTime),
				channelTag: event.channelTag || event.tag,
			},
		],
	}
}
