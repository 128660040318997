export const keyboardButtons = {
	keypad: [
		'a',
		'b',
		'c',
		'd',
		'e',
		'f',
		'g',
		'h',
		'i',
		'j',
		'k',
		'l',
		'm',
		'n',
		'o',
		'p',
		'q',
		'r',
		's',
		't',
		'u',
		'v',
		'w',
		'x',
		'y',
		'z',
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
	],
	keypadUppercase: [
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z',
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
	],
	actionButtons: {
		space: {
			label: 'space',
		},
		clear: {
			label: 'clear',
			labelActive: 'Clear',
		},
		delete: {
			label: 'delete',
		},
		symbols: {
			label: '#+-',
			labelActive: 'abc',
		},
		case: {
			label: 'ABC',
			labelActive: 'abc',
		},
		ok: {
			label: 'OK',
		},
		ok_not_focused: {
			label: 'OK',
		},
	},
	symbols: [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'0',
		'&',
		'#',
		'(',
		')',
		'@',
		'!',
		':',
		'.',
		'-',
		'_',
		'"',
		"'",
		'$',
		'%',
		'^',
		'+',
		'=',
		'{',
		'}',
		'[',
		']',
		';',
		'<',
		'>',
		'?',
		'/',
	],
}
