import { FocusContext, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import addProfile from '../../../assets/images/smarttv/add_profile.svg'
import styles from './AddAvatar.module.scss'
export function AddAvatar({ onSelectItem }) {
	const { ref, focused, focusKey } = useFocusable({
		onEnterPress: () => {
			onSelectItem()
		},
		focusKey: 'ADD_USER',
	})
	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles['container']}>
				<div className={styles['avatar_title']}>Add profile</div>
				<div className={styles['avatar_wrapper']}>
					<img
						src={addProfile}
						alt="Add profile"
						className={[styles.add__avatar, focused ? styles.avatar__focus : styles.avatar__darken].join(' ')}
					/>
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default AddAvatar
