import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import Edit from '../../../assets/images/smarttv/edit.png'
import EditHover from '../../../assets/images/smarttv/edit_hover.png'
import styles from './Profile.module.scss'

export function ProfileEditIcon({ onEditItem }) {
	const { ref, focused, focusKey } = useFocusable({
		onEnterPress: () => onEditItem(),
		focusKey: 'EDIT',
	})

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.edit_button} ${focused ? styles.focus : ''}`}>
				<img alt="Edit" src={focused ? EditHover : Edit} />
			</div>
		</FocusContext.Provider>
	)
}
