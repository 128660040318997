import { useEffect, useState } from 'react'
import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import { Recommendation } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './Recommendations.module.scss'

export function Recommendations({ rows, cancel }) {
	const { focusKey, hasFocusedChild, ref } = useFocusable({
		focusKey: 'PLAYER_RECOMMENDATIONS',
		trackChildren: true,
		isFocusBoundary: true,
		onBackPress: cancel,
	})

	const [currentIndex, setCurrentIndex] = useState(0)
	const [pageScroll, setPageScroll] = useState(0)

	useEffect(() => {
		setFocus(focusKey)
	}, [setFocus, focusKey])

	const onRecommendationFocus = (focusedRef, _carouselIndex, _item, _id, index) => {
		setCurrentIndex(index)
		setPageScroll(focusedRef.offsetTop)
	}
	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.recommendations_wrapper} ${hasFocusedChild && styles.active}`}>
				<div className={styles.ecommendations} style={{ transform: `translate3d(0, -${pageScroll}px, 0)` }}>
					{rows?.map((row, index) => {
						return (
							<Recommendation
								key={index}
								row={row}
								index={index}
								cardIndex={currentIndex}
								onRecommendationFocus={onRecommendationFocus}
							/>
						)
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default Recommendations
