import PropTypes from 'prop-types'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import styles from './SettingNavItem.module.scss'

export function SettingNavItem({ focusKey, id, itemEnterPress, itemFocus, subtitle, title, ...rest }) {
	const { ref, focused, onFocus } = useFocusable({
		onEnterPress: itemEnterPress && itemEnterPress,
		onFocus: itemFocus && itemFocus,
		focusKey: `${focusKey}_${id.toUpperCase().replace(/ /g, '_')}`,
	})

	return (
		<div ref={ref} className={`${styles['nav-item']} ${focused ? styles.focus : ''}`} onFocus={onFocus}>
			{title}
			{focused && <span>{subtitle}</span>}
		</div>
	)
}

SettingNavItem.defaultProps = {
	active: false,
	title: 'item',
	subtitle: '',
}

SettingNavItem.propTypes = {
	active: PropTypes.bool,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
}

export default SettingNavItem
