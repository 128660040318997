import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	Button,
	getThumbnailUrl,
	PlayerButton,
	PlayerProgressBar,
	PlayerSettingsModal,
	PlayerThumbnail,
	AutoPlayNextPopup,
	Recommendations,
	TimeWidget,
	Iplate,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	DISPLAY_NOTIFICATION_OVERLAY,
	GET_BOOKMARKS,
	GET_EPISODES,
	GET_NEXT_EPISODE,
	GET_TRY_THIS,
	getVodDetails,
	NOTIFICATION_OVERLAY_BUTTON_CLICKED,
	PLAY,
	SAVE_AUDIO_LANGUAGE,
	SAVE_BANDWIDTH,
} from '@dstv-web-leanback/dstv-frontend-services'
import { useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

// Image assets
import iconCog from '../../../../assets/images/icons/icon-player-cog.svg'
import iconPause from '../../../../assets/images/icons/icon-player-pause.svg'
import iconPlay from '../../../../assets/images/icons/icon-player-play.svg'
import iconRestart from '../../../../assets/images/icons/icon-watch-from-start.svg'
import iconNext from '../../../../assets/images/icons/icon-player-next.svg'
import iconPrev from '../../../../assets/images/icons/icon-player-prev.svg'

import styles from '../PlayerControls.module.scss'
import { getDefaultSubOptionsValues, getPlayerSettingsOptions, convertSecondsToTime } from './helper'
import { restartLabel } from '@dstv-web-leanback/dstv-frontend-components'
import { useCreditOverlay } from '../hooks'
import VODSkipControls from './VODSkipControls'
import moment from 'moment'

export function VODControls({
	audioLanguages,
	currentTime,
	handleAudioChange,
	handleBandwidthChange,
	handleSubOptionChange,
	pause,
	play,
	playing,
	playNextEpisode,
	playPause,
	seekTo,
	video,
	videoDetails,
	stopStream,
}) {
	// Variables
	const CONTROLS_TIMEOUT = 7000
	const [controlsVisible, setControlsVisible] = useState(true)
	const [showIPlate, setShowIplate] = useState(false)
	const [prevEpisode, setPrevEpisode] = useState(undefined)
	const [nextEpisode, setNextEpisode] = useState(undefined)
	const [showPlayerSettings, setShowPlayerSettings] = useState(false)

	const options = useRef([])

	// Seeking
	const [seekAmount, setSeekAmount] = useState(0)
	const [seeking, setSeeking] = useState(false)
	const [seekTime, setSeekTime] = useState(0)
	const [time, setTime] = useState(moment())
	const SEEK_INTERVAL = 10
	let seekTimer = useRef()

	// Thumbnail
	const [thumbnailImage, setThumbnailImage] = useState(null)
	const [thumbnailCount, setThumbnailCount] = useState(0)
	const [thumbnailColumnCount, setThumbnailColumnCount] = useState(null)

	const dispatch = useDispatch()

	const configState = useSelector((state) => state.config)
	const playerState = useSelector((state) => state.player)
	const adPlaying = useSelector((state) => state.adPlayer.playing)
	const autoplayState = useSelector((state) => state.autoplay)
	const episodesState = useSelector((state) => state.episodes)

	const episodes = useMemo(() => {
		if (episodesState?.episodes) {
			return episodesState.episodes.map((o) => {
				if (o.id === playerState.details?.id) {
					return {
						...o,
						tag: 'Watching',
					}
				} else return o
			})
		} else return []
	}, [episodesState?.episodes, playerState.details?.id])

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'VOD_CONTROLS',
		forceFocus: true,
		isFocusBoundary: true,
		preferredChildFocusKey: 'PLAY_PAUSE',
		onFocus: () => {
			setShowIplate(false)
		},
		onBackPress: hideControls,
	})

	const debounce = useDebounce(CONTROLS_TIMEOUT, showControls, hideControls)
	const { showCreditOverlay, autoplayEnabled, remainingDuration, onDismiss } = useCreditOverlay(
		playNextEpisode,
		currentTime,
		videoDetails,
		video.duration,
		getTryThis
	)

	const playerControls = [
		{
			focusKey: 'PLAY_PAUSE',
			icon: playing ? iconPause : iconPlay,
			onSelect: () => {
				playPause()
			},
		},
	]

	// Effects
	useEffect(() => {
		focusSelf()
		if (!autoplayState?.data?.id || !playerState?.details?.genRef) {
			if (playerState.type === 'vod') {
				getThumbnailData()
				getNextEpisode()
				getEpisodes()
			}

			options.current = getPlayerSettingsOptions(configState, audioLanguages)
			if (!playerState.savedAudioLanguage && !playerState.savedBandwidth) {
				let defaultLanguage = audioLanguages?.[0]?.language
				const defaultBandwidth = +localStorage.getItem('QUALITY_STORAGE_BITRATE') || 6000000

				dispatch(SAVE_AUDIO_LANGUAGE({ language: defaultLanguage }))
				dispatch(SAVE_BANDWIDTH({ bandwidth: defaultBandwidth }))

				handleAudioChange(defaultLanguage)
				handleBandwidthChange(defaultBandwidth)
			}
		}
		debounce()
	}, [])

	useEffect(() => {
		if (episodes && playerState.details) {
			getEpisodeBookmarks(episodes.map?.((o) => o.genRef).join(','))
			const episodeId =
				playerState.details.id.indexOf('_') > -1 ? playerState.details.id.split('_')?.[1] : playerState.details.id

			let index = -1
			for (let i = 0; i < episodes.length; i++) {
				const episode = episodes[i]

				if (episodeId === episode.id) {
					index = i
				}
			}

			setPrevEpisode(episodes[index - 1])
			setNextEpisode(episodes[index + 1])
		}
	}, [episodes])

	useEffect(() => {
		if (!autoplayEnabled && playerState.tryThis?.length > 0 && showCreditOverlay) {
			pause()
			setFocus('PLAYER_RECOMMENDATIONS')
		}
	}, [playerState.tryThis, showCreditOverlay, autoplayEnabled])

	// Find the amount of columns in the thumbnail image
	useEffect(() => {
		if (thumbnailImage) {
			let thumbnailImg = new Image()
			thumbnailImg.src = thumbnailImage
			thumbnailImg.onload = () => {
				setThumbnailColumnCount(thumbnailImg.width / 150)
			}
		}
	}, [thumbnailImage])

	// When the controls become visible, set focus to the PLAY_PAUSE button
	useEffect(() => {
		if (controlsVisible) {
			setShowIplate(false)
			setFocus('PLAY_PAUSE')
		}
	}, [controlsVisible])

	useEffect(() => {
		if (!adPlaying) {
			setShowIplate(false)
			setFocus('PLAY_PAUSE')
		}

		return () => {
			setShowPlayerSettings(false)
		}
	}, [])

	useEffect(() => {
		const { state } = adPlaying
		if (!state) debounce()
	}, [adPlaying])

	// Functions
	const onCogBtnSelect = () => {
		setShowPlayerSettings(!showPlayerSettings)
	}

	const onRestart = () => {
		seekTo(0)
		setFocus('PLAY_PAUSE')
	}

	const getPlayerProgress = () => {
		return (currentTime / video.duration) * 100
	}

	const getSeekProgress = () => {
		return (seekTime / video.duration) * 100
	}

	const getThumbnailData = () => {
		fetch(getThumbnailUrl(playerState?.url) + '/thumbs.vtt')
			.then((response) => response.text())
			.then((text) => {
				let textArray = text.split('\n').reverse()
				for (let i = 0; i < textArray.length; i++) {
					if (textArray[i].startsWith('Img')) {
						setThumbnailCount(textArray[i].split(' ')[1])
						break
					}
					if (textArray[i].includes('#')) {
						let imagePath = textArray[i].split('#')[0]
						setThumbnailImage(getThumbnailUrl(playerState?.url) + '/' + imagePath)
					}
				}
			})
	}

	const getNextEpisode = () => {
		if (playerState?.details?.resumeVideoId) {
			dispatch(GET_NEXT_EPISODE({ id: playerState?.details?.resumeVideoId }))
		}
	}

	const getEpisodes = () => {
		dispatch(GET_EPISODES({ id: playerState?.details?.id }))
	}

	const getEpisodeBookmarks = (genRefs) => {
		if (genRefs) {
			dispatch(GET_BOOKMARKS({ genRefs }))
		}
	}

	const handleSeek = (direction) => {
		debounce()
		setSeeking(true)

		const amount = direction === 'right' ? seekAmount + SEEK_INTERVAL : seekAmount - SEEK_INTERVAL
		setSeekAmount(amount)
		let newSeekTime = video.currentTime + amount
		if (newSeekTime < 0) {
			newSeekTime = 0
		} else if (newSeekTime > video.duration) {
			newSeekTime = video.duration
		}
		setSeekTime(newSeekTime)

		clearTimeout(seekTimer.current)
		seekTimer.current = setTimeout(() => {
			seekTo(video.currentTime + amount)
			setSeekAmount(0)
			setSeeking(false)
		}, 500)
	}

	function showControls() {
		setControlsVisible(true)
		setTime(moment())
	}

	function hideControls() {
		setControlsVisible(false)
	}

	const playEpisode = (data, ignoreBookmarks) => {
		const playerDetails = getVodDetails(data)

		if (playerDetails.details.id !== playerState.details.id) {
			dispatch(PLAY({ ...playerDetails, ignoreBookmarks: ignoreBookmarks }))
		} else {
			debounce()
			setFocus('PLAY_PAUSE')
			setShowIplate(false)
		}
	}

	function getTryThis(autoplayEnabled) {
		if (!autoplayEnabled) {
			dispatch(GET_TRY_THIS({ videoId: playerState.details?.id }))
		}
	}

	const handleRecommendationsCancel = () => {
		play()
		onDismiss()
	}

	if (showCreditOverlay && autoplayEnabled) {
		return (
			<AutoPlayNextPopup
				title="Next Episode"
				primaryButtonTitle="Watch Now"
				primaryButtonAction={() => {
					NOTIFICATION_OVERLAY_BUTTON_CLICKED({
						overlay: 'Autoplay Next Episode',
						title: autoplayState?.data?.title,
						buttonText: 'Watch Now',
					})
					playNextEpisode()
				}}
				secondaryButtonTitle="Watch Credits"
				secondaryButtonAction={() => {
					dispatch(
						NOTIFICATION_OVERLAY_BUTTON_CLICKED({
							overlay: 'Autoplay Next Episode',
							title: autoplayState?.data?.title,
							buttonText: 'Watch Credits',
						})
					)
					onDismiss()
					setShowIplate(false)
					setFocus('PLAY_PAUSE')
				}}
				countDownCounter={remainingDuration}
				heading={autoplayState?.data?.title}
				subHeading={autoplayState?.data?.seasonEpisode}
				initAction={() =>
					dispatch(
						DISPLAY_NOTIFICATION_OVERLAY({
							overlay: 'Autoplay Next Episode',
							title: autoplayState?.data?.title,
						})
					)
				}
				backAction={() => {
					onDismiss()
					setShowIplate(false)
					setFocus('PLAY_PAUSE')
				}}
			/>
		)
	}

	if (showCreditOverlay && playerState.tryThis?.length > 0) {
		return <Recommendations rows={playerState.tryThis} cancel={handleRecommendationsCancel} />
	}

	if (!controlsVisible && !showPlayerSettings && !showIPlate) {
		return <VODSkipControls video={video} seekTo={seekTo} showControls={debounce} stopStream={stopStream} />
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div
				ref={ref}
				className={`${styles.vod_controls_wrapper} ${
					controlsVisible || showPlayerSettings || showIPlate ? styles.visible : ''
				}`}
			>
				{controlsVisible && (
					<div className={styles.time_widget_wrapper}>
						<TimeWidget currentTime={time} player={true} />
					</div>
				)}
				<div className={styles.player_controls}>
					{seeking ? (
						<PlayerThumbnail
							thumbnailCount={thumbnailCount}
							thumbnailImage={thumbnailImage}
							currentTime={seekTime}
							duration={video.duration}
							position={getSeekProgress()}
							time={convertSecondsToTime(seekTime)}
							columnCount={thumbnailColumnCount}
							hideThumbnailPreview={!thumbnailImage}
							collapseTimestamp={!thumbnailImage}
						/>
					) : (
						<div className={styles.metadata_wrapper}>
							<h1>{playerState?.details?.title}</h1>
							<h3>{playerState?.details?.episode_title}</h3>
						</div>
					)}
					<div className={`${styles.times_wrapper} ${showIPlate ? styles.hidden : ''}`}>
						<div className={styles.times_left}>{convertSecondsToTime(seeking ? seekTime : video.currentTime)}</div>
						<div className={styles.times_right}>
							{convertSecondsToTime(seeking ? video.duration - seekTime : video.duration - video.currentTime)}
						</div>
					</div>
					<div className={`${styles.progress_wrapper} ${showIPlate ? styles.hidden : ''}`}>
						<PlayerProgressBar
							focusable={true}
							handleSeek={handleSeek}
							progress={seeking ? getSeekProgress() : getPlayerProgress()}
							seeking={seeking}
						/>
					</div>
					<div className={`${styles.controls_wrapper} ${showIPlate ? styles.hidden : ''}`}>
						<div className={styles.controls_left}>
							<PlayerButton
								icon={iconCog}
								onSelect={onCogBtnSelect}
								onNavigate={debounce}
								selected={showPlayerSettings}
							/>
						</div>
						<div className={styles.controls_center}>
							<div className={styles.prev_wrapper}>
								{prevEpisode && (
									<PlayerButton
										icon={iconPrev}
										onNavigate={debounce}
										onSelect={() => {
											playEpisode(prevEpisode, false)
										}}
									/>
								)}
							</div>

							{playerControls.map((button, index) => {
								return (
									<PlayerButton
										focusKey={button.focusKey}
										icon={button.icon}
										key={index}
										onSelect={button.onSelect}
										onNavigate={debounce}
									/>
								)
							})}

							<div className={styles.next_wrapper}>
								{nextEpisode && (
									<PlayerButton
										icon={iconNext}
										onNavigate={debounce}
										onSelect={() => {
											playEpisode(nextEpisode, false)
										}}
									/>
								)}
							</div>
						</div>
						<div className={styles.controls_right_vod}>
							<PlayerButton icon={iconRestart} label={restartLabel} onSelect={onRestart} onNavigate={debounce} />
						</div>
					</div>

					<div className={`${styles.line_up} ${showIPlate ? styles.hidden : ''}`}>
						{episodes?.length ? (
							<Button
								id="EPISODE_LIST"
								title={'Episode List'}
								onSelect={() => {
									setShowIplate(true)
									setFocus('IPLATE')
								}}
							/>
						) : (
							''
						)}
					</div>
				</div>

				{episodes?.length ? (
					<Iplate
						active={showIPlate}
						cards={episodes}
						initialFocus={`CARD_${playerState.details?.id}`}
						handleBack={() => {
							debounce()
							setShowIplate(false)
							setFocus('PLAY_PAUSE')
						}}
						onSelect={playEpisode}
					/>
				) : (
					''
				)}

				{showPlayerSettings && (
					<PlayerSettingsModal
						options={options.current}
						defaultValues={getDefaultSubOptionsValues(
							options.current,
							playerState.savedAudioLanguage,
							playerState?.savedBandwidth
						)}
						handleBackButton={() => setShowPlayerSettings(null)}
						handleSubOptionSelection={(optionHeading, subOption) => {
							setShowPlayerSettings(false)
							setControlsVisible(false)
							handleSubOptionChange(optionHeading, subOption)
						}}
					/>
				)}
			</div>
		</FocusContext.Provider>
	)
}
export default VODControls
