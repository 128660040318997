export const menuDisplayName = (menuId) => {
	switch (menuId) {
		case 'catchup':
			return 'Catch Up'
		case 'tvguide':
			return 'TV Guide'
		case 'zatvguide':
			return 'TV Guide'
		case 'livetv':
			return 'Live TV'
		case 'home':
			return 'Home'
		case 'mystuff':
			return 'My Stuff'
		case 'search':
			return 'Search'
		case 'settings':
			return 'Settings'
		case 'tvshows':
			return 'TV Shows'
		case 'movies':
			return 'Movies'
		case 'kids':
			return 'Kids'
		case 'sport':
			return 'Sports'
		case 'apps':
			return 'Apps'
		case 'channelgroups':
			return 'Channels'
		default:
			return menuId
	}
}
