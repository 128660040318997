import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { handleUnauthorized, getHeaders } from '../../utils/authHelper'
import { PLAY } from '../player/player.slice'
import { getSeasonEpisode } from '../billboard/billboardTransformHelper'

export const AUTOPLAY_FEATURE_KEY = 'autoplay'

/*
 * Create our slice
 */
export const autoplaySlice = createSlice({
	name: AUTOPLAY_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_NEXT_EPISODE: (state) => {
			delete state.data
			delete state.error
			delete state.serverError
			state.loading = true
		},
		GET_NEXT_EPISODE_SUCCESS: (state, action) => {
			state.loading = false
			state.data = action.payload
		},
		GET_NEXT_EPISODE_ERROR: (state, action) => {
			state.loading = false
			state.error = action.payload.error
			state.serverError = action.payload.serverError
		},
	},
	extraReducers: (builder) => {
		builder.addCase(PLAY.type, (state) => {
			delete state.data
			delete state.error
			delete state.serverError
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const autoplayReducer = autoplaySlice.reducer

/*
 * Export actions
 */
export const { GET_NEXT_EPISODE, GET_NEXT_EPISODE_SUCCESS, GET_NEXT_EPISODE_ERROR } = autoplaySlice.actions

/*
 * Set up the redux-observable epic
 */
export const autoplayEpic = (action$) => action$.pipe(ofType(GET_NEXT_EPISODE.type), mergeMap(autoplayService(action$)))

/*
 * Do API calls
 */
const autoplayService = (action$) => (action) => {
	switch (action.type) {
		case GET_NEXT_EPISODE.type: {
			return HTTP.GET(
				ENV.AUTO_PLAY_NEXT_EPISODE.replace('{id}', action.payload.id),
				getHeaders(),
				getNextEpisodeSuccess,
				getNextEpisodeError(action),
				true
			)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const getNextEpisodeSuccess = (response) => {
	let assetContentId = response.stream_url.split('/').reverse()[1] + '_ext'
	let seasonEpisode = response.seasonEpisode || getSeasonEpisode(response.season, response.episode)
	return {
		type: GET_NEXT_EPISODE_SUCCESS.type,
		payload: { ...response, seasonEpisode: seasonEpisode, contentId: assetContentId, resumeVideoId: response.id },
	}
}

const getNextEpisodeError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_NEXT_EPISODE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
