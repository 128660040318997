import { createSlice } from '@reduxjs/toolkit';
import { mergeMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';

import { ENV } from '../../utils/environment';
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper';
import { getHeaders } from '../../utils/authHelper';
import { fetchFromLocalStorage } from '../../utils/storageHelper';

export const SETTINGS_FEATURE_KEY = 'settings';

/*
 * Create our slice
 */
export const settingsSlice = createSlice({
	name: SETTINGS_FEATURE_KEY,
	initialState: { autoplay_enabled: fetchFromLocalStorage("autoplay_toggle") },
	reducers: {
		AUTOPLAY_TOGGLE: (state, action) => {
			state.autoplay_enabled = action.payload.enabled
		},
	},
});

/*
 * Export reducer for store configuration.
 */
export const settingsReducer = settingsSlice.reducer;

/*
 * Export actions
 */
export const {
	AUTOPLAY_TOGGLE
} = settingsSlice.actions;

/*
 * Set up the redux-observable epic
 */
export const settingsEpic = (action$) =>
	action$.pipe(filter(), mergeMap(settingsService(action$)));

/*
 * Do API calls
 */
const settingsService = (action$) => (action) => {
	// return HTTP.POST(
	// 	ENV.DEVICE_REGISTRATION,
	// 	action.payload,
	// 	getHeaders(),
	// 	loginSuccess,
	// 	loginError(action),
	// 	true
	// );
};

/*
 * Dispatch actions based on API responses
 */
// const loginSuccess = (response) => { return { type: LOGIN_SUCCESS.type, payload: successResponse(response.response)} };
// const loginError = (action) => (response) => of({type: LOGIN_ERROR.type,  payload: errorResponse(response, action)});
