import { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FocusContext, useFocusable, setFocus, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'

import {
	DialogFull,
	Grid,
	ChipList,
	Toast,
	toastSuccess,
	toastError,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	useNavigation,
	WATCH_LIST_PAGING,
	WATCH_LIST_FEATURE_KEY,
	replaceVirtualLinks,
	GET_GRID_ITEM,
} from '@dstv-web-leanback/dstv-frontend-services'
import { WatchlistEmptyScreen } from '../Watchlist/WatchlistEmptyScreen'
import { focusMainNavigation } from '../../utils/helper'
import styles from './WatchlistGrid.module.scss'

export const WatchlistGrid = ({ showToast, setGridToast, onSelect }) => {
	const dispatch = useDispatch()
	const { location, navigateBack, navigate } = useNavigation()

	const watchlistGridData = useSelector((state) => state[WATCH_LIST_FEATURE_KEY])
	const categories = watchlistGridData?.gridData?.genres

	const [showPanel, setShowPanel] = useState(false)

	const [focusedCard, setFocusedCard] = useState(null)
	const previousFocusedCard = useRef()

	const [showToastPanel, setShowToastPanel] = useState(showToast)
	const [genreTitle, setGenreTitle] = useState(null)
	const homeState = useSelector((state) => state.home)

	const watchlistGrid = watchlistGridData?.gridItem?.items?.length ? true : false

	const { focusKey, ref, hasFocusedChild } = useFocusable({
		onBackPress: () => {
			navigateBack()
		},
		trackChildren: true,
		forceFocus: true,
	})

	useEffect(() => {
		setShowToastPanel(showToast)
		if (!location.back) {
			setFocus(`CHIP_${genreTitle}`)
		}
	}, [showToast, watchlistGrid])

	useEffect(() => {
		if (categories?.length && !showPanel) {
			setFocus(`CHIP_${categories[0].title.toString()?.toUpperCase()}`)
		}
	}, [categories])

	const paginateWatchlistGrid = () => {
		dispatch(
			WATCH_LIST_PAGING({
				page: watchlistGridData.data?.page + 1,
			})
		)
	}

	const onFocusChange = useCallback((item) => {
		if (item) {
			setGenreTitle(item.title.toString()?.toUpperCase())
			dispatch(
				GET_GRID_ITEM({
					url: replaceVirtualLinks(item.endpoint),
				})
			)
			setFocusedCard(null)
			previousFocusedCard.current = item.title
		}
	}, [])
	const handleArrow = (direction) => {
		if (direction === 'down') {
			setFocusedCard(previousFocusedCard.current)
		}
	}

	if (watchlistGridData.error) {
		return (
			<DialogFull
				error={watchlistGridData.error}
				serverError={watchlistGridData.serverError}
				backAction={() => {
					navigateBack()
				}}
				retryAction={() => {
					focusMainNavigation()
				}}
			/>
		)
	}

	const handleCloseToast = () => {
		setShowToastPanel(false)
		setGridToast(false)
	}

	const getToastDetails = (icon) => {
		return {
			toastIcon: icon,
		}
	}
	const toastData = {
		myStuffToast: getToastDetails(!homeState.loadToastError ? toastSuccess : toastError),
	}

	return (
		<>
			<FocusContext.Provider value={focusKey}>
				<div ref={ref} className={styles.watchlistWrapper}>
					<img src={watchlistGridData?.poster_image} alt="" className={styles.gridBgImg} />
					<div className={styles.gradientOverlay}></div>
					<div className={styles.description_wrapper}>
						<div className={styles.title}>{watchlistGridData?.title}</div>
						<p className={styles.description}>{watchlistGridData?.description}</p>
					</div>
					<div>
						<div className={styles.menuWrapper}>
							{categories && (
								<ChipList
									chips={categories}
									selectedChip={focusedCard}
									onItemFocus={onFocusChange}
									className={styles.grid_chip}
									handleArrow={handleArrow}
								/>
							)}
						</div>
						{watchlistGridData?.gridItem?.items.length > 0 ? (
							<Grid
								cards={watchlistGridData?.gridItem?.items}
								focusKeyProp="WATCHLIST_GRID"
								paginateCatchupGrid={paginateWatchlistGrid}
								watchlistGrid={watchlistGrid}
								onWatchlistSelect={onSelect}
								rowTitle={watchlistGridData?.title}
							/>
						) : (
							<div>
								<WatchlistEmptyScreen grid={true} />
							</div>
						)}
					</div>
				</div>
			</FocusContext.Provider>
			<Toast
				show={showToastPanel}
				duration={5000}
				message={watchlistGridData.toastMessage}
				onClose={handleCloseToast}
				icon={toastData[watchlistGridData.toastId]?.toastIcon}
			/>
		</>
	)
}
export default WatchlistGrid
