import { useSelector } from 'react-redux'

import { NavSidebar } from '@dstv-web-leanback/dstv-frontend-components'

export function Menu(props) {
	const menuItems = useSelector((state) => state.menu.data)

	return menuItems && <NavSidebar items={menuItems} activeProfile={props.activeProfile} />
}

export default Menu
