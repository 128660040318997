import { FocusContext, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import OptionList from '../../../collection/OptionList/OptionList'
import styles from './Welcome.module.scss'

export function Welcome({ title, subtitle, options }) {
	const { ref, focusKey } = useFocusable()

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.steps_wrapper}>
				<div>
					<div className={styles.titles_wrapper}>
						{title && <h2>{title}</h2>}
						{subtitle && <p>{subtitle}</p>}
					</div>
					{options && <OptionList options={options} />}
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default Welcome
