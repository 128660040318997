import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { mergeMap, of } from 'rxjs'
import { HTTP } from '../../utils/httpHelper'
import { handleUnauthorized, getAuthorizationHeaders } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { successResponse, errorResponse } from '../../utils/httpHelper'

export const CALCULATE_FEATURE_KEY = 'calculatePackage'

export const calculateSlice = createSlice({
	name: CALCULATE_FEATURE_KEY,
	initialState: {
		loading: false,
		error: null,
		action: null,
	},
	reducers: {
		PACKAGE_RECALCULATE: (state) => {
			state.done = false
			state.loading = true
			state.status = 'LOADING'
		},
		PACKAGE_RECALCULATE_SUCCESS: (state, action) => {
			state.buttonText = action?.payload?.buttonText
			state.status = 'SUCCESS'
			state.done = true
			state.success = true
			state.loading = false
		},
		PACKAGE_RECALCULATE_ERROR: (state, action) => {
			state.status = action?.payload?.serverError?.error
			state.errorMessage = action?.payload?.serverError?.message
			state.error = true
			state.success = false
			state.loading = false
			state.errorCode = action?.payload?.errorCode
		},
		PACKAGE_RECALCULATE_CLOSE: (state) => {
			state.buttonText = 'Dismiss'
			state.status = 'DONE'
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const calculateReducer = calculateSlice.reducer

export const {
	PACKAGE_RECALCULATE,
	PACKAGE_RECALCULATE_SUCCESS,
	PACKAGE_RECALCULATE_ERROR,
	PACKAGE_RECALCULATE_CLOSE,
} = calculateSlice.actions

// Selector for the calculate state
export const getcalculateEpicState = (rootState) => rootState[CALCULATE_FEATURE_KEY]

export const calculateEpic = (action$) => action$.pipe(ofType(PACKAGE_RECALCULATE), mergeMap(calculateService))

/*
 * API service to fetch data
 */
export const calculateService = (action) => {
	switch (action.type) {
		case PACKAGE_RECALCULATE.type: {
			return HTTP.POST(
				ENV.PACKAGE_RECALCULATE,
				{},
				getAuthorizationHeaders(),
				calculateSuccess(action),
				handleError(action),
				true
			)
		}
		default:
			return []
	}
}

const handleError = (action) => (error) => {
	return of(
		handleUnauthorized(
			error,
			{
				type: PACKAGE_RECALCULATE_ERROR.type,
				payload: errorResponse(error, action),
			},
			action
		)
	)
}

const calculateSuccess = (action) => (response) => {
	const userReinitialize = response ? { userPackage: response['urn:cv:dstvnow:user:action'][0] } : []

	return userReinitialize.userPackage === 'REINITIALIZE'
		? {
				type: PACKAGE_RECALCULATE_SUCCESS.type,
				payload: successResponse(response),
		  }
		: { type: PACKAGE_RECALCULATE_CLOSE.type }
}

export const calculateError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: PACKAGE_RECALCULATE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
