import { useEffect } from 'react'
import { FocusContext, useFocusable, setFocus, doesFocusableExist } from '@dstv-web-leanback/norigin-spatial-navigation'
import { emptyBox, Loader } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './Watchlist.module.scss'
import { focusMainNavigation } from '../../utils/helper'
import { useNavigation } from '@dstv-web-leanback/dstv-frontend-services'

export const WatchlistEmptyScreen = ({ grid }) => {
	const { location, navigateBack } = useNavigation()
	const { focusKey, ref, focusSelf, hasFocusedChild } = useFocusable({
		focusKey: 'EMPTY_STATE',
		forceFocus: true,
		isFocusBoundary: true,
		onBackPress: () => {
			if (grid) {
				navigateBack()
			} else {
				focusMainNavigation()
			}
		},
		onEnterPress: () => {
			if (grid) {
				navigateBack()
			} else {
				focusMainNavigation()
			}
		},
		trackChildren: true,
	})

	useEffect(() => {
		location.focusKey && doesFocusableExist(location.focusKey) ? setFocus(location.focusKey) : focusSelf()
	}, [location.pathname, location.focusKey, hasFocusedChild])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.no_content_container} ${grid ? styles.no_content_container_top : ''}`}>
				<img alt="" className={styles.no_content_image} src={emptyBox} />
				<div className={styles.no_content_title}>Save all your must-watch items for later.</div>
				<div className={styles.no_content_text}>Add shows, movies, sports and more, to watch when you're ready.</div>
				<button className={styles.buttons_wrapper} title={'Back'}>
					Back
				</button>
			</div>
		</FocusContext.Provider>
	)
}
