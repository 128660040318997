import { createSlice } from '@reduxjs/toolkit'
import { GET_CATCHUP, GET_CATCHUP_SUCCESS } from '../catchup/catchup.slice'
import { GET_LIVE_TV_SECTIONS, GET_LIVE_TV_SECTIONS_SUCCESS } from '../channels/channels.slice'

export const SECTIONS_FEATURE_KEY = 'sections'

/*
 * Create sections slice
 */
export const sectionsSlice = createSlice({
	name: SECTIONS_FEATURE_KEY,
	initialState: {},
	reducers: {
		SET_ACTIVE_SECTION: (state, action) => {
			state.activeSection = action.payload.index
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GET_LIVE_TV_SECTIONS.type, (state, action) => {
			delete state.data
			delete state.type
			state.activeSection = 0
		})
		builder.addCase(GET_LIVE_TV_SECTIONS_SUCCESS.type, (state, action) => {
			setSectionMenus(state, action, 'live')
		})
		builder.addCase(GET_CATCHUP.type, (state, action) => {
			delete state.data
			delete state.type
			state.activeSection = 0
		})
		builder.addCase(GET_CATCHUP_SUCCESS.type, (state, action) => {
			setSectionMenus(state, action, 'vod')
		})
	},
})
/*
 * Export reducer for store configuration.
 */
export const sectionsReducer = sectionsSlice.reducer

/*
 * Export actions
 */
export const { SET_ACTIVE_SECTION } = sectionsSlice.actions

export const setSectionMenus = (state, action, type) => {
	state.data = action.payload.menuItems
	state.activeSection = 0
	state.type = type
}
