import { replaceVirtualLinks } from '@dstv-web-leanback/dstv-frontend-services'
import { getCardTypeByFeatures } from '@dstv-web-leanback/dstv-frontend-utils'

export const getRemoveContinueWatchingEndpoint = (item) => {
	return replaceVirtualLinks(item.links?.find((o) => o.rel.includes('continue_watching_remove'))?.href)
}

export const getHomeRowEndpoint = (row) => {
	return replaceVirtualLinks(row?.links?.find((o) => o.rel.includes('self'))?.href)
}

export const getMyStuffVodEndPoint = (item) => {
	return replaceVirtualLinks(item?.links?.find((o) => o.rel.includes('remove'))?.href)
}

export const getRowHeight = (row) => {
	const type = getCardTypeByFeatures(row.features, row.type)
	switch (type) {
		case 'vod_with_progress':
			return 21.875
		case 'big16x9':
			return 34.375
		case 'small16x9':
			return 21.875
		case 'poster3x4':
			return 20.833
		case 'posternumbered':
			return 20.833
		case 'posterleadtitle':
			return 20.833
		case 'episodecard16x9':
			return 20.625
		case 'containedcard':
			return 45.57
		case 'expandablecard16x9':
			return 20.833
		case 'contentpage':
			return 21.41
		case 'livecard16x9':
			return 18.02
		case 'midrailbillboard':
			return 45.57
		case 'singleeventbillboard':
			return 18.75 + 2.06
		default:
			return 18.75
	}
}
