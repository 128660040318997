import { useEffect, useRef } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { useSelector } from 'react-redux'
import {
	getUserID,
	getUserPackage,
	getSessionID,
	DATADOG_CONFIG,
	asInteger,
} from '@dstv-web-leanback/dstv-frontend-services'
import { createSelector } from '@reduxjs/toolkit'

const configLoadedSelector = (state) => state.config.configLoaded
const monConfigSelector = (state) => {
	const items = JSON.parse(state.config.data?.mon_config?.payload?.items || null) || {}
	return items[getUserPackage() || 'Others'] || null
}

const sessionRateSelector = createSelector(configLoadedSelector, monConfigSelector, (configLoaded, monConfig = {}) => {
	let {
		sessionReplaySampleRate = monConfig?.sessionReplaySampleRate ?? DATADOG_CONFIG.sessionReplaySampleRate,
		sessionSampleRate = monConfig?.sessionSampleRate ?? DATADOG_CONFIG.sessionSampleRate,
	} = (configLoaded && monConfig) || {}
	return {
		sessionReplaySampleRate: +asInteger(sessionReplaySampleRate),
		sessionSampleRate: +asInteger(sessionSampleRate),
	}
})

const shouldDiscard = (event) => {
	const { type, resource } = event

	if (type === 'resource' && ['fetch', 'xhr'].includes(resource.type) && resource) {
		const { url, status_code } = resource
		const isDstvApiUnauthorized = url.includes('ssl.dstv.com/api') && status_code === 401
		const isBookmarkNotFound = url.includes('/getBookmark') && status_code === 404

		if (isDstvApiUnauthorized || isBookmarkNotFound) {
			return true
		}
	}

	return false
}

export const useDataDog = () => {
	const ref = useRef()
	const userID = getUserID()
	const loggedIn = !!getSessionID()
	const sessionRate = useSelector(sessionRateSelector)
	const configState = useSelector((state) => state.config.data)

	useEffect(() => {
		if (ref.current) {
			return
		} else {
			if (window.datadogRum) {
				ref.current = window.datadogRum
				return
			}
		}
		if (loggedIn && configState) {
			datadogRum.init({
				...DATADOG_CONFIG,
				...sessionRate,
				trackUserInteractions: true,
				trackResources: true,
				trackLongTasks: true,
				trackFrustrations: true,
				defaultPrivacyLevel: 'mask-user-input',
				version: process.env.APP_VERSION,
				beforeSend: (event, _ctx) => {
					event.view.url = event.view.url = event.view.url.split('#')[1]
					if (shouldDiscard(event)) {
						return false
					}
				},
			})
			datadogRum.setUser({ id: userID })
			datadogRum.startSessionReplayRecording()
			window.datadogRum = datadogRum
			ref.current = datadogRum
		}
	}, [ref, sessionRate, loggedIn, userID])

	return ref
}
