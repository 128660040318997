import { useEffect } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { Button, Loader } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './PackageUpdate.module.scss'
import {
	PACKAGE_RECALCULATE,
	getcalculateEpicState,
	SESSION_REFRESH,
	SESSION_FEATURE_KEY,
	GET_USER_INFO,
	GET_REMOTE_CONFIGS,
	PACKAGE_RECALCULATE_SUCCESS,
	PACKAGE_RECALCULATE_ERROR,
	PACKAGE_RECALCULATE_CLOSE,
} from '@dstv-web-leanback/dstv-frontend-services'
import { useDispatch, useSelector } from 'react-redux'

export function PackageUpdate() {
	const { loading, success, error } = useSelector(getcalculateEpicState)
	const { reload } = useSelector((state) => state[SESSION_FEATURE_KEY])
	const dispatch = useDispatch()

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'UPDATE_PACKAGE',
		forceFocus: true,
		isFocusBoundary: true,
	})

	useEffect(() => {
		focusSelf()
		if (!loading && !success && !error) {
			dispatch(PACKAGE_RECALCULATE())
		}
	}, [dispatch, loading, success, error, focusSelf])

	const handleReload = () => {
		dispatch(SESSION_REFRESH({ reload: true }))
		dispatch(PACKAGE_RECALCULATE_SUCCESS({ buttonText: 'Reload' }))
	}

	const handleDismiss = () => {
		dispatch(PACKAGE_RECALCULATE_ERROR({ buttonText: 'Dismiss' }))
		dispatch(PACKAGE_RECALCULATE_CLOSE({ buttonText: 'Dismiss' }))
	}

	useEffect(() => {
		if (reload) {
			dispatch(GET_USER_INFO({}))
			dispatch(GET_REMOTE_CONFIGS({}))
		}
	}, [reload])

	useEffect(() => {
		if (error) {
			handleDismiss()
		}
	}, [error])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.page_full}>
				<div className={styles.content_wrapper}>
					{loading ? <LoadingState /> : success ? <SuccessState onReload={handleReload} /> : null}

					{reload && success ? <Loader overlay={true} /> : ''}
				</div>
			</div>
		</FocusContext.Provider>
	)
}

function LoadingState() {
	return (
		<>
			<h2 className={styles.main_heading}>Verifying your account</h2>
			<p className={styles.body}>This could take up to a minute</p>
			<div className={styles.loader}>
				<Loader overlay={false} fullscreen={false} />
			</div>
		</>
	)
}

function SuccessState({ onReload }) {
	return (
		<>
			<h2 className={styles.main_heading}>Subscription Update</h2>
			<p className={styles.body}>
				We've noticed a change to your subscription. <br />
			</p>
			<p className={styles.body}>Reload the app to see your updated catalogue.</p>
			<div className={styles.buttons_wrapper}>
				<Button id="Reload" onSelect={onReload} title="Reload" />
			</div>
		</>
	)
}

export default PackageUpdate
