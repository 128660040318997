import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import radioBtn from '../../../assets/images/smarttv/radio_button.svg'
import radioBtnSelected from '../../../assets/images/smarttv/radio_button_selected.svg'
import radioBtnActive from '../../../assets/images/smarttv/radio_button_active.svg'
import radioBtnActiveSelected from '../../../assets/images/smarttv/radio_button_active_selected.svg'
import styles from './PlayerSettingsSubOptionItem.module.scss'
export function PlayerSettingsSubOptionItem({ item, selectedItem, setSelectedItem, focusKey }) {
	const { ref, focused } = useFocusable({
		focusKey: focusKey,
		onEnterPress: () => {
			setSelectedItem(item)
		},
	})

	const getRadioBtn = () => {
		if (focused) {
			return selectedItem?.id === item.id ? radioBtnActiveSelected : radioBtnActive
		} else {
			return selectedItem?.id === item.id ? radioBtnSelected : radioBtn
		}
	}

	return (
		<div ref={ref} className={[styles.sub_option_item, focused ? styles.focus : ''].join(' ')}>
			<div className={styles.radio_button_wrapper}>
				<img className={styles.radio_button} src={getRadioBtn()} alt="" />
			</div>
			<div className={styles.sub_option_text_wrapper}>
				<div className={styles.title}>{item.title}</div>
				<div className={styles.description}>{item.subtitle}</div>
			</div>
		</div>
	)
}
export default PlayerSettingsSubOptionItem
