import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { PULSE_CONSTANTS } from '../constants'
import { useEffect } from 'react'

function PulseSkipControls({ styles, canSkip, showSkip, skipAd, adSkipCountdown, handleFocusableVal }) {
	const { focusKey, ref } = useFocusable({
		forceFocus: true,
		isFocusBoundary: true,
		focusKey: PULSE_CONSTANTS.FOCUSABLE.PULSE_SKIP_CONTROLS,
		onEnterPress: skipAd,
	})

	const skipCount = adSkipCountdown > 0 ? ` in ${adSkipCountdown}` : ''

	useEffect(() => {
		handleFocusableVal(PULSE_CONSTANTS.FOCUSABLE.PULSE_SKIP_CONTROLS)
	}, [])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.skipControlsWrapper}>
				{canSkip && (
					<div className={`${styles.pulseBtn} ${styles.pulseSkipButton} ${showSkip && styles.active}`}>
						<span>
							{PULSE_CONSTANTS.TEXT.SKIP_BTN} {!showSkip && skipCount}
						</span>
					</div>
				)}
			</div>
		</FocusContext.Provider>
	)
}

export default PulseSkipControls
