import { useState, useRef, useEffect } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { CardPortrait, CardVOD16x9, FloatingGuide } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './Grid.module.scss'

export function Grid({ cards, itemFocus, paginateCatchupGrid, onWatchlistSelect, rowTitle }) {
	const wrapperRef = useRef(null)

	const [gridScroll, setGridScroll] = useState(0)
	const [scrollAdjust, setScrollAdjust] = useState(0)
	const [showFloatingGuide, setShowFloatingGuide] = useState(false)
	const [focusedIndex, setFocusedIndex] = useState(0)
	let timer

	const changeGridFocus = (focusedRef, item, index) => {
		if (focusedRef.current && ref.current && wrapperRef.current) {
			clearTimeout(timer)
			itemFocus?.(item)
			setFocusedIndex(index)
			const wrapperStyles = getComputedStyle(wrapperRef.current)
			const cardStyles = getComputedStyle(focusedRef.current)
			const offsetTop = focusedRef.current.offsetTop
			const marginTop = Math.round(Number.parseFloat(cardStyles.marginTop))
			const paddingTop = Number.parseInt(wrapperStyles.paddingTop)

			// determine if parent's padding is included in offset top
			let newScrollAdjust = scrollAdjust
			if (gridScroll === 0 && offsetTop - paddingTop === marginTop) {
				newScrollAdjust = paddingTop
				setScrollAdjust(newScrollAdjust)
			}

			setGridScroll(offsetTop - newScrollAdjust + marginTop)

			// Get next page
			if (cards.length > 7 && index > cards.length - 7) {
				paginateCatchupGrid && paginateCatchupGrid()
			}
		}
		setShowFloatingGuide(false)
	}

	const { focusKey, ref, hasFocusedChild } = useFocusable({
		focusKey: 'GRID',
		onBlur: () => setGridScroll(0),
		saveLastFocusedChild: false,
		trackChildren: true,
	})

	useEffect(() => {
		if (hasFocusedChild) {
			timer = setTimeout(() => {
				setShowFloatingGuide(true)
			}, 3000)
			return () => clearTimeout(timer)
		}
	}, [hasFocusedChild, focusedIndex])

	useEffect(() => {
		if (!hasFocusedChild) {
			setShowFloatingGuide(false)
		}
	}, [hasFocusedChild])

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={`${styles.grid_wrapper}`} ref={wrapperRef}>
				<div className={styles.grid} ref={ref} style={{ transform: `translate3d(0, -${gridScroll}px, 0)` }}>
					{cards.map((card, index) => {
						return (
							<CardVOD16x9
								index={index}
								item={card}
								itemFocus={changeGridFocus}
								key={index}
								onSelect={onWatchlistSelect}
								watchListGrid={true}
								showFloatingGuide={showFloatingGuide}
								rowTitle={rowTitle}
							/>
						)
					})}
				</div>
				<div className={styles.floatingGuide_wrapper}>{showFloatingGuide && <FloatingGuide />}</div>
			</div>
		</FocusContext.Provider>
	)
}
export default Grid
