import { useRef, useState } from 'react'
import { FocusContext, setFocus, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect } from 'react'
import EpisodeListItem from '../../container/EpisodeListItem/EpisodeListItem'
import styles from './EpisodeList.module.scss'

export function EpisodeList({ data, itemFocus, navigateToPlayer, currentSeasonId }) {
	const listWrapper = useRef()
	const onLoad = useRef(true)
	const [listScroll, setListScroll] = useState(0)
	const [episodeKey, setEpisodeKey] = useState('EpisodeListItem-0')

	const { focusKey, ref, hasFocusedChild } = useFocusable({
		trackChildren: true,
		preferredChildFocusKey: episodeKey,
	})

	const changeListFocus = (focusedRef, item) => {
		if (focusedRef.current) {
			setTimeout(() => {
				itemFocus?.(item)
				setListScroll(focusedRef?.current?.offsetLeft - Number.parseInt(getComputedStyle(ref?.current)?.paddingLeft))
			}, 1)
		}
	}

	const onLoadEpisodeChange = (key, focusedRef) => {
		if (onLoad.current) {
			setListScroll(focusedRef?.current?.offsetLeft - Number.parseInt(getComputedStyle(ref?.current)?.paddingLeft))
			setEpisodeKey(key)
			onLoad.current = false
		}
	}

	useEffect(() => {
		listScroll && setListScroll(0)
		//if focus on the card when season is updating, setting focus on first card
		hasFocusedChild && setFocus('EpisodeListItem-0')
	}, [data])

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={styles.episodes} ref={listWrapper}>
				<div className={styles.episode_list_wrapper}>
					<div ref={ref} className={styles.episode_list} style={{ transform: `translate3d(-${listScroll}px,0,0)` }}>
						<div className={styles.episode_wrapper}>
							{data?.videos?.map((video, index) => (
								<EpisodeListItem
									seasonNumber={data.seasonNumber}
									index={index}
									focusKey={'EpisodeListItem-' + index}
									data={video}
									itemFocus={changeListFocus}
									key={'EpisodeListItem' + index}
									navigateToPlayer={navigateToPlayer}
									currentSeasonId={currentSeasonId}
									onLoadEpisodeChange={onLoadEpisodeChange}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default EpisodeList
