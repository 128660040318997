import { configureStore } from '@reduxjs/toolkit'
import { ReportMiddleware } from '@dstv-web-leanback/dstv-frontend-middleware'
import { epicMiddleware, listenerInstance, middlewareConfig, rootEpic } from './middleware'
import rootReducer from './reducers'

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware(middlewareConfig)
			.prepend(listenerInstance.middleware)
			.concat([epicMiddleware, ReportMiddleware]),
	devTools: process.env.NODE_ENV !== 'production',
})

epicMiddleware.run(rootEpic)

export default store
