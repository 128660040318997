import React, { useEffect, useState } from 'react'

/**
 * @param {React.ImgHTMLAttributes} props
 * @param {string} props.fallback
 * @returns
 */
export function LazyImage({ src, fallback, ...props }) {
	const [imageSrc, setImageSrc] = useState(undefined)
	const [error, setError] = useState(false)

	useEffect(() => {
		if (src) {
			const image = new Image()
			image.onload = (ev) => {
				setError(false)
				setImageSrc(src)
			}

			image.onerror = () => {
				setError(true)
				setImageSrc(undefined)
			}

			image.src = src

			return () => {
				image.onload = undefined
				image.onerror = undefined
				image.src = ''
				setImageSrc(undefined)
				setError(false)
			}
		} else {
			setImageSrc(undefined)
			setError(true)
		}
	}, [src])

	if (!error && !imageSrc) return <div {...props}>&nbsp;</div>

	return <img {...props} src={error ? fallback : imageSrc} />
}
export default LazyImage
