import { useSelector } from 'react-redux'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

import {
	BOOKMARK_LIMITATION,
	BOOKMARKS_FEATURE_KEY,
	getShowPageVodDetails,
	SHOWPAGE_FEATURE_KEY,
} from '@dstv-web-leanback/dstv-frontend-services'
import { ProgressBar } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './EpisodeListItem.module.scss'
import play_icon from '../../../assets/images/smarttv/play_cw_icon.svg'
import play_icon_active from '../../../assets/images/smarttv/play_cw_focus_icon.svg'
import thumbFallback from '../../../assets/images/thumb_fallback.png'
import { getProgressBarDuration } from './helpers'
import { useEffect, useMemo, useRef } from 'react'
import { dateUtils, useIntersectionObserver } from '@dstv-web-leanback/dstv-frontend-utils'
import Image from '../../core/Image/LazyImage'

export function EpisodeListItem({
	data,
	itemFocus,
	focusKey,
	index,
	seasonNumber,
	navigateToPlayer,
	currentSeasonId,
	onLoadEpisodeChange,
}) {
	const bookmarksState = useSelector((state) => state[BOOKMARKS_FEATURE_KEY])
	const showpageState = useSelector((state) => state[SHOWPAGE_FEATURE_KEY])
	const { watchButton } = showpageState
	const currentItem = useRef(null)
	const bookmark = bookmarksState?.bookmarks?.find(
		(item) => item.genref === data?.external_reference?.video_meta_id.split('_')[1]
	)
	const timeLeft = Math.abs(data?.duration_in_seconds - bookmark?.timeInSeconds)
	const bookmarkDuration = useMemo(() => {
		return getProgressBarDuration(bookmark?.timeInSeconds, data?.duration_in_seconds)
	}, [bookmark?.timeInSeconds, data?.duration_in_seconds])
	const { episode, title } = data

	const { ref, focused } = useFocusable({
		focusKey: focusKey,
		onEnterPress: () => {
			navigateToPlayer(getShowPageVodDetails(currentItem.current), bookmarkDuration > BOOKMARK_LIMITATION, 'Play Icon')
		},
	})

	const isIntersecting = useIntersectionObserver(ref)

	useEffect(() => {
		if (watchButton?.resume_video_id === data?.id) {
			onLoadEpisodeChange(focusKey, ref)
		}
		if (focused && data) {
			currentItem.current = { ...data, seasonId: currentSeasonId, focusKey: focusKey }
			itemFocus?.(ref, { ...data, seasonNumber })
		}
	}, [data, focused, watchButton])

	return (
		<div ref={ref} className={`${styles.episode_list_item} ${focused ? styles.focus : ''}`}>
			{isIntersecting && (
				<div className={styles.episode_image_wrapper}>
					<Image
						src={data?.images['play-image']?.MEDIUM || thumbFallback}
						alt="episode_image"
						loading="lazy"
						className={styles.episode_image}
						fallback={thumbFallback}
					/>
					<img
						src={focused ? play_icon_active : play_icon}
						alt="play"
						className={`${styles.card_playButton} ${styles.centered_both}`}
					/>
					{!!bookmark?.timeInSeconds && bookmarkDuration <= BOOKMARK_LIMITATION && (
						<>
							<span className={styles.time_left}>{dateUtils.convertSecsToHrsMins(timeLeft)} left</span>
							<div className={`${styles.progress_wrapper} ${styles.centered}`}>
								{!!bookmark?.timeInSeconds && <ProgressBar progress={bookmarkDuration} active={focused} />}
							</div>
						</>
					)}
				</div>
			)}
			<span className={styles.card_title}>{title}</span>
			<span className={styles.card_metadata}>
				S{seasonNumber} Ep{episode}
			</span>
		</div>
	)
}
export default EpisodeListItem
