import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'

import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { getEndpointsFromMenu } from '../../utils/storageHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'
import { getHomeRowEndpoint, getRowHeight } from './helper'
import { SET_ACTIVE_PROFILE_ID } from '../profile/profile.slice'

export const HOME_FEATURE_KEY = 'home'

/*
 * Create our slice
 */
export const homeSlice = createSlice({
	name: HOME_FEATURE_KEY,
	initialState: {
		rows: [],
		contentrows: [],
		active_carousel_row_index: 0,
		active_carousel_row_id: undefined,
		active_carousel_id: undefined,
		active_carousel_index: 0,
	},
	reducers: {
		GET_CONTENT: (state, action) => {
			delete state.error
			delete state.serverError
			if (action.payload.currentSection) {
				state.rows = []
			}
			state.contentrows = []
			state.loading = true
			if (!action.payload.mystuff) {
				delete state.loadToastSuccess
				delete state.loadToastError
			} else {
				state.loadToastSuccess = true
				if (action.payload.mystuff) {
					state.toastId = 'myStuffToast'
					state.toastMessage = 'Removed from My Stuff'
				}
			}
		},
		GET_CONTENT_SUCCESS: (state, action) => {
			let offset = 0

			if (action.payload?.url) {
				state.contentrows = action.payload.rows
				state.contentrows.forEach((item) => {
					item.rowOffset = offset
					offset = offset + getRowHeight(item)
				})
			} else {
				state.rows = action.payload.rows
				state.rows.forEach((item) => {
					item.rowOffset = offset
					offset = offset + getRowHeight(item)
				})
				state.homeOffset = offset
			}
			if (action.payload.myStuff) {
				state.focusRow = action?.payload?.focusRow
			}
			state.offset = offset
			state.loading = false
			state.selectRowIndex = null
		},
		GET_HOME_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		GET_HOME_CHANNEL_CARD_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_CHANNEL_CARD_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_EVENT_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_EVENT_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_CARD_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_CARD_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_CARD_INFO_ERROR: (state) => {
			state.loading = false
		},
		GET_HOME_VOD_CARD_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_VOD_CARD_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_INFO_ERROR: (state) => {
			state.loading = false
		},
		GET_HOME_TOAST_ERROR: (state) => {
			state.loading = false
			state.loadToastError = true
			state.toastId = 'continueWatchingToast'
			state.toastMessage = 'Unable to remove from Continue Watching'
		},
		SET_HOME_ACTIVE_CAROUSEL: (state, action) => {
			state.active_carousel_row_index = action.payload.rowIndex
			state.active_carousel_row_id = action.payload.rowId
			state.active_carousel_id = action.payload.id
			state.active_carousel_index = action.payload.index
		},
		GET_HOME_ROW: (state, action) => {
			state.loading = true
			state.loadToastSuccess = true
			if (action.payload.continueWatching) {
				state.toastId = 'continueWatchingToast'
				state.toastMessage = 'Removed from Continue Watching'
			}
		},
		GET_HOME_ROW_SUCCESS: (state, action) => {
			state.loading = false
			state.rows.forEach((row) => {
				if (row.id === action.payload.id) {
					row.items = action.payload.items.map((o) => {
						return {
							...row.items.find((item) => item.id === o.id),
							...o,
						}
					})
				}
			})
			state.focusRow = action.payload.focusRow
		},
		GET_HOME_ROW_ERROR: (state, action) => {
			state.rows = [...state.rows]
			state.focusRow = action.payload.focusRow
			state.loading = false
		},
		REMOVE_ITEM_FROM_ROW: (state, action) => {
			state.loading = true
		},
		CLEAR_FOCUS_ROW: (state, action) => {
			state.focusRow = null
		},
		CLEAR_CONTENT_ROW: (state, action) => {
			state.contentrows = null
		},
		SHOW_HOME_TOAST: (state, action) => {
			state.toastId = action.payload.id
			state.toastMessage = action.payload.message
		},
		CLEAR_CONTENT_ERROR: (state, action) => {
			delete state.error
			delete state.serverError
		},
		REMOVE_ITEM_FROM_MYSTUFF: (state, action) => {
			state.loading = true
		},
		GET_MYLIST_TOAST_ERROR: (state, action) => {
			state.loading = false
			state.loadToastError = true
			state.toastId = 'myStuffToast'
			state.toastMessage = 'Unable to remove from Mystuff'
		},
		CLEAR_TOAST: (state, action) => {
			state.toastId = null
			state.toastMessage = null
			state.loadToastSuccess = false
			state.loadToastError = false
		},
		GET_HOME_WATCHLIST: (state, action) => {
			state.loading = true
		},
		GET_MY_HOME_WATCHLIST_SUCCESS: (state, action) => {
			state.loading = false
			state.myStuffInfo = action.payload
			state.rows = [...state.rows]
			state.focusRow = action.payload.focusRow
			state.loading = false
			state.loadToastSuccess = true
		},
		TOGGLE_HOME_WATCHLIST: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
			state.toastId = 'myStuffToast'
			if (action.payload.Toggle === 'ADD') {
				state.toastMessage = 'Added to My Stuff'
			} else if (action.payload.Toggle === 'REMOVE') {
				state.toastMessage = 'Removed from My Stuff'
			}
			state.myStuffInfo = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(SET_ACTIVE_PROFILE_ID.type, (state) => {
			state.rows = []
			state.active_carousel_index = 0
			state.active_carousel_id = undefined
			state.active_carousel_row = undefined
			state.active_carousel_row_index = 0
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const homeReducer = homeSlice.reducer

/*
 * Export actions
 */
export const {
	GET_CONTENT,
	GET_CONTENT_SUCCESS,
	GET_HOME_ERROR,
	GET_HOME_INFO_ERROR,
	GET_HOME_TOAST_ERROR,
	GET_HOME_CHANNEL_CARD_INFO,
	GET_HOME_CHANNEL_CARD_INFO_SUCCESS,
	GET_HOME_EVENT_INFO,
	GET_HOME_EVENT_INFO_SUCCESS,
	GET_HOME_CARD_INFO,
	GET_HOME_CARD_INFO_SUCCESS,
	GET_HOME_VOD_CARD_INFO,
	GET_HOME_VOD_CARD_INFO_SUCCESS,
	SET_HOME_ACTIVE_CAROUSEL,
	GET_HOME_ROW,
	GET_HOME_ROW_SUCCESS,
	GET_HOME_ROW_ERROR,
	REMOVE_ITEM_FROM_ROW,
	CLEAR_FOCUS_ROW,
	CLEAR_CONTENT_ROW,
	SHOW_HOME_TOAST,
	CLEAR_CONTENT_ERROR,
	REMOVE_ITEM_FROM_MYSTUFF,
	GET_MYLIST_TOAST_ERROR,
	GET_HOME_WATCHLIST,
	GET_MY_HOME_WATCHLIST_SUCCESS,
	TOGGLE_HOME_WATCHLIST,
	CLEAR_TOAST,
} = homeSlice.actions

/*
 * Set up the redux-observable epic
 */
export const homeEpic = (action$) =>
	action$.pipe(
		ofType(
			GET_CONTENT.type,
			GET_HOME_CHANNEL_CARD_INFO.type,
			GET_HOME_EVENT_INFO.type,
			GET_HOME_VOD_CARD_INFO.type,
			GET_HOME_CARD_INFO.type,
			GET_HOME_ROW.type,
			REMOVE_ITEM_FROM_ROW.type,
			REMOVE_ITEM_FROM_MYSTUFF.type,
			GET_HOME_WATCHLIST.type,
			TOGGLE_HOME_WATCHLIST.type
		),
		mergeMap(homeService(action$))
	)

/*
 * Do API calls
 */
const homeService = (action$) => (action) => {
	switch (action.type) {
		case GET_CONTENT.type: {
			let endpoint
			if (action.payload && action.payload.url) {
				endpoint = action.payload.url
			} else endpoint = getEndpointsFromMenu(action.payload.currentSection)
			return HTTP.GET(endpoint, getHeaders(), getHomeSuccess(action), homeError(action), true)
		}
		case GET_HOME_CHANNEL_CARD_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeChannelCardInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_EVENT_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeEventInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_VOD_CARD_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeVodCardInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_CARD_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeCardInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_ROW.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeRowSuccess(action), homeRowError(action), true)
		}
		case REMOVE_ITEM_FROM_ROW.type: {
			let endpoint = action.payload.url
			return HTTP.DELETE(endpoint, null, getHeaders(), getContinueWatching(action), homeToastError(action), true)
		}
		case REMOVE_ITEM_FROM_MYSTUFF.type: {
			let endpoint = action.payload.url
			return HTTP.DELETE(endpoint, null, getHeaders(), getMystuff(action), myStuffError(action), true)
		}
		case GET_HOME_WATCHLIST.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeWatchlist(action), homeRowError(action), true)
		}
		case TOGGLE_HOME_WATCHLIST.type: {
			let endpoint = action.payload.url
			let payload = action.payload.body
			if (action.payload.Toggle === 'REMOVE') {
				return HTTP.DELETE(endpoint, null, getHeaders(), toggleHomeWatchListSuccess(action), homeRowError(action), true)
			} else if (action.payload.Toggle === 'ADD') {
				return HTTP.PUT(endpoint, payload, getHeaders(), toggleHomeWatchListSuccess(action), homeRowError(action), true)
			}
			break
		}
	}
}

/*
 * Dispatch actions based on API responses
 */

const getHomeSuccess = (action) => (response) => {
	//  TODO: remove the filteredResponse variable once empty state testing completed in mystuff page
	const filteredResponse = response.items.filter((item) => item.type !== 'searchable_with_grid')
	const nonEmptyRows = filteredResponse
		?.filter((row) => Array.isArray(row.items) && row.items.length > 0)
		.map((row) => ({
			...row,
			items: row.items.map((item) => ({
				...item,
				poster_image: getCardPosterImageURL(item, 'poster'),
				poster_image_landscape: getCardPosterImageURL(item, 'poster-landscape'),
				trailerDetails: item.links?.[1]?.rel.includes('stream') ? item.links[1].href : undefined,
			})),
		}))
	if (nonEmptyRows?.length >= 0) {
		return {
			type: GET_CONTENT_SUCCESS.type,
			payload: {
				rows: nonEmptyRows,
				url: action.payload?.url,
				myStuff: action.payload.mystuff,
				focusRow: action.payload.focusRow,
			},
		}
	} else {
		throw new Error()
	}
}

// This is for the home rows.
const getHomeChannelCardInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_CHANNEL_CARD_INFO_SUCCESS.type,
		payload: {
			response: response,
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'channel',
		},
	}
}

// This is for the current live event on a tv channel card
const getHomeEventInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_EVENT_INFO_SUCCESS.type,
		payload: {
			response: response.events?.[0] ? response.events[0] : response,
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'event',
		},
	}
}

// This is for VOD cards
const getHomeVodCardInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_VOD_CARD_INFO_SUCCESS.type,
		payload: {
			response: response,
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'card',
		},
	}
}

const getHomeCardInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_CARD_INFO_SUCCESS.type,
		payload: {
			response: response,
			resume_progress: action?.payload.item?.resume_progress_percentage,
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'card',
		},
	}
}

const getContinueWatching = (action) => (response) => {
	const row = action.payload.rows.find((o) => o.id === action.payload.item.rowId)
	return {
		type: GET_HOME_ROW.type,
		payload: {
			focusRow: action.payload.focusRow,
			rowId: row.id,
			url: getHomeRowEndpoint(row),
			continueWatching: true,
		},
	}
}

const getHomeRowSuccess = (action) => (response) => {
	return {
		type: GET_HOME_ROW_SUCCESS.type,
		payload: {
			items: response.items.map((item) => {
				return {
					...item,
					poster_image: getCardPosterImageURL(item, 'poster'),
					poster_image_landscape: getCardPosterImageURL(item, 'poster-landscape'),
				}
			}),
			id: action.payload.rowId,
			focusRow: action.payload.focusRow,
		},
	}
}
const getMystuff = (action) => (response) => {
	const row = action.payload
	return {
		type: GET_CONTENT.type,
		payload: {
			focusRow: action.payload.focusRow,
			rowId: row.id,
			currentSection: 'mystuff',
			mystuff: true,
		},
	}
}
const getHomeWatchlist = (action) => (response) => {
	return {
		type: GET_MY_HOME_WATCHLIST_SUCCESS.type,
		payload: {
			saved: response.saved,
			links: response.links,
		},
	}
}

const toggleHomeWatchListSuccess = (action) => (response) => {
	return {
		type: GET_MY_HOME_WATCHLIST_SUCCESS.type,
		payload: {
			focusRow: action.payload.focusRow,
		},
	}
}

const homeRowError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_HOME_ROW_ERROR.type,
				payload: {
					...errorResponse(response, action),
					focusRow: action.payload.focusRow,
				},
			},
			action
		)
	)
}

const homeError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_HOME_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const homeInfoError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_HOME_INFO_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const homeToastError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_HOME_TOAST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const myStuffError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_MYLIST_TOAST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

export const updateItemsWithCardInfoResponse = (state, action) => {
	try {
		// Home or content row
		const rows = state.contentrows ?? state.rows

		// Find indexes for the card to update
		const rowIndex = rows.findIndex((row) => row.id === action.payload.rowId)
		if (rowIndex === -1) return // Early return if row not found

		const currentCard = rows[rowIndex]?.items?.find((card) => card.id === action.payload.cardId)
		if (!currentCard) return // Early return if card not found

		const { channels, event, ...cardInfo } = action.payload.response

		// Assign properties
		assignProperties(currentCard, 'channel', channels)
		assignProperties(currentCard, 'event', event)
		assignProperties(currentCard, 'card', cardInfo)

		// Ensure fallback values for poster images and progress
		currentCard.timeShiftStream = currentCard.event_time_shift_stream || null
		currentCard.resume_progress_percentage = currentCard.resume_progress_percentage || null
		currentCard.channelNumber = currentCard.number
		currentCard.channelLogo = currentCard.channel_images?.find(
			(image) => image.rel.includes('XLARGE') && image.rel.includes('logos')
		)?.href

		//Handle the poster
		currentCard.trailerDetails =
			currentCard.card_related?.trailers?.[0]?.video_assets?.[0]?.url ??
			currentCard.card_trailers?.[0]?.video_assets?.[0]?.url ??
			null
		currentCard.showLogo = currentCard.card_images?.[0]?.href || null
		currentCard.poster_image = currentCard.poster_image || getCardPosterImageURL(currentCard, 'poster')
		currentCard.poster_image_landscape =
			currentCard.poster_image_landscape || getCardPosterImageURL(currentCard, 'poster-landscape')

		// Mark card info as fetched
		currentCard.fetched_info = true
	} catch (e) {
		console.error('Error updating card info:', e)
	}
}

const assignProperties = (currentCard, prefix, data) => {
	if (data) {
		Object.entries(data).forEach(([key, value]) => {
			currentCard[`${prefix}_${key}`] = value
		})
	}
}
