import { createSlice } from '@reduxjs/toolkit'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { mergeMap } from 'rxjs/operators'
import { HTTP } from '../../utils/httpHelper'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'

export const EPISODES_FEATURE_KEY = 'episodes'

export const initialEpisodesState = {
	episodes: [],
	loading: false,
}

export const episodesSlice = createSlice({
	name: EPISODES_FEATURE_KEY,
	initialState: initialEpisodesState,
	reducers: {
		GET_EPISODES: (state) => {
			delete state.error
			delete state.serverError
			state.episodes = []
			state.loading = true
		},
		GET_EPISODES_SUCCESS: (state, action) => {
			state.episodes = action.payload
			state.loading = false
			state.selectRowIndex = null
		},
		GET_EPISODES_ERROR: (state, action) => {
			state.episodes = []
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const episodesReducer = episodesSlice.reducer

/*
 * Export actions
 */
export const { GET_EPISODES, GET_EPISODES_SUCCESS, GET_EPISODES_ERROR } = episodesSlice.actions

/*
 * Set up the redux-observable epic
 */
export const episodesEpic = (action$) => action$.pipe(ofType(GET_EPISODES.type), mergeMap(episodesService(action$)))

/*
 * Do API calls
 */
export const episodesService = (action$) => (action) => {
	switch (action.type) {
		case GET_EPISODES.type: {
			let videoId = action.payload.id

			if (videoId.indexOf('_') > 0) {
				videoId = videoId.split('_')[1]
			}

			return HTTP.GET(
				ENV.BASE_URL + ENV.GET_PROGRAM.replace('{PROGRAM_ID}', videoId),
				getHeaders(),
				episodesSuccess(action),
				episodesError(action)
			)
		}
	}
}

const episodesSuccess = (action) => (response) => {
	return {
		type: GET_EPISODES_SUCCESS.type,
		payload: response.items?.[0].program?.seasons?.[0].videos.map((video) => {
			let videoId = video.id

			if (videoId.indexOf('_') > 0) {
				videoId = videoId.split('_')[1]
			}

			return {
				...video,
				id: videoId,
			}
		}),
	}
}

const episodesError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_EPISODES_ERROR.type,
				payload: {
					videoId: action.payload.videoId,
				},
			},
			action
		)
	)
}
