import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useDispatch, useSelector } from 'react-redux'

import {
	Button,
	Iplate,
	PlayerButton,
	PlayerProgressBar,
	PlayerSettingsModal,
	PlayerThumbnail,
	BackToLiveButton,
	TimeWidget,
	AutoPlayNextPopup,
	isLiveEvent,
	getPrevAndNextReverseEpgItem,
	ModalOverlay,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	SAVE_AUDIO_LANGUAGE,
	SAVE_BANDWIDTH,
	useNavigation,
	CLOUD_DVR_KEY,
	GET_CURRENT_EVENT_BY_ID,
	EVENTS_FEATURE_KEY,
	PLAY,
	SELECTED_CHANNEL_EVENT,
	UPDATE_SELECTED_EVENT_TAG,
	UPDATE_PREV_NEXT_EVENT,
	WATCH_FROM_START_LIVE,
	REVERSE_EPG_EVENT_CARD_CLICKED,
	CHANNEL_LINEUP_BUTTON_CLICKED,
	NOTIFICATION_OVERLAY_BUTTON_CLICKED,
	DISPLAY_NOTIFICATION_OVERLAY,
	UPDATE_FOCUSED_ITEM,
	PLAY_EVENT_BUTTON_CLICKED,
	GET_RECORDING_STATUS,
	getRecordingUrl,
	TOGGLE_RECORDING,
	UPDATE_LIVE_EVENT_ID,
	WATCH_FROM_START_EPG,
	DELETE_SELECTED_ITEMS,
	isFutureEvent,
	configKeyEnabled,
} from '@dstv-web-leanback/dstv-frontend-services'
import { useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

// Image assets
import iconCog from '../../../../assets/images/icons/icon-player-cog.svg'
import iconNext from '../../../../assets/images/icons/icon-player-next.svg'
import iconPause from '../../../../assets/images/icons/icon-player-pause.svg'
import iconPlay from '../../../../assets/images/icons/icon-player-play.svg'
import watchfromStartIcon from '../../../../assets/images/icons/icon-watch-from-start.svg'

import styles from '../PlayerControls.module.scss'
import {
	getDefaultSubOptionsValues,
	getPlayerSettingsOptions,
	convertSecondsToTime,
	getCurrentEpgEvent,
} from './helper'
import moment from 'moment'

export function LiveControls({
	audioLanguages,
	handleAudioChange,
	handleBandwidthChange,
	handleSubOptionChange,
	playing,
	playPause,
	switchChannel,
	seekTo,
	seekRange,
	liveSeekTime,
	handleBackToLive,
	currentTime,
	liveSeekTimeEnd,
	playbackCapabilities,
	dvrWatchfromStartLive,
	video,
	onToast,
	stopStream,
	stopPlaying,
	reverseEpgEnabled,
	allowPastEventRewatch,
}) {
	// Variables
	const SWITCH_TIMEOUT = 1500
	const CONTROLS_TIMEOUT = 7000
	const SEEK_INTERVAL = 10
	const CONTINUE_WATCHING_POPUP_COUNTER = 10
	const [showIPlate, setShowIplate] = useState(false)
	const [prevChannel, setPrevChannel] = useState(undefined)
	const [nextChannel, setNextChannel] = useState(undefined)
	const [controlsVisible, setControlsVisible] = useState(true)
	const [showPlayerSettings, setShowPlayerSettings] = useState(false)
	const [seeking, setSeeking] = useState(false)
	const [seekAmount, setSeekAmount] = useState(0)
	const [seekTime, setSeekTime] = useState(liveSeekTime)
	const [isLiveStream, setIsLiveStream] = useState(true)
	const [currentEventId, setCurrentEventId] = useState('')
	const [time, setTime] = useState(moment())
	const [showWatchConfirmationDialog, setShowWatchConfirmationDialog] = useState()
	const [showAutoplayNextPopup, setShowAutoplayNextPopup] = useState(false)
	const [modalData, setModalData] = useState()
	const [modalOptions, setModalOptions] = useState([])
	const [selectedCorrelationId, setSelectedCorrelationId] = useState(undefined)

	const channelsState = useSelector((state) => state.channels)
	const playerState = useSelector((state) => state.player)
	const configState = useSelector((state) => state.config)
	const adPlaying = useSelector((state) => state.adPlayer.playing)
	const eventsState = useSelector((state) => state[EVENTS_FEATURE_KEY])

	const stopStreamEnabled = configKeyEnabled(configState, 'stop_stream_enabled') ?? true
	const [channelIndex, setChannelIndex] = useState(
		channelsState.all_channels.findIndex(
			(channel) =>
				channel.number ===
				(playerState.channelNumber || playerState.details.channelNumber || playerState.details.number)
		)
	)

	const controlsDebounce = useDebounce(CONTROLS_TIMEOUT, showControls, hideControls)
	const switchChannelDebounce = useDebounce(
		SWITCH_TIMEOUT,
		(tag) => {
			const index = channelsState.all_channels.findIndex((channel) => channel.tag === tag)
			controlsDebounce()
			setChannelIndex(index)
		},
		(tag) => {
			switchChannel(tag)
		}
	)

	const dispatch = useDispatch()
	const { navigateBack } = useNavigation()

	const options = useRef([])
	let seekTimer = useRef()

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'LIVE_CONTROLS',
		forceFocus: true,
		isFocusBoundary: true,
		preferredChildFocusKey: 'PLAY_PAUSE',
		onArrowPress: () => {
			controlsDebounce()
			setFocus('PLAY_PAUSE')
		},
		onBackPress: () => {
			if (controlsVisible) {
				hideControls()
			} else {
				navigateBack()
				if (stopStreamEnabled) {
					stopStream()
				}
			}
		},
	})

	// Effects
	useEffect(() => {
		focusSelf()
		controlsDebounce()

		return () => {
			setShowPlayerSettings(false)
		}
	}, [])

	useEffect(() => {
		if (!adPlaying) controlsDebounce()
	}, [adPlaying])

	useEffect(() => {
		setPrevChannel(channelsState.all_channels[channelIndex - 1])
		setNextChannel(channelsState.all_channels[channelIndex + 1])
	}, [channelIndex])

	// When the controls become visible, set focus to the PLAY_PAUSE button
	useEffect(() => {
		if (controlsVisible) {
			setFocus('PLAY_PAUSE')
		}
	}, [controlsVisible])

	useEffect(() => {
		options.current = getPlayerSettingsOptions(configState, audioLanguages)

		if (!playerState.savedAudioLanguage && !playerState.savedBandwidth) {
			const defaultLanguage = audioLanguages?.[0]?.language
			const defaultBandwidth = +localStorage.getItem('QUALITY_STORAGE_BITRATE') || 6000000

			dispatch(SAVE_AUDIO_LANGUAGE({ language: defaultLanguage }))
			dispatch(SAVE_BANDWIDTH({ bandwidth: defaultBandwidth }))

			handleAudioChange(defaultLanguage)
			handleBandwidthChange(defaultBandwidth)
		}
	}, [])
	useEffect(() => {
		if (liveSeekTimeEnd === currentTime) {
			setIsLiveStream(true)
		}
	}, [liveSeekTimeEnd])

	useEffect(() => {
		if (controlsVisible && channelsState?.channels?.length > 0 && !showIPlate & !playerState.isReverseEpgEvent) {
			const targetEpgChannel = getCurrentEpgEvent(channelsState, playerState.details?.channelTag)
			if (currentEventId !== '' && targetEpgChannel && currentEventId !== targetEpgChannel.id) {
				dispatch(GET_CURRENT_EVENT_BY_ID({ eventId: targetEpgChannel.id }))
				dispatch(
					UPDATE_LIVE_EVENT_ID({
						currentEventId: targetEpgChannel.id,
					})
				)
			}
			setCurrentEventId(targetEpgChannel?.id)
		}
	}, [controlsVisible])

	useEffect(() => {
		if (
			eventsState.nextEvent &&
			video.duration - video.currentTime <= CONTINUE_WATCHING_POPUP_COUNTER &&
			video.duration - video.currentTime > 0
		) {
			setShowAutoplayNextPopup(true)
		} else {
			setShowAutoplayNextPopup(false)
		}
	}, [video.currentTime])

	const switchEvent = useDebounce(
		500,
		(selectedChannelEvent) => {
			controlsDebounce()
			const { prevEvent, nextEvent } = getPrevAndNextReverseEpgItem(
				eventsState.reverseEpgChannelEvents,
				selectedChannelEvent.id
			)
			dispatch(UPDATE_PREV_NEXT_EVENT({ nextEvent, prevEvent }))
		},
		(selectedChannelEvent) => {
			if (selectedChannelEvent) {
				const selectedEventDetails = getSelectedEventDetails(selectedChannelEvent)
				dispatch(
					UPDATE_SELECTED_EVENT_TAG({
						selectedChannelEventId: selectedEventDetails.selectedChannelEventId,
					})
				)
				updateSelectedChannelEvent(selectedEventDetails)
				if (selectedEventDetails.isLiveEvent) {
					handleWatchFromStartLive(selectedEventDetails)
				} else {
					handleReverseEpgEvent(selectedEventDetails)
				}
			}
		}
	)

	const handleActionDebounce = useDebounce(
		250,
		null,
		useCallback((action) => dispatch(action), [dispatch])
	)

	useEffect(() => {
		if (onToast && eventsState.toastMessage) {
			onToast(eventsState.toastMessage, eventsState.toastError)
		}
	}, [eventsState.toastMessage, eventsState.toastError])

	const channelFallback = useMemo(() => {
		if (!playerState.details?.channelTag || playerState.channelLogo || playerState.details?.channelLogo) {
			return undefined
		}

		const channel = channelsState.all_channels?.find((o) => playerState.details?.channelTag === o.tag)

		return channel
	}, [playerState.details?.channelTag])

	// Functions
	const onCogBtnSelect = () => {
		setShowPlayerSettings(!showPlayerSettings)
	}

	function showControls() {
		setControlsVisible(true)
		setTime(moment())
	}

	function hideControls() {
		setControlsVisible(false)
	}

	const handleSeek = (direction) => {
		controlsDebounce()
		showControls()
		setSeeking(true)

		const amount = direction === 'right' ? seekAmount + SEEK_INTERVAL : seekAmount - SEEK_INTERVAL
		setSeekAmount(amount)

		if (seekRange() <= liveSeekTime + amount) setSeekTime(seekRange())
		else if (liveSeekTime + amount <= 0) setSeekTime(0)
		else setSeekTime(liveSeekTime + amount)

		clearTimeout(seekTimer.current)
		seekTimer.current = setTimeout(() => {
			seekTo(seekAmount)
			setSeekAmount(0)
			setSeeking(false)
		}, 500)
	}

	const handleSeekReverseEpg = (direction) => {
		controlsDebounce()
		setSeeking(true)

		const amount = direction === 'right' ? seekAmount + SEEK_INTERVAL : seekAmount - SEEK_INTERVAL
		setSeekAmount(amount)
		let newSeekTime = video.currentTime + amount
		if (newSeekTime < 0) {
			newSeekTime = 0
		} else if (newSeekTime > video.duration) {
			newSeekTime = video.duration
		}
		setSeekTime(newSeekTime)

		clearTimeout(seekTimer.current)
		seekTimer.current = setTimeout(() => {
			seekTo(video.currentTime + amount)
			setSeekAmount(0)
			setSeeking(false)
		}, 500)
	}

	const getPlayerProgress = () => {
		if (playerState.type === 'live' && !playerState.isReverseEpgEvent) {
			return (Math.abs(seekTime) / seekRange()) * 100
		}
		return (currentTime / video.duration) * 100
	}
	const getSeekProgress = () => {
		if (playerState.type === 'live' && !playerState.isReverseEpgEvent) {
			return (Math.abs(seekTime) / seekRange()) * 100
		}
		return (seekTime / video.duration) * 100
	}
	const getPlayerSeekTime = () => {
		if (playerState.type === 'live' && !playerState.isReverseEpgEvent) {
			return '-' + convertSecondsToTime(seekRange() - seekTime)
		}
		return convertSecondsToTime(seekTime)
	}

	const updateSelectedChannelEvent = (selectedEventDetails) => {
		dispatch(SELECTED_CHANNEL_EVENT(selectedEventDetails))
	}

	const handleWatchFromStartLive = useCallback(
		(selectedEventDetails) => {
			const { prevEvent, nextEvent } = getPrevAndNextReverseEpgItem(
				eventsState.reverseEpgChannelEvents,
				selectedEventDetails.selectedChannelEventId
			)
			dispatch(UPDATE_PREV_NEXT_EVENT({ nextEvent, prevEvent }))
			stopPlaying()

			dispatch(
				WATCH_FROM_START_LIVE({
					details: selectedEventDetails.playerDetails.details,
					channelNumber: playerState.channelNumber || playerState.details.channelNumber || playerState.details.number,
					url: playerState.url,
					type: 'live',
					buttonText: 'watch from start',
					timeShiftUrl: playerState.timeShiftUrl,
				})
			)
			setIsLiveStream(false)
		},
		[
			eventsState.reverseEpgChannelEvents,
			playerState.channelNumber,
			playerState.details,
			playerState.url,
			playerState.timeShiftUrl,
		]
	)

	const handleReverseEpgEvent = useCallback(
		(selectedEventDetails) => {
			const { prevEvent, nextEvent } = getPrevAndNextReverseEpgItem(
				eventsState.reverseEpgChannelEvents,
				selectedEventDetails.selectedChannelEventId
			)

			dispatch(UPDATE_PREV_NEXT_EVENT({ nextEvent, prevEvent }))

			setIsLiveStream(false)
			const playerDetails = {
				...selectedEventDetails.playerDetails,
				isReverseEpgEvent: !selectedEventDetails.isLiveEvent,
				reverseEpgChannelEventId: !selectedEventDetails.isLiveEvent
					? selectedEventDetails.selectedChannelEventId
					: null,
			}
			dispatch(
				PLAY({
					...playerDetails,
					channelLogo: eventsState.channelLogo,
					channelNumber: eventsState.channelNumber,
					channelTag: eventsState.channel_tag,
					url: playerState.url,
				})
			)
			dispatch(
				UPDATE_SELECTED_EVENT_TAG({
					selectedChannelEventId: selectedEventDetails.selectedChannelEventId,
				})
			)
		},
		[
			eventsState.reverseEpgChannelEvents,
			eventsState.channelLogo,
			eventsState.channelNumber,
			eventsState.channel_tag,
			playerState.url,
		]
	)

	const watchFromStartReverseEpgSegment = useCallback(() => {
		dispatch(
			WATCH_FROM_START_EPG({
				url: playerState.url,
				channelNumber: playerState.channelNumber || playerState.details.channelNumber || playerState.details.number,
				details: playerState.details,
				type: 'live',
				buttonText: 'watch from start',
			})
		)
	}, [playerState.channelNumber, playerState.details, playerState.url])

	const getWatchPopupDetails = useCallback(
		(selectedEventDetails) => {
			const watchText =
				selectedEventDetails.isLiveEvent || playerState.eventId === selectedEventDetails.selectedChannelEventId
					? 'Watch From Start'
					: 'Watch'

			setSelectedCorrelationId(selectedEventDetails.correlation_id)

			if (selectedEventDetails.isFutureEvent) {
				return []
			}

			return [
				{
					key: 'watch',
					text: watchText,
					onSelect: () => {
						dispatch(UPDATE_FOCUSED_ITEM({ focusedEventId: null }))
						setShowWatchConfirmationDialog(false)
						setShowIplate(false)

						selectedEventDetails.currentEventId = selectedEventDetails.selectedChannelEventId
						if (!allowPastEventRewatch) {
							dvrWatchfromStartLive(selectedEventDetails.playerDetails.details)
						} else if (selectedEventDetails.isLiveEvent) {
							// Watch from start live
							handleWatchFromStartLive(selectedEventDetails)
						} else if (selectedEventDetails.selectedChannelEventId === playerState.eventId) {
							// Watch from start reverse epg event
							setIsLiveStream(false)
							seekTo(0)
							setFocus('PLAY_PAUSE')
							watchFromStartReverseEpgSegment()
						} else {
							// Watch reverse epg event
							handleReverseEpgEvent(selectedEventDetails)
						}
					},
				},
			]
		},
		[
			playerState.eventId,
			handleWatchFromStartLive,
			handleReverseEpgEvent,
			watchFromStartReverseEpgSegment,
			dvrWatchfromStartLive,
		]
	)

	const getSelectedEventDetails = (item, eventId, url) => {
		return {
			selectedChannelEventId: item.id,
			currentEventId: eventId,
			title: item.main_title || item.displayTitle,
			displayItemDetailedTitle: item.displayItemDetailedTitle,
			isLiveEvent: isLiveEvent(item.start_date_time, item.end_date_time),
			isFutureEvent: isFutureEvent(item.start_date_time, item.end_date_time),
			correlation_id: item.correlation_id,
			recording: item.recording,
			playerDetails: {
				isReverseEpgEvent: true,
				reverseEpgChannelEventId: item.id,
				details: item,
				url: url,
				type: 'live',
			},
		}
	}

	const handleEventFocus = useCallback((ref, carouselIndex, item) => {
		dispatch(DELETE_SELECTED_ITEMS())
		const url = getRecordingUrl(item, 'recording', 'GET')
		if (url)
			handleActionDebounce(
				GET_RECORDING_STATUS({
					url,
					title: `${item.title} ${item.displayItemDetailedTitle}`.trim(),
					correlation_id: item.correlation_id,
					isFutureEvent:
						isFutureEvent(item.start_date_time, item.end_date_time) ||
						isLiveEvent(item.start_date_time, item.end_date_time),
				})
			)
	}, [])

	const handleIplateSelect = useCallback(
		(item) => {
			dispatch(
				REVERSE_EPG_EVENT_CARD_CLICKED({
					channel_name: playerState.details.channelName,
					content_date_time: item.start_date_time,
					channel_number: playerState.details.channelNumber,
					content_discovery_referrer: 'Channel Lineup',
					content_discovery_section: 'Live TV',
					content_discovery_category: playerState.details.channelCategory,
					title: item.main_title || item.displayTitle,
				})
			)
			item && dispatch(UPDATE_FOCUSED_ITEM({ focusedEventId: item.id }))

			const selectedEventDetails = getSelectedEventDetails(item, playerState.eventId, playerState.url)

			if (item) {
				updateSelectedChannelEvent(selectedEventDetails)
			}
			const options = getWatchPopupDetails(selectedEventDetails)
			setModalData({
				title: item.title,
				subtitle: item.displayItemDetailedTitle,
				synopsis: item.synopsis,
				image: item.image,
			})
			setModalOptions(options)
			setShowWatchConfirmationDialog(true)
		},
		[playerState.details, playerState.eventId, getWatchPopupDetails, playerState.url]
	)

	const cancelWatch = useCallback(() => {
		dispatch(UPDATE_FOCUSED_ITEM({ focusedEventId: null }))
		setShowWatchConfirmationDialog(false)
		setFocus(`CARD_${eventsState.selectedChannelEventId}`)
		setModalData(undefined)
		setModalOptions([])
	}, [eventsState.selectedChannelEventId])

	const selectBackToLive = () => {
		const item = eventsState.nextEvent
		dispatch(
			NOTIFICATION_OVERLAY_BUTTON_CLICKED({
				overlay: 'Continue Watching',
				title: item.main_title || item.displayTitle,
				buttonText: 'Back to Live',
			})
		)
		handleBackToLive()
	}

	const handleAutoPlayNextEvent = () => {
		const item = eventsState.nextEvent
		const isLive = isLiveEvent(item.start_date_time, item.end_date_time)

		dispatch(
			NOTIFICATION_OVERLAY_BUTTON_CLICKED({
				overlay: 'Continue Watching',
				title: item.main_title || item.displayTitle,
				buttonText: 'Continue Watching',
			})
		)

		const playerDetails = {
			isReverseEpgEvent: !isLive,
			reverseEpgChannelEventId: !isLive ? item.id : null,
			details: item,
			url: playerState.url,
			type: 'live',
		}

		dispatch(
			SELECTED_CHANNEL_EVENT({
				selectedChannelEventId: item.id,
				currentEventId: playerState.eventId,
				title: item.main_title || item.displayTitle,
				displayItemDetailedTitle: item.displayItemDetailedTitle,
				isLiveEvent: isLive,
				playerDetails,
			})
		)

		const { prevEvent, nextEvent } = getPrevAndNextReverseEpgItem(eventsState.reverseEpgChannelEvents, item.id)

		item && dispatch(UPDATE_PREV_NEXT_EVENT({ nextEvent: !isLive ? nextEvent : null, prevEvent }))

		if (isLive) {
			dispatch(
				WATCH_FROM_START_LIVE({
					...playerDetails,
					channelNumber: playerState.channelNumber || playerState.details.channelNumber || playerState.details.number,
					url: playerState.url,
					type: 'live',
					buttonText: 'watch from start',
				})
			)
			setIsLiveStream(false)
		} else {
			dispatch(
				PLAY({
					...playerDetails,
					channelLogo: eventsState.channelLogo,
					channelNumber: eventsState.channelNumber,
					channelTag: eventsState.channel_tag,
				})
			)
		}
	}

	const handleWatchFromStart = useCallback(() => {
		if (playerState.isReverseEpgEvent) {
			seekTo(0)
			setFocus('PLAY_PAUSE')
			watchFromStartReverseEpgSegment()
		} else {
			dvrWatchfromStartLive(playerState.details)
		}
	}, [dvrWatchfromStartLive, playerState.isReverseEpgEvent])

	if (eventsState.nextEvent && showAutoplayNextPopup) {
		return (
			<AutoPlayNextPopup
				title="Playing Next"
				heading={eventsState.nextEvent.displayTitle}
				subHeading={eventsState.nextEvent.episodeSeason}
				primaryButtonTitle="Continue Watching"
				primaryButtonAction={handleAutoPlayNextEvent}
				secondaryButtonTitle="Back To Live"
				secondaryButtonAction={selectBackToLive}
				initAction={() => {
					dispatch(
						DISPLAY_NOTIFICATION_OVERLAY({
							overlay: 'Continue Watching',
							title: eventsState.nextEvent.displayTitle,
						})
					)
				}}
				size="large"
				withIcon={false}
				withAnimation={true}
				showOverlay={true}
				withBackground={false}
			/>
		)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div
				ref={ref}
				className={`${styles.live_controls_wrapper} ${
					controlsVisible || showPlayerSettings || showIPlate ? styles.visible : ''
				}`}
			>
				{(controlsVisible || showPlayerSettings || showIPlate) && (
					<>
						<div className={styles.time_widget_wrapper}>
							<TimeWidget currentTime={time} player={true} />
						</div>
						<div className={styles.player_controls}>
							{seeking ? (
								<PlayerThumbnail
									currentTime={seekTime}
									duration={liveSeekTime}
									position={seeking ? getSeekProgress() : getPlayerProgress()}
									time={getPlayerSeekTime()}
									hideThumbnailPreview={true}
									collapseTimestamp={playerState.isReverseEpgEvent}
								/>
							) : (
								<div className={styles.metadata_wrapper}>
									<div className={styles.channel_details}>
										{(playerState.channelLogo || playerState.details?.channelLogo || channelFallback?.logo) && (
											<img
												src={playerState.channelLogo || playerState.details?.channelLogo || channelFallback?.logo}
											></img>
										)}
										<h5>
											{playerState.channelNumber || playerState?.details?.channelNumber || channelFallback?.number}
										</h5>
									</div>
									<div className={styles.title_wrapper}>
										<h1 className={`${styles.title} ${showIPlate ? styles.hidden : ''}`}>
											{playerState.title ? playerState.title : playerState.details.title}
										</h1>
										{!showIPlate && (
											<h3 className={`${styles.subtitle} ${showIPlate ? styles.hidden : ''}`}>
												{playerState.episode_title ? playerState.episode_title : playerState.details?.episode_title}
											</h3>
										)}
									</div>
								</div>
							)}
							{!showIPlate && playerState.isReverseEpgEvent && (
								<div className={`${styles.times_wrapper} ${showIPlate ? styles.hidden : ''}`}>
									<div className={styles.times_left}>
										{convertSecondsToTime(seeking ? seekTime : video.currentTime)}
									</div>
									<div className={styles.times_right}>
										{convertSecondsToTime(seeking ? video.duration - seekTime : video.duration - video.currentTime)}
									</div>
								</div>
							)}
							<div className={`${styles.progress_wrapper} ${showIPlate ? styles.hidden : ''}`}>
								<PlayerProgressBar
									focusable={true}
									handleSeek={playerState.isReverseEpgEvent ? handleSeekReverseEpg : handleSeek}
									progress={seeking ? getSeekProgress() : getPlayerProgress()}
									seeking={seeking}
								/>
							</div>

							<div className={`${styles.controls_wrapper} ${showIPlate ? styles.hidden : ''}`}>
								<div className={styles.controls_left}>
									<PlayerButton
										icon={iconCog}
										onSelect={onCogBtnSelect}
										selected={showPlayerSettings}
										onNavigate={controlsDebounce}
									/>
								</div>
								<div className={styles.controls_center}>
									<div className={styles.prev_wrapper}>
										{playerState.isReverseEpgEvent && allowPastEventRewatch && eventsState.prevEvent && (
											<PlayerButton
												icon={iconNext}
												iconReversed={true}
												onSelect={() => {
													dispatch(
														PLAY_EVENT_BUTTON_CLICKED({
															buttonText: 'Play Previous Event',
															title: eventsState.prevEvent.displayTitle,
														})
													)
													switchEvent(eventsState.prevEvent)
												}}
												label={'Play Previous'}
												labelPosition="left"
												size={'extra_small'}
												onNavigate={controlsDebounce}
											/>
										)}
										{!playerState.isReverseEpgEvent && prevChannel && (
											<PlayerButton
												icon={iconNext}
												iconReversed={true}
												onSelect={() => {
													switchChannelDebounce(prevChannel?.tag)
												}}
												label={prevChannel?.number}
												labelHeader={'Channel'}
												onNavigate={controlsDebounce}
											/>
										)}
									</div>
									<PlayerButton
										focusKey={'PLAY_PAUSE'}
										icon={playing ? iconPause : iconPlay}
										onSelect={() => {
											setIsLiveStream(false)
											playPause()
										}}
										onNavigate={controlsDebounce}
									/>
									<div className={styles.next_wrapper}>
										{playerState.isReverseEpgEvent && allowPastEventRewatch && eventsState.nextEvent && (
											<PlayerButton
												icon={iconNext}
												onSelect={() => {
													dispatch(
														PLAY_EVENT_BUTTON_CLICKED({
															buttonText: 'Play Next Event',
															title: eventsState.nextEvent.displayTitle,
														})
													)
													switchEvent(eventsState.nextEvent)
												}}
												label={'Play Next'}
												labelPosition="right"
												size={'extra_small'}
												onNavigate={controlsDebounce}
											/>
										)}
										{!playerState.isReverseEpgEvent && nextChannel && (
											<PlayerButton
												icon={iconNext}
												onSelect={() => {
													switchChannelDebounce(nextChannel?.tag)
												}}
												label={nextChannel?.number}
												labelHeader={'Channel'}
												labelPosition="right"
												onNavigate={controlsDebounce}
											/>
										)}
									</div>
								</div>
								{configState.data?.cloud_dvr_enabled?.flagValue === 'true' &&
									playbackCapabilities?.includes(CLOUD_DVR_KEY) && (
										<div className={styles.controls_right_livetv}>
											{!playerState.isReverseEpgEvent && getPlayerProgress() >= 97 && isLiveStream === true ? (
												<div className={styles.live_indicator}>
													<span className={styles.live_dot} />
													<span className={styles.title}>LIVE</span>
												</div>
											) : (
												<BackToLiveButton id={'Back_to_live'} title={'BACK TO LIVE'} onSelect={handleBackToLive} />
											)}

											{(playerState.timeShiftUrl || playerState.details.timeShiftUrl) && (
												<PlayerButton
													icon={watchfromStartIcon}
													label={'Watch from Start'}
													onSelect={handleWatchFromStart}
													onNavigate={controlsDebounce}
													size={'small'}
													labelPosition={'right'}
													iconSize={'large'}
												/>
											)}
										</div>
									)}
							</div>
							<div className={`${styles.line_up} ${showIPlate ? styles.hidden : ''}`}>
								{reverseEpgEnabled && eventsState.reverseEpgChannelEvents && allowPastEventRewatch ? (
									<Button
										id="CHANNEL_LINEUP"
										title={'Channel Lineup'}
										onSelect={() => {
											dispatch(
												CHANNEL_LINEUP_BUTTON_CLICKED({
													buttonText: 'Channel Lineup',
												})
											)
											setShowIplate(true)
											setFocus('IPLATE')
										}}
									/>
								) : reverseEpgEnabled && playerState.timeShiftUrl ? (
									<Button
										id="MORE_INFO"
										title={'Show Information'}
										onSelect={() => {
											setShowIplate(true)
											setFocus('IPLATE')
										}}
									/>
								) : (
									''
								)}
							</div>
						</div>
					</>
				)}

				{eventsState.reverseEpgChannelEvents?.length && showIPlate ? (
					<Iplate
						key={`iplate_${playerState.eventId}`}
						active={showIPlate}
						cards={
							allowPastEventRewatch
								? eventsState.reverseEpgChannelEvents
								: eventsState.reverseEpgChannelEvents.filter((item) => item.isLive)
						}
						initialFocus={`CARD_${playerState.eventId}`}
						handleBack={() => {
							controlsDebounce()
							setShowIplate(false)
							setFocus('PLAY_PAUSE')
						}}
						onCarouselFocus={handleEventFocus}
						onSelect={handleIplateSelect}
					/>
				) : (
					''
				)}

				{showPlayerSettings && (
					<PlayerSettingsModal
						options={options.current}
						defaultValues={getDefaultSubOptionsValues(
							options.current,
							playerState.savedAudioLanguage,
							playerState.savedBandwidth
						)}
						handleBackButton={() => {
							setShowPlayerSettings(null)
						}}
						handleSubOptionSelection={(optionHeading, subOption) => {
							setShowPlayerSettings(false)
							setControlsVisible(false)
							handleSubOptionChange(optionHeading, subOption)
						}}
					/>
				)}

				{showWatchConfirmationDialog && (
					<ModalOverlay
						title={modalData?.title}
						subtitle={modalData.subtitle}
						synopsis={modalData?.synopsis}
						image={modalData?.image}
						options={[
							...modalOptions,
							...(selectedCorrelationId === eventsState.correlation_id && eventsState.recording?.recordable
								? [
										{
											key: 'record',
											text: !eventsState.recording.recorded ? 'Add to My Stuff' : 'Remove from My Stuff',
											onSelect: () => {
												setShowWatchConfirmationDialog(false)
												setShowIplate(false)
												setFocus('PLAY_PAUSE')

												const rel = eventsState.recording.recorded ? 'remove' : 'add'
												const method = eventsState.recording.recorded ? 'DELETE' : 'POST'
												const url = getRecordingUrl(eventsState.recording, rel, method)

												if (url) {
													dispatch(
														TOGGLE_RECORDING({
															url,
															method,
															correlation_id: eventsState.correlation_id,
															title: eventsState.recording.title,
															isFutureEvent: eventsState.recording.isFutureEvent,
														})
													)
												}
											},
										},
								  ]
								: []),
						]}
						handleBack={cancelWatch}
					/>
				)}
			</div>
		</FocusContext.Provider>
	)
}
export default LiveControls
