import { createSlice } from '@reduxjs/toolkit'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, filter } from 'rxjs/operators'

export const AVATARS_FEATURE_KEY = 'avatars'

/**
 * Avatars Slice
 */
export const avatarsSlice = createSlice({
	name: AVATARS_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_AVATARS: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		AVATARS_SUCCESS: (state, action) => {
			state.data = action.payload.data
			state.loading = false
		},
		AVATARS_ERROR: (state, action) => {
			state.loading = false
			state.error = action.payload.error
			state.serverError = action.payload.serverError || action.payload
		},
		CLEAR_AVATARS: (state, action) => {
			delete state.loading
			delete state.data
			delete state.error
			delete state.serverError
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const avatarsReducer = avatarsSlice.reducer

/**
 * Export actions
 */
export const { GET_AVATARS, AVATARS_SUCCESS, AVATARS_ERROR, CLEAR_AVATARS } = avatarsSlice.actions

/**
 * Avatars epic
 */

export const avatarsEpic = (action$) => action$.pipe(filter(GET_AVATARS.match), mergeMap(avatarsService(action$)))

/**
 * Avatars service
 */

const avatarsService = (action$) => (action) => {
	return HTTP.GET_WITH_CANCEL(
		ENV.GET_AVATARS_LIST,
		getHeaders(),
		avatarsSuccess(),
		avatarsError(action),
		'',
		action$.pipe(ofType(GET_AVATARS))
	)
}

const avatarsSuccess = (action) => (response) => {
	return {
		type: AVATARS_SUCCESS.type,
		payload: successResponse(response),
	}
}

const avatarsError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: AVATARS_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
