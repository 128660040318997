import styles from './Toast.module.scss'
import { useEffect, useState } from 'react'

export function Toast({ show, message, icon, className = '', duration, onClose }) {
	const [showToast, setShowToast] = useState(show)
	useEffect(() => {
		if (showToast) {
			const timeout = setTimeout(() => {
				setShowToast(false)
				onClose?.()
			}, duration)
			return () => {
				clearTimeout(timeout)
			}
		}
	}, [duration, showToast])

	useEffect(() => {
		setShowToast(show)
	}, [show])

	return (
		<div className={[showToast ? styles.show : styles.hide, styles.toast, className].join(' ')}>
			<img src={icon} alt="" className={styles.icon} />
			<p>{message}</p>
		</div>
	)
}
export default Toast
