import { getDeviceDetail, getDeviceType, getProfileId, getUserId, getUserName } from '../Report/reportData'

export const getPlayerErrorLog = (action, prevState) => {
	let {
		code: bitmovinCode = '',
		message: bitmovinMessage = '',
		timestamp: bitmovinTimestamp = '',
	} = action?.payload || {}
	return {
		contentPayload: {
			errorResponse: {
				bitmovinCode,
				bitmovinMessage,
				bitmovinTimestamp,
				playerPayload: prevState && prevState.player ? prevState.player : {},
				playbackEntitlement: prevState && prevState.entitlement ? prevState.entitlement : {},
				userId: getUserId(),
				userName: getUserName(),
			},
			errorType: 'playback',
		},
	}
}

export const getRemoteConfigError = (action) => {
	if (action.error) {
		return {
			contentPayload: {
				errorResponse: action.error,
				requestPayload: action.requestPayload,
				errorType: 'remote-config',
			},
		}
	}
}

export const getConnectSessionRefreshResponseData = (action) => {
	if (action.payload && action.payload.response) {
		return {
			contentPayload: {
				errorResponse: action.payload ? action.payload.response : {},
				errorType: 'connect-session-refresh',
			},
		}
	} else {
		return null
	}
}

export const getUnauthorizedResponseData = (action, prevState) => {
	//handle specific case for unauthorized error
	if (action?.payload?.errorCode === 403) {
		return {
			contentPayload: {
				userId: getUserId(),
				error: {
					errorResponse: action.payload,
					serverError: action?.payload?.serverError,
					errorType: 'entitlement unauthorized',
				},
			},
			type: 'Message Displayed to Customer',
		}
	}

	//return generic error
	return {
		contentPayload: {
			userId: getUserId(),
			error: {
				errorResponse: action.payload,
			},
		},
		type: 'Message Displayed to Customer',
	}
}

export const getMessageDisplayedToCustomerPayload = (payload) => {
	let contentPayload = {
		device_detail: getDeviceDetail(),
		device_portfolio: getDeviceType(),
		error_message: payload.errorMessage || '',
		error_reason: payload.errorReason || '',
		profile_id: getProfileId() || null,
		user_id: getUserId(),
	}

	return {
		type: 'Message Displayed to Customer',
		contentPayload,
	}
}

export const getConcurrencyData = (action, prevState) => {
	return {
		contentPayload: {
			errorResponse: action.payload,
			apiResponse: action.response,
			existingEntitlement: prevState.entitlement,
			errorType: 'conncurrency',
		},
	}
}
