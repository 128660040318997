import { useEffect, useMemo } from 'react'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useSelector } from 'react-redux'

import { ProgressBar } from '@dstv-web-leanback/dstv-frontend-components'
import { BOOKMARKS_FEATURE_KEY } from '@dstv-web-leanback/dstv-frontend-services'
import { useIntersectionObserver } from '@dstv-web-leanback/dstv-frontend-utils'

import { getCurrentEpgDuration } from '../../PlayerControls/Modes/helper'
import Image from '../../../core/Image/LazyImage'

import playicon from '../../../../assets/images/play.png'
import thumbFallback from '../../../../assets/images/thumb_fallback.png'

import styles from './CardIplate.module.scss'

const getProgressBarPercentage = (bookmarkedTimeInSeconds, totalDurationInSeconds) => {
	return !!bookmarkedTimeInSeconds && !!totalDurationInSeconds
		? Math.ceil((bookmarkedTimeInSeconds / totalDurationInSeconds) * 100)
		: 0
}

export function CardIplate({ item, itemFocus, onSelect }) {
	const bookmarksState = useSelector((state) => state[BOOKMARKS_FEATURE_KEY])
	const bookmark = bookmarksState?.bookmarks?.find((o) => o.genref === item.genRef)

	const bookmarkPercentage = useMemo(() => {
		const eventDetail = getCurrentEpgDuration(item)

		if (eventDetail) {
			return getProgressBarPercentage(eventDetail.progress, eventDetail.duration)
		} else return getProgressBarPercentage(bookmark?.timeInSeconds, item?.durationInSeconds)
	}, [bookmark?.timeInSeconds, item.durationInSeconds, item.start_date_time, item.end_date_time])

	const { ref, focused } = useFocusable({
		focusKey: `CARD_${item.id}`,
		onFocus: () => {
			itemFocus?.(ref, item)
		},
		onEnterPress: () => {
			onSelect?.(item, bookmarkPercentage >= 93)
		},
	})

	const isIntersecting = useIntersectionObserver(ref)

	const displayImage = useMemo(() => {
		let image = item.images?.['play-image']?.MEDIUM

		if (!image) {
			image = item.image
		}

		return image
	}, [item.images])

	return (
		<div ref={ref} className={styles.card_iplate_container}>
			{item.startTime && item.endTime ? (
				<div className={`${styles.times} ${focused || item.selected ? styles.tagged : ''}`}>
					{item.startTime} - {item.endTime}
				</div>
			) : undefined}
			<div className={`${styles.card_iplate} ${focused || item.selected ? styles.active : ''}`}>
				<div className={styles.card_image_container}>
					{isIntersecting ? (
						<Image
							className={styles.card_image}
							alt={item.displayTitle}
							src={displayImage}
							loading="lazy"
							fallback={thumbFallback}
						/>
					) : (
						''
					)}

					{item.tag ? (
						<span className={styles.show_tag}>{item.tag}</span>
					) : !item.isFutureEvent ? (
						<img className={styles.play_icon} src={playicon} alt="Play" />
					) : null}
				</div>

				<div className={styles.card_details}>
					<h1 className={styles.card_details__title}>{item.displayTitle}</h1>
					<span className={styles.card_details__subtitle}>{item.displayItemDetailedTitle}</span>

					<div className={styles.metadata}>
						<div className={styles.rating}>
							{item.restrictionRating?.ageRestrictedToAge || item.rating}&nbsp;{item.ratingAdvisory?.replace(/,/g, '')}
						</div>
						<div className={styles.genres}>{item.genres?.join(' | ')}</div>
					</div>

					<div className={styles.synopsis}>
						<p>{item.synopsis || item.shortSynopsis || item.longSynopsis}</p>
					</div>
					{bookmarkPercentage > 0 ? (
						<div className={styles.progress_wrapper}>
							<ProgressBar progress={bookmarkPercentage} />
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	)
}
export default CardIplate
