import { useEffect, useRef, useState } from 'react'

import styles from './ChannelBlock.module.scss'

export function ChannelBlock({ channel, getDayEvents, active }) {
	const { logo, tag, uuid, number } = channel

	const channelLogo = useRef()

	useEffect(() => {
		if (!channel?.epgCalled) {
			let io = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting ?? entry.intersectionRatio > 0) {
						getDayEvents(tag, uuid)
					}
				})
			})

			if (channelLogo.current) {
				io.observe(channelLogo.current)
			}

			return () => {
				if (channelLogo.current) {
					io.unobserve(channelLogo.current)
				}
			}
		}
	}, [channel.uuid, channel.epgCalled])

	return (
		<div ref={channelLogo} className={`${styles.channel_item} ${active ? styles.active : ''}`}>
			<img src={logo} />
			<h5>{number}</h5>
		</div>
	)
}
export default ChannelBlock
