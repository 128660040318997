import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FocusContext, useFocusable, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'

import { AvatarList, Loader, ProfileName } from '@dstv-web-leanback/dstv-frontend-components'
import {
	AVATARS_FEATURE_KEY,
	CLEAR_AVATARS,
	EDIT_PROFILE,
	GET_AVATARS,
	PROFILE_FEATURE_KEY,
	DELETE_PROFILE_FLAGS,
	GET_PROFILES_LIST,
	SET_NEW_PROFILE_DETAILS,
	CLEAR_NEW_PROFILE_DETAILS,
	CLEAR_KEYBOARD,
} from '@dstv-web-leanback/dstv-frontend-services'

import styles from './ProfileEdit.module.scss'

import { DialogButtonGroup } from '@dstv-web-leanback/dstv-frontend-components'
import dstvLogo from '../../../../assets/images/dstv_logo_fallback.png'

export function ProfileEdit(props) {
	const { focusKey, ref } = useFocusable({
		focusable: true,
		isFocusBoundary: true,
		onBackPress: () => {
			navigate('/profiles')
		},
	})

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { id } = useParams()

	const avatars = useSelector((state) => state[AVATARS_FEATURE_KEY].data?.items)

	const avatarsLoading = useSelector((state) => state[AVATARS_FEATURE_KEY].loading)

	const profiles = useSelector((state) => state[PROFILE_FEATURE_KEY].data?.items)

	const profileCrud = useSelector((state) => state[PROFILE_FEATURE_KEY].profileCrud)
	const loading = useSelector((state) => state[PROFILE_FEATURE_KEY].loading)
	const newProfile = useSelector((state) => state[PROFILE_FEATURE_KEY].newProfile)
	const selectedProfile = profiles?.find((item) => item.id === id)

	const [selectedAvatarId, setSelectedAvatarId] = useState(selectedProfile?.avatar?.id)
	const [titleKey, setTitleKey] = useState('avatar')

	const title = {
		avatar: 'Choose your avatar',
		name: 'Edit your name',
		save: 'Save your profile',
		delete: 'Delete your profile',
	}

	useEffect(() => {
		dispatch(GET_AVATARS())
		return () => {
			dispatch(CLEAR_AVATARS())
		}
	}, [dispatch])

	useEffect(() => {
		if (profileCrud) {
			dispatch(CLEAR_NEW_PROFILE_DETAILS())
			dispatch(CLEAR_KEYBOARD())
			dispatch(DELETE_PROFILE_FLAGS())
			dispatch(GET_PROFILES_LIST())
			navigate('/profiles')
		}
	}, [profileCrud, navigate, dispatch])

	const avatarList = useMemo(() => !!avatars && [selectedProfile?.avatar, ...avatars], [avatars, selectedProfile])

	const actionButtons = [
		{
			focusKeyParam: 'save',
			title: 'Save',
			onItemFocus: () => {
				setTitleKey('save')
				dispatch(DELETE_PROFILE_FLAGS())
			},
			action: (payload) => {
				dispatch(
					EDIT_PROFILE({
						alias: newProfile?.alias || selectedProfile?.alias,
						avatarId: newProfile?.avatarId || selectedAvatarId,
						profileId: id,
					})
				)
			},
		},
		{
			focusKeyParam: 'delete',
			title: 'Delete Profile',
			onItemFocus: () => {
				setTitleKey('delete')
			},
			action: (payload) => {
				navigate('/profiles/delete/' + id)
			},
		},
	]

	if (!selectedProfile?.canDelete) {
		actionButtons.pop()
	}

	const showEditPage = useMemo(
		() => !avatarsLoading && avatarList && selectedProfile?.alias,
		[avatarsLoading, avatarList, selectedProfile?.alias]
	)

	return !showEditPage ? (
		<Loader />
	) : (
		<div className={styles.component}>
			<img className={styles.dstv_logo} alt="DStv logo" src={dstvLogo} />
			<div className={styles.title}>{title[titleKey]}</div>
			<FocusContext.Provider value={focusKey}>
				<div ref={ref}>
					<AvatarList
						setTitleKey={setTitleKey}
						avatars={avatarList}
						setSelectedAvatarId={setSelectedAvatarId}
						onSelectItem={(avatarId, avatarUri) => {
							setSelectedAvatarId(avatarId)
							dispatch(SET_NEW_PROFILE_DETAILS({ newProfile: { avatarId, avatarUri } }))
						}}
					/>
					<ProfileName id={id} setTitleKey={setTitleKey} profileName={newProfile?.alias || selectedProfile?.alias} />
					<DialogButtonGroup actionButtons={actionButtons} orientation="vertical" />
				</div>
			</FocusContext.Provider>
		</div>
	)
}
export default ProfileEdit
