import {
	CONNECT_ID,
	AUTH_KEY,
	SESSION_ID,
	TOKEN_ID,
	USER_ID,
	FALLBACK_PROFILE_ID,
	ACTIVE_PROFILE_ID,
} from './constants'

import { SESSION_REFRESH } from '../slices/session/session.slice'

import { getProfileId, fetchFromLocalStorage, saveToLocalStorage } from './storageHelper'

export const getDefaultHeaders = () => {
	return {
		'Content-Type': 'application/json',
	}
}

export const getAuthorizationHeaders = () => {
	return {
		Authorization: fetchFromLocalStorage(SESSION_ID),
		...getDefaultHeaders(),
	}
}

export const getNoCacheHeaders = () => {
	return {
		Authorization: fetchFromLocalStorage(SESSION_ID),
		'Cache-Control': 'no-cache',
		...getDefaultHeaders(),
	}
}

export const getHeaders = () => {
	const activeProfileId = getProfileId()
	if (!activeProfileId || activeProfileId === FALLBACK_PROFILE_ID) {
		return getAuthorizationHeaders()
	} else {
		return {
			...getAuthorizationHeaders(),
			[ACTIVE_PROFILE_ID]: activeProfileId,
		}
	}
}

export const setTokenAndJWT = (response) => {
	saveToLocalStorage(SESSION_ID, response.accessToken)
	if (response.trackingId) saveToLocalStorage(USER_ID, response.trackingId)
	saveToLocalStorage(TOKEN_ID, response.idToken)
	saveToLocalStorage(CONNECT_ID, response.connect_id)
	saveToLocalStorage(AUTH_KEY, JSON.stringify(response))
}

export const handleUnauthorized = (response, action, retryAction, refreshPage = false) => {
	if (response.status === 429) {
		const { protocol, host, pathname } = window.location
		const url = `${protocol}//${host}${pathname}`
		host && window.open(url, '_self')
	} else {
		if (response.status === 401 || response?.response?.errorCode === 1200) {
			return {
				type: SESSION_REFRESH.type,
				payload: retryAction,
			}
		} else {
			const retryCount = +retryAction.retryCount
			const count = +retryAction.count || 0
			if (count < retryCount && response.status !== 403) {
				return { ...retryAction, count: count + 1, retryCount }
			} else {
				return action
			}
		}
	}
}
export const fetchJWT = () => {
	return fetchFromLocalStorage(TOKEN_ID)
}
