import { useRef, useState } from 'react'
import { FocusContext, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { Chip } from '../../core/Chip/Chip'
import styles from './ChipList.module.scss'

export function ChipList({
	chips,
	selectedChip,
	onSelectItem,
	onItemFocus,
	className = null,
	handleArrow,
	selectedStyles,
}) {
	const [chipKey, setChipKey] = useState(null)
	const { focusKey, ref } = useFocusable({
		trackChildren: true,
		preferredChildFocusKey: chipKey,
		focusKey: 'ChipList',
	})

	const onLoad = useRef(true)
	const [chipScroll, setChipScroll] = useState(0)

	const changeChipListFocus = (focusedRef, item) => {
		setTimeout(() => {
			if (focusedRef.current) {
				setChipScroll(focusedRef?.current?.offsetLeft - Number.parseInt(getComputedStyle(ref?.current)?.paddingLeft))
				onItemFocus?.(item)
			}
		}, 1)
	}

	const onLoadScroll = (focusedRef, currentChipKey) => {
		if (onLoad.current) {
			changeChipListFocus(focusedRef)
			setChipKey(currentChipKey)
			onLoad.current = false
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<section
				ref={ref}
				className={`${styles.chip_list} ${className}`}
				style={{ transform: `translate3d(-${chipScroll}px,0,0)` }}
			>
				{chips.map((chip, index) => {
					return (
						<Chip
							id={chip.title}
							key={index}
							onSelect={() => onSelectItem?.(chip)}
							selected={chip.title === selectedChip}
							title={chip.title}
							onItemFocus={(focusedRef) => changeChipListFocus(focusedRef, chip)}
							handleArrow={handleArrow}
							selectedStyles={selectedStyles}
							onLoadScroll={onLoadScroll}
						/>
					)
				})}
			</section>
		</FocusContext.Provider>
	)
}
export default ChipList
