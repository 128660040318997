// External dependency imports
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as ReactDOM from 'react-dom/client'
import reportWebVitals from './setupWebVitals'
// Internal dependency imports
import App from './app/app'
import store from './store'

import { segmentAnalytics } from '@dstv-web-leanback/dstv-frontend-services'

window.isSmartTv = true
const root = ReactDOM.createRoot(document.getElementById('root'))

const Root = () => {
	return (
		<Provider store={store}>
			<HashRouter>
				<App />
			</HashRouter>
		</Provider>
	)
}

root.render(<Root />)

const sendWebVitalSegment = ({ name, ...entry }) => {
	let ident = ['webmetrics', name].join('->')
	segmentAnalytics.track(ident, { name, ...entry })
}

reportWebVitals(sendWebVitalSegment)
