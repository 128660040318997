import { useRef } from 'react'

import { ShakaPlayer } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './TrailerPlayer.module.scss'

export function TrailerPlayer({ url, videoEnd, onPlay }) {
	const shakaPlayer = useRef(null)
	let playerUrl = `${url}/.mpd`

	const onVideoEnded = () => {
		videoEnd(true)
	}
	const onError = () => {
		onVideoEnded()
	}

	return (
		<ShakaPlayer
			ref={shakaPlayer}
			url={playerUrl}
			onEnded={onVideoEnded}
			onError={onError}
			onLoaded={onPlay}
			className={styles.trailer_player}
		/>
	)
}
export default TrailerPlayer
