import { from } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import FetchWorker from './fetch.worker.js'
import { isObject, isString } from './isHelper'
import { getBy } from './objHelper'

class FakeWorker {
	constructor() {
		this.isNotWorker = true
	}
	static get isFake() {
		return true
	}
	onmessage = (v) => v
	postMessage = (v) => v
}

const getResponse = getBy('response')
const getStatus = getBy('status', getBy('xhr.status', 0))
const getResponseType = getBy('response_type')
/**
 * Create a Universal onMessage handler for FetchWorker
 * @param {*} worker - Instance of FetchWorker
 * @param {*} successCb - can be a Promise.resolve or Subscriber.next
 * @param {*} errorCb - can be a Promise.reject or Subscriber.error
 * @returns
 */
const createResponseErrorHandler =
	(worker, successCb, errorCb, timeout = 1) =>
	({ data }) => {
		let response = getResponse(data)
		let response_type = getResponseType(data)
		let status = getStatus(data)
		if (data !== undefined && data !== null) {
			switch (response_type) {
				case 'AUTH_ERROR': {
					errorCb(data)
					break
				}
				case 'ERROR': {
					response.status = status
					errorCb(response)
					break
				}
				case 'SUCCESS': {
					successCb(response)
					break
				}
				case 'COMPLETED': {
					setTimeout(() => worker.terminate(), timeout)
					break
				}
				default:
					return
			}
		}
	}

/**
 *
 * @param {*} url
 * @param {*} init
 * @returns
 */
const promiseWorker = (url, init) => {
	let worker
	if (!isFetchWorkerLoaded()) {
		worker = new FakeWorker()
	} else {
		worker = new FetchWorker()
	}

	const promise = new Promise((resolve, reject) => {
		worker.onmessage = createResponseErrorHandler(worker, resolve, reject, 1)
	})
	worker.postMessage({ url, init })
	return promise
}

/**
 *
 * @param {*} url
 * @param {*} init
 * @returns
 */
const createWorkerPromiseStream = (url, init) => from(promiseWorker(url, init))

/**
 * Attempt to seriazlize body for fetch request
 *
 * @param {*} body
 * @returns
 */
const convertBody = (body) => {
	if (body === undefined || body === null) {
		return body
	}
	if (body instanceof FormData || typeof body === 'string') {
		return body
	}
	return JSON.stringify(body)
}
const isWebworkersSupported = typeof Worker !== 'undefined'
const isFetchWorkerLoaded = () => FetchWorker?.name?.length > 0

/**
 * Wrapper to createWorkerPromiseStream which defaults to GET method and doesn't expect a body to create Mutating Queries
 * @param {*} method
 * @returns
 */
const createWorkerforMutations =
	(method = 'POST') =>
	(url, body = {}, headers = {}) =>
		createWorkerPromiseStream(url, {
			headers,
			method,
			body: convertBody(body),
		})

/**
 * Create the Drop In Replacement fro RX.ajax using Webworkers
 */
const createFromPromiseWorkerOrGracefullyFailBack = () => {
	let isWorkerAvailable = isFetchWorkerLoaded()
	if (!isWebworkersSupported || !isWorkerAvailable || /Chrome\/53\./.test(window.navigator.userAgent)) {
		return ajax
	}
	let actor$ = (...args) => {
		if (args.length === 1) {
			if (isObject(args[0])) {
				let options = { method: 'GET', ...args[0] }
				if ('url' in options) {
					let { url, ...restOptions } = options
					if ('body' in restOptions) {
						restOptions.body = convertBody(restOptions.body)
					}
					return createWorkerPromiseStream(url, restOptions)
				}
			} else {
				if (isString(args[0])) {
					return createWorkerPromiseStream(args[0], {
						method: 'GET',
					})
				}
			}
		}
	}
	actor$.getJSON = (url, headers = {}) => createWorkerPromiseStream(url, { headers, method: 'GET' })
	const mutationStreams = {
		post: createWorkerforMutations('POST'),
		put: createWorkerforMutations('PUT'),
		patch: createWorkerforMutations('PATCH'),
		delete: createWorkerforMutations('DELETE'),
	}
	for (let methodName in mutationStreams) {
		actor$[methodName] = mutationStreams[methodName]
	}
	return actor$
}

/**
 * Export the Drop In Replacement fro RX.ajax using Webworkers
 */
export const fromPromiseWorker = createFromPromiseWorkerOrGracefullyFailBack()
