import { Route, Routes, useLocation } from 'react-router-dom'
import { init, setKeyMap } from '@dstv-web-leanback/norigin-spatial-navigation'
import React, { Suspense, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router } from './components'
import {
	ADD_NAV_LOCATION,
	GET_MENU,
	GET_REMOTE_CONFIGS,
	GET_USER_INFO,
	getDeviceID,
	getSessionID,
	getUserID,
	PROFILE_FEATURE_KEY,
	segmentAnalytics,
	useNavigation,
	configKeyEnabled,
	USER_FEATURE_KEY,
	SESSION_FEATURE_KEY,
} from '@dstv-web-leanback/dstv-frontend-services'
import { Loader, BootupAnimation, DialogFull } from '@dstv-web-leanback/dstv-frontend-components'
import { UserProfiles } from './pages/UserProfiles/UserProfiles'
import { Player } from './pages/Player/Player'
import { reloadAppDialog } from './utils/content'
import { useDataDog } from './utils/hooks/useDataDog'
import { ErrorBoundary } from 'react-error-boundary'
import PackageUpdate from './pages/PackageUpdate/PackageUpdate'

const LoginOtp = React.lazy(() => import('./pages/LoginOtp/LoginOtp'))
const ShowPage = React.lazy(() => import('./pages/ShowPage/ShowPage'))
const Settings = React.lazy(() => import('./pages/Settings/Settings'))
const Content = React.lazy(() => import('./pages/Content/Content'))

export function App() {
	init({ throttle: 150, throttleKeypresses: true })
	setKeyMap({ back: [10009, 196, 461, 8] })

	const dispatch = useDispatch()
	const { navigate } = useNavigation()
	const location = useLocation()
	const [showBootupAnimation, setShowBootupAnimation] = useState(true)
	const profilesState = useSelector((state) => state[PROFILE_FEATURE_KEY])
	const userState = useSelector((state) => state[USER_FEATURE_KEY])
	const configState = useSelector((state) => state.config)
	const reCalculateVas = configKeyEnabled(configState, 'recalculate_vas') ?? true

	const activeProfile = useMemo(
		() => profilesState?.data?.items?.find((profile) => profile.active),
		[profilesState?.data?.items]
	)

	useEffect(() => {
		// First time launch coming from the old app
		if (localStorage.getItem('DEVICE_ID_STORAGE_KEY')) {
			localStorage.clear()
			sessionStorage.clear()
		}

		if (!activeProfile && getSessionID()) {
			segmentAnalytics.identify({ userId: getUserID() })
			dispatch(GET_USER_INFO({}))
			dispatch(GET_REMOTE_CONFIGS({}))
			navigate('/profiles')
		}
	}, [])

	const setUserSessionID = () => {
		const sessionId = getSessionID()

		if (sessionId) {
			segmentAnalytics.identify({ userId: getUserID() })
			dispatch(GET_USER_INFO({}))
			dispatch(GET_REMOTE_CONFIGS({}))
			navigate('/profiles')
		}
	}

	useEffect(() => {
		if (userState.userPackage) {
			dispatch(GET_MENU({}))
		}
	}, [userState.userPackage])

	useEffect(() => {
		dispatch(ADD_NAV_LOCATION(location.pathname))
	}, [location.pathname])

	useDataDog()

	let appContent = showBootupAnimation && <BootupAnimation onComplete={() => setShowBootupAnimation(false)} />

	if (!getSessionID() || !getDeviceID()) {
		appContent = (
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route path="*" key="LoginOtp" element={<LoginOtp setSessionID={setUserSessionID} />} />
				</Routes>
			</Suspense>
		)
	} else if (userState.isRecalculate && activeProfile && !userState.ignorePackageUpdate && reCalculateVas) {
		appContent = <PackageUpdate />
	} else {
		appContent = (
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route key="UserProfiles" path="/profiles/*" element={<UserProfiles />} />
					<Route key="Player" path="/play/*" element={<Player />} />
					<Route key="ShowPage" path="/showpage/:videoId/:type" element={<ShowPage />} />
					<Route path="/settings/*" element={<Settings />} />
					<Route key="AppRouter" path="*" element={<Router activeProfile={activeProfile} />} />
					<Route key="Content" path={'/contentpage/:id'} element={<Content />} />
				</Routes>
			</Suspense>
		)
	}

	return (
		<ErrorBoundary fallback={<DialogFull error={reloadAppDialog} customAction={() => navigate(0)} />}>
			{appContent}
		</ErrorBoundary>
	)
}

export default App
