import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

import styles from './KeyboardKey.module.scss'

export function KeyboardKey({ label, selectKey, index }) {
	const { ref, focused } = useFocusable({
		onEnterPress: () => {
			selectKey(label)
		},
		focusKey: `KEYBOARD_KEY_${index}_${label}`,
	})

	return (
		<div ref={ref} className={`${styles.keyboard_key} ${focused ? styles.active : ''}`}>
			{label}
		</div>
	)
}
export default KeyboardKey
