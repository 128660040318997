import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createEpicMiddleware, combineEpics } from 'redux-observable'

import {
	adPlayerEpic,
	authEpic,
	autoplayEpic,
	avatarsEpic,
	billboardEpic,
	bookmarksEpic,
	catchupEpic,
	cdnAuthEpic,
	channelsEpic,
	configEpic,
	devicesEpic,
	entitlementEpic,
	eventsEpic,
	episodesEpic,
	homeEpic,
	menuEpic,
	playerEpic,
	profileEpic,
	proximityEpic,
	searchEpic,
	sessionEpic,
	showpageEpic,
	termsEpic,
	userEpic,
	watchlistEpic,
	calculateEpic,
} from '@dstv-web-leanback/dstv-frontend-services'

const rootEpic = combineEpics(
	authEpic,
	autoplayEpic,
	avatarsEpic,
	billboardEpic,
	catchupEpic,
	cdnAuthEpic,
	channelsEpic,
	configEpic,
	devicesEpic,
	entitlementEpic,
	episodesEpic,
	homeEpic,
	proximityEpic,
	menuEpic,
	playerEpic,
	adPlayerEpic,
	profileEpic,
	searchEpic,
	sessionEpic,
	showpageEpic,
	termsEpic,
	userEpic,
	watchlistEpic,
	bookmarksEpic,
	eventsEpic,
	calculateEpic
)

const listenerInstance = createListenerMiddleware({
	extra: { worker: null, serviceWoker: null },
	onError: (error, errorInfo) => {
		console.error(error, errorInfo)
		reportError(error)
	},
})

const epicMiddleware = createEpicMiddleware()

const middlewareConfig = {
	thunk: true,
	immutableCheck: false,
	serializableCheck: false,
}

export { epicMiddleware, listenerInstance, middlewareConfig, rootEpic }
