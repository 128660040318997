import { useEffect, useState } from 'react'

import styles from './CardBillboard.module.scss'
import { Billboard, Carousel } from '@dstv-web-leanback/dstv-frontend-components'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useSelector } from 'react-redux'

export function CardBillboard({
	cards,
	index,
	item,
	itemFocus,
	carouselIndex,
	getCardDetails,
	rowId,
	rowTitle,
	onSelect,
	features,
}) {
	const playbackEnabled = useSelector((state) => state.menu.hidden)
	const billboardState = useSelector((state) => state.billboard)
	const [billboardInfo, setBillboardInfo] = useState({
		...item,
		poster: item.images[2].href,
	})

	const { focusKey, ref, focused } = useFocusable({
		onFocus: () => {
			if (ref) itemFocus?.(ref, item, index)
			if (getCardDetails && !item?.fetched_info) {
				getCardDetails(item, rowId, index)
			}
		},
		onEnterRelease: () => {
			onSelect({
				...item,
				carouselIndex,
				rowTitle,
				features,
				rowId,
				comingUp: billboardInfo?.isComingUp,
				index,
			})
		},
		focusKey: `CARD_${rowId ? rowId : ''}${index}`,
	})

	useEffect(() => {
		if (focused) {
			setBillboardInfo(billboardState.info)
		}
	}, [focused, billboardState.info])

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={styles.cardbillboard_container} ref={ref}>
				<div className={`${styles.cardbillboard} ${focused ? styles.active : ''}`}>
					<Billboard
						detailsVisible={true}
						card={true}
						playbackEnabled={playbackEnabled && focused}
						billboardInfo={billboardInfo}
						position={'center'}
						showButtonLabel={true}
						showMetadata={true}
					/>
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default CardBillboard
