import { useState, useEffect, useRef } from 'react'
import { useFocusable, FocusContext, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useDispatch } from 'react-redux'

import {
	PLAY,
	SHOW_SEARCH_TOAST,
	useNavigation,
	getEpisodeTitle,
	NAV_PUSH,
	GET_CURRENT_EVENT_BY_ID,
	GET_PLAYER_DETAILS,
	SET_CONTENT_DISCOVERY_REFERRER,
} from '@dstv-web-leanback/dstv-frontend-services'

import { Card16x9, CardVOD16x9, Carousel } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './SearchContainer.module.scss'
import { dateUtils, channelLive } from '@dstv-web-leanback/dstv-frontend-utils'

export function SearchContainer({ searchContents, searchedItems, handleSetToast }) {
	const dispatch = useDispatch()
	const containerRef = useRef(null)
	const { location, navigate } = useNavigation()
	const [itemScroll, setItemScroll] = useState(0)
	const [searchFound, setSearchFound] = useState(false)

	const { focusKey, ref } = useFocusable({})

	const changeItemFocus = (focusedRef) => {
		const containerStyles = getComputedStyle(containerRef.current)
		const offsetTop = focusedRef.current.offsetTop
		const paddingTop = Math.round(Number.parseFloat(containerStyles.paddingTop))
		setItemScroll(offsetTop - paddingTop)
	}

	const onCarouselFocus = (focusedRef) => {
		let gridStyles = getComputedStyle(ref.current)
		setItemScroll(focusedRef.offsetTop - Number.parseInt(gridStyles.paddingTop))
	}

	const dispatchPlayerVOD = (item) => {
		dispatch(
			GET_PLAYER_DETAILS({
				videoId: item.id,
				resumeVideoId: item.resume_video_id ?? item.id,
				cardId: item.id,
				rowId: item.rowId,
				type: item.type,
			})
		)
		item?.resume_state && dispatch(SET_CONTENT_DISCOVERY_REFERRER({ referrer: 'Continue Watching' }))
		navigate('/play')
	}

	const handleNavPush = () => {
		dispatch(
			NAV_PUSH({
				key: getCurrentFocusKey(),
				location: location.pathname,
			})
		)
	}

	const onSelectLiveCard = (item) => {
		handleSetToast(false)
		const [isLive, labelId] = channelLive(item)
		if (['coming_up', 'expired'].includes(labelId)) {
			const dateText = dateUtils.formatDate(item?.start_date_time)
			const onText = ['Today', 'Tomorrow', 'Yesterday', ''].includes(dateText) ? '' : 'on '
			const textMapping = {
				coming_up: 'Coming up ',
				expired: 'Expired ',
			}
			const toastPayload = {
				id: 'comingUpToast',
				message: `${textMapping[labelId] + onText + dateText}`,
			}
			dispatch(SHOW_SEARCH_TOAST(toastPayload))
			handleSetToast(true)
		} else if (isLive) {
			let streamUrl = item?.links?.filter((link) => link?.rel?.[0] === 'stream')[0]?.href
			if (streamUrl) {
				const episodeTitle = getEpisodeTitle(item)
				if (item.type === 'event') {
					dispatch(GET_CURRENT_EVENT_BY_ID({ eventId: item.id }))
				}
				dispatch(
					PLAY({
						url: streamUrl,
						details: {
							...item,
							episode_title: episodeTitle,
							channelTag: item.tag || item.channel_tag,
							channelNumber: item.number || item.channel_number,
							rowTitle: item.rowTitle,
						},
						type: 'live',
					})
				)
				handleNavPush()
				navigate('/play', { state: { from: location.pathname.replace('/', '') } })
			}
		} else {
			handleNavPush()
			dispatchPlayerVOD(item)
		}
	}

	useEffect(() => {
		if (!searchedItems?.length) {
			if (searchFound) {
				setItemScroll(0)
			}
			setSearchFound(false)
		} else {
			setItemScroll(0)
			setSearchFound(true)
		}
	}, [searchedItems?.length])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.grid} style={{ transform: `translate3d(0, -${itemScroll}px, 0)` }}>
				{searchedItems?.length ? (
					<div ref={containerRef} className={styles.results_grid}>
						{searchedItems?.map((item, index) => {
							return (
								<div className={styles.grid_item} key={item.id}>
									{item.type === 'event' && (
										<Card16x9
											index={index}
											className={styles.search_card}
											rowTitle={item.title}
											item={item}
											itemFocus={changeItemFocus}
											key={index}
											rowId={`${index}`}
											onSelect={onSelectLiveCard}
											type={item.type}
										/>
									)}
									{item.type !== 'event' && (
										<CardVOD16x9
											index={index}
											className={styles.search_card}
											rowTitle={item.title}
											item={item}
											itemFocus={changeItemFocus}
											key={index}
											rowId={`${index}`}
											showTitle={true}
										/>
									)}
								</div>
							)
						})}
					</div>
				) : null}
				{searchContents?.length && !searchedItems?.length ? (
					<div>
						{searchContents?.map((row, index) => {
							if (row.items?.length) {
								return (
									<Carousel
										key={row.id}
										cards={row.items}
										title={row.title}
										type={row.type}
										id={row.id}
										carouselIndex={index}
										saveLastFocusedChild={true}
										onCarouselFocus={onCarouselFocus}
										onSelect={row.type === 'channels' || row.type === 'vod_with_progress' ? onSelectLiveCard : null}
										features={row.features}
										showTitle={true}
									/>
								)
							} else {
								return null
							}
						})}
					</div>
				) : null}
			</div>
		</FocusContext.Provider>
	)
}

export default SearchContainer
