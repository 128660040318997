import { useEffect } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'

import { Button } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './ButtonGroup.module.scss'

export function ButtonGroup({ buttons, setActive }) {
	const { ref, focusKey, hasFocusedChild } = useFocusable({
		trackChildren: true,
	})

	useEffect(() => {
		setActive && setActive(hasFocusedChild)
	}, [hasFocusedChild])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.button_group}>
				{buttons.map((button, index) => {
					return (
						<Button
							selected={button.selected}
							icon={button.selected && button.icon}
							id={button.title}
							key={index}
							onSelect={button.action}
							title={button.title}
						/>
					)
				})}
			</div>
		</FocusContext.Provider>
	)
}
export default ButtonGroup
