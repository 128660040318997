import { FocusContext, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

import { useNavigate } from 'react-router-dom'
import styles from './ProfileName.module.scss'
export function ProfileName({ id, profileName, setTitleKey, from }) {
	const navigate = useNavigate()
	const { ref, focused, focusKey } = useFocusable({
		focusable: true,
		onFocus: () => {
			setTitleKey('name')
		},
		onBackPress: () => {
			if (from === 'save_page') {
				navigate(`/profiles/save/${id}`)
			}
		},
		onEnterPress: () => {
			if (id === 'new' && from === 'save_page') {
				navigate(`/profiles/save/edit/name/${id}`)
			} else {
				navigate(`/profiles/edit/name/${id}`)
			}
		},
		focusKey: `USER_NAME_${profileName?.toUpperCase()}`,
	})

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} key={id} className={`${styles.profile_name} ${focused ? styles.focus : ''}`}>
				{profileName}
			</div>
		</FocusContext.Provider>
	)
}
export default ProfileName
