import { useEffect, useState } from 'react'

/**
 *
 * @param {*} ref
 * @param {IntersectionObserverInit} options
 * @returns
 */
export const useIntersectionObserver = (ref, options) => {
	const [isIntersecting, setIsIntersecting] = useState(false)

	useEffect(() => {
		if (!ref) return

		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				setIsIntersecting(entry.isIntersecting ?? entry.intersectionRatio > 0)
			})
		}, options)

		observer.observe(ref.current)

		return () => {
			observer.disconnect()
		}
	}, [ref])

	return isIntersecting
}

export default useIntersectionObserver
