import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { HTTP, errorResponse, successResponse } from '../../utils/httpHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'
import { getShowPageBillboardData, getWatchlistDetails } from '../billboard/billboardTransformHelper'
import { secureLocalStorage } from '../../utils/secureStorage'

export const SHOWPAGE_FEATURE_KEY = 'showpage'

export const showpageSlice = createSlice({
	name: SHOWPAGE_FEATURE_KEY,
	initialState: { billboardVisible: true },
	reducers: {
		CLEAR_SHOWPAGE: (state) => {
			state.editorials = null
			state.data = null
			state.videoId = null
			state.watchButton = null
			state.watchlistDetails = null
			delete state.error
		},
		EDITORIALS: (state, action) => {
			delete state.error
			state.editorials = null
		},
		EDITORIALS_SUCCESS: (state, action) => {
			state.editorials = action.payload
			state.editorials?.map((item) => {
				item?.editorialItems?.map(
					(editorialItem) => (editorialItem.poster_image = getCardPosterImageURL(editorialItem))
				)
			})
			state.date = new Date().getTime()
		},
		EDITORIALS_ERROR: (state, action) => {
			state.editorials = null
		},
		UPDATE_WATCH_BUTTON: (state) => {
			state.watchButton = {
				loading: true,
			}
		},
		UPDATE_WATCH_BUTTON_SUCCESS: (state, action) => {
			state.watchButton = {
				...action.payload,
				loading: false,
			}
		},
		UPDATE_WATCH_BUTTON_ERROR: (state, action) => {
			state.watchButton = { resume_state: 'watch' }
			state.watchButton.loading = false
		},
		CLEAR_WATCH_BUTTON: (state) => {
			state.watchButton = null
		},
		TOGGLE_WATCHLIST: (state, action) => {
			state.watchlistDetails = getWatchlistDetails(action.payload.data, true)
			state.watchlistDetails.loading = true
		},
		TOGGLE_WATCHLIST_SUCCESS: (state, action) => {
			const watchlistDetails = getWatchlistDetails(action.payload.data, false)

			state.watchlistDetails = {
				...watchlistDetails,
				showToast: true,
				toastMessage: watchlistDetails.title,
				loading: false,
			}
		},
		TOGGLE_WATCHLIST_ERROR: (state, action) => {
			const watchlistDetails = getWatchlistDetails(action.payload.data, false)
			state.watchlistDetails.loading = false
			state.watchlistDetails = {
				...watchlistDetails,
				showToast: true,
				toastMessage: watchlistDetails.title,
				loading: false,
			}
		},
		GET_WATCH_LIST_INFO: (state, action) => {
			state.watchlistDetails = { initialLoading: true }
		},
		GET_WATCH_LIST_INFO_SUCCESS: (state, action) => {
			state.watchlistDetails = getWatchlistDetails(action.payload.data, false)
		},
		GET_WATCH_LIST_INFO_ERROR: (state, action) => {
			state.watchlistDetails = getWatchlistDetails(action.payload, false)
		},
		HIDE_WATCHLIST_TOAST: (state, action) => {
			if (state.watchlistDetails?.showToast) {
				state.watchlistDetails.showToast = false
			}
		},
		GET_SHOW_PAGE_DATA: (state, action) => {
			state.loading = true
			state.videoId = action.payload.videoId
		},
		SHOW_PAGE_SUCCESS: (state, action) => {
			state.data = action.payload.data
			state.date = action.payload.date
		},
		SHOW_PAGE_ERROR: (state, action) => {
			state.loading = false
			state.error = action.payload.error
		},
		GET_STACK_API: (state) => {
			state.loading = true
		},
		STACK_API_SUCCESS: (state, action) => {
			if (action.payload.data?.billboardDetails?.type === 'videos') {
				state.loading = false
			}
			state.data = { ...state.data, ...action.payload.data }
		},
		GET_RELATED_API: (state) => {
			state.loading = true
		},
		RELATED_API_SUCCESS: (state, action) => {
			state.data = { ...state.data, trailerUrl: action.payload.data }
		},
		GET_EPISODE_VIDEOS: (state, action) => {
			if (!state.data.episodeDetails?.length) {
				state.data.episodeDetails = []
			}
			state.data.vodDetails = null
		},
		GET_EPISODE_VIDEOS_SUCCESS: (state, action) => {
			state.loading = false
			if (!state.data?.episodeDetails?.length) {
				state.data.billboardDetails = { ...state.data.billboardDetails, ...action.payload.data.billboardDetails }
				state.data.vodDetails = action.payload.data.vodDetails
			}
			state.data.episodeDetails = [...state.data.episodeDetails, ...action.payload.data.episodeDetails]
		},
		WATCH_BUTTON_CLICKED: (state) => {
			return state
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const showpageReducer = showpageSlice.reducer

export const {
	CLEAR_SHOWPAGE,
	EDITORIALS,
	EDITORIALS_SUCCESS,
	EDITORIALS_ERROR,
	UPDATE_WATCH_BUTTON,
	UPDATE_WATCH_BUTTON_SUCCESS,
	UPDATE_WATCH_BUTTON_ERROR,
	CLEAR_WATCH_BUTTON,
	TOGGLE_WATCHLIST,
	TOGGLE_WATCHLIST_SUCCESS,
	TOGGLE_WATCHLIST_ERROR,
	GET_WATCH_LIST_INFO,
	GET_WATCH_LIST_INFO_SUCCESS,
	GET_WATCH_LIST_INFO_ERROR,
	HIDE_WATCHLIST_TOAST,
	GET_SHOW_PAGE_DATA,
	SHOW_PAGE_SUCCESS,
	SHOW_PAGE_ERROR,
	GET_STACK_API,
	STACK_API_SUCCESS,
	GET_RELATED_API,
	RELATED_API_SUCCESS,
	GET_EPISODE_VIDEOS,
	GET_EPISODE_VIDEOS_SUCCESS,
	WATCH_BUTTON_CLICKED,
} = showpageSlice.actions

/*
 * Set up the redux-observable epic
 */
export const showpageEpic = (action$) =>
	action$.pipe(
		ofType(
			EDITORIALS.type,
			UPDATE_WATCH_BUTTON.type,
			TOGGLE_WATCHLIST.type,
			GET_WATCH_LIST_INFO.type,
			GET_SHOW_PAGE_DATA.type,
			GET_STACK_API.type,
			GET_RELATED_API.type,
			GET_EPISODE_VIDEOS.type
		),
		mergeMap(showpageService(action$))
	)

const showpageService = (action$) => (action) => {
	switch (action.type) {
		case EDITORIALS.type: {
			return HTTP.GET_WITH_CANCEL(
				ENV.BASE_URL + ENV.GET_TRY_THIS.replace('{VIDEO_ID}', action.payload.videoId),
				getHeaders(),
				editorialsSuccess,
				editorialsError(action),
				false,
				action$.pipe(ofType(EDITORIALS.type))
			)
		}
		case UPDATE_WATCH_BUTTON.type: {
			return HTTP.GET(action.payload.url, getHeaders(), updateWatchButtonSuccess, updateWatchButtonError(action), true)
		}

		case TOGGLE_WATCHLIST.type: {
			if (action.payload.operation === 'ADD') {
				return HTTP.PUT(
					action.payload.url,
					action.payload.data,
					getHeaders(),
					toggleWatchListSuccess,
					toggleWatchListError(action),
					true
				)
			} else if (action.payload.operation === 'REMOVE') {
				return HTTP.DELETE(
					action.payload.url,
					action.payload.data,
					getHeaders(),
					toggleWatchListSuccess,
					toggleWatchListError(action),
					true
				)
			}
			break
		}
		case GET_WATCH_LIST_INFO.type: {
			return HTTP.GET_WITH_CANCEL(
				action.payload.url,
				getHeaders(),
				getWatchlistSuccess,
				getWatchlistError(action),
				true,
				action$.pipe(ofType(GET_WATCH_LIST_INFO))
			)
		}
		case GET_SHOW_PAGE_DATA.type: {
			let storedUserInfo = secureLocalStorage.getItem('userInfo')
			let { country = '', userPackage = '' } = storedUserInfo ? storedUserInfo : ''
			let showPageBillboardApi = ENV.GET_STACK_LOOKUP.replace('{TYPE}', action.payload.editorialItemType)
				.replace('{ID}', action.payload.videoId)
				.replace('{COUNTRY_CODE}', country)
				.replace('{PACKAGE}', userPackage)
			return HTTP.GET(
				showPageBillboardApi,
				getHeaders(),
				showBillboardSuccess(action),
				showBillboardError(action),
				true
			)
		}
		case GET_STACK_API.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), stackApiSuccess(action), stackApiError(action), true)
		}
		case GET_RELATED_API.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), relatedApiSuccess(action), relatedApiError(action), true)
		}
		case GET_EPISODE_VIDEOS.type: {
			return HTTP.GET(action.payload.url, getHeaders(), episodeVideoSuccess(action), episodeVideoError(action), true)
		}
	}
}

const editorialsSuccess = (response) => {
	let editorialRows = response?.items?.filter((row) => row?.editorialItems?.length)
	if (editorialRows?.length) {
		return { type: EDITORIALS_SUCCESS.type, payload: editorialRows }
	} else {
		return of({
			type: EDITORIALS_ERROR.type,
		})
	}
}

const editorialsError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: EDITORIALS_ERROR.type,
			},
			action
		)
	)
}

const updateWatchButtonSuccess = (response) => {
	return {
		type: UPDATE_WATCH_BUTTON_SUCCESS.type,
		payload: { ...response },
	}
}

const updateWatchButtonError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: UPDATE_WATCH_BUTTON_ERROR.type,
				payload: { ...response },
			},
			action
		)
	)
}

const toggleWatchListSuccess = (response) => {
	return {
		type: TOGGLE_WATCHLIST_SUCCESS.type,
		payload: { data: { ...(response.response || response) } },
	}
}

const toggleWatchListError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: TOGGLE_WATCHLIST_ERROR.type,
				payload: { data: { ...action.payload.data } },
			},
			action
		)
	)
}

const getWatchlistSuccess = (response) => {
	return {
		type: GET_WATCH_LIST_INFO_SUCCESS.type,
		payload: successResponse({ ...response, initialLoading: false }),
	}
}

const getWatchlistError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_WATCH_LIST_INFO_ERROR.type,
				payload: { initialLoading: false },
			},
			action
		)
	)
}
const showBillboardSuccess = (action) => (response) => {
	if (response?.links?.length) {
		return {
			type: SHOW_PAGE_SUCCESS.type,
			payload: {
				data: { ...response },
				videoId: action.payload.videoId,
				date: new Date().getTime(),
			},
		}
	} else {
		throw new Error()
	}
}

const showBillboardError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: SHOW_PAGE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const stackApiSuccess = (action) => (response) => {
	if (response) {
		const billboardMetaData = getShowPageBillboardData(response, action.payload.type, action.payload.videoId)

		return {
			type: STACK_API_SUCCESS.type,
			payload: {
				...billboardMetaData,
				date: new Date().getTime(),
			},
		}
	} else {
		throw new Error()
	}
}

const stackApiError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: SHOW_PAGE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const relatedApiSuccess = (action) => (response) => {
	const billboardTrailerData = response?.trailers[0].video_assets[0].url

	return {
		type: RELATED_API_SUCCESS.type,
		payload: {
			data: billboardTrailerData || null,
			date: new Date().getTime(),
		},
	}
}

const relatedApiError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: RELATED_API_SUCCESS.type,
				payload: {
					data: null,
					date: new Date().getTime(),
				},
			},
			action
		)
	)
}

const episodeVideoSuccess = (action) => (response) => {
	const billboardMetaData = getShowPageBillboardData(response, action.payload.type, action.payload.videoId)

	return {
		type: GET_EPISODE_VIDEOS_SUCCESS.type,
		payload: {
			...billboardMetaData,
			date: new Date().getTime(),
		},
	}
}

const episodeVideoError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: SHOW_PAGE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
