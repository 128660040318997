export const PULSE_CONSTANTS = Object.freeze({
	PLAYER_TYPE: {
		VOD: 'vod',
		LIVE: 'live',
	},
	MARKERS: {
		PRE_ROLL: 'p',
		MID_ROLL: 'm',
		PAUSE_AD: 'pa',
		POST_ROLL: 'po',
	},
	FOCUSABLE: {
		PLAY_PAUSE: 'PLAY_PAUSE',
		PULSE_SKIP_CONTROLS: 'PULSE_SKIP_CONTROLS',
		PULSE_PAUSE_CONTROLS: 'PULSE_PAUSE_CONTROLS',
	},
	AD_TYPES: {
		PRE_ROLL_LINEAR: 'dai_linear_prerolls',
		PRE_ROLLS: 'dai_vod_prerolls',
		MID_ROLLS: 'dai_vod_midrolls',
		POST_ROLLS: 'dai_vod_postrolls',
		PAUSE_AD: 'dai_pa_vod',
	},
	FEATURES: {
		SSAI_ENABLED: 'ssai_enabled',
		DAI_DEBUG_ENABLED: 'dai_debug_enabled',
	},
	V_PAID: 'VPAID',
	DEFAULT_PAUSE_AD_TIMEOUT: 10000,
	START_AD_TIMEOUT: 3,
	THIRD_PARTY_TIMEOUT: 3,
	TOTAL_PASS_BACK_TIMEOUT: 3,
	MAX_BITRATE: 1105,
	AD_EVENT_TYPES: {
		ON_BEFORE_CONTENT: 'onBeforeContent',
		PLAYBACK_POSITION: 'playbackPosition',
		PAUSE_CONTENT: 'onPause',
		ON_CONTENT_END: 'onContentEnd',
	},
	TEXT: {
		SKIP_BTN: 'Skip Ad',
		CLOSE_BTN: 'Close Ad',
		AD_DURATION: 'Ad - ',
	},
})
