import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper'
import { menuDisplayName } from '../../utils/menuHelper'
import { LOGOUT } from '../auth/auth.slice'
import { secureLocalStorage, secureSessionStorage } from '../../utils/secureStorage'
import { handleUnauthorized } from '../../utils/authHelper'
import { StorageKey } from '../../utils/storageKey'

export const MENU_FEATURE_KEY = 'menu'

/*
 * Create our slice
 */
export const menuSlice = createSlice({
	name: MENU_FEATURE_KEY,
	initialState: { hidden: true },
	reducers: {
		GET_MENU: (state, action) => {
			delete state.data
			delete state.error
			delete state.serverError
			state.loading = true
		},
		MENU_TOGGLE: (state, action) => {
			state.hidden = !action.payload.toggled
		},
		GET_MENU_SUCCESS: (state, action) => {
			state.loading = false
			state.data = action.payload.data
		},
		GET_MENU_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(LOGOUT.type, () => {
			return {}
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const menuReducer = menuSlice.reducer

/*
 * Export actions
 */
export const { GET_MENU, GET_MENU_ERROR, GET_MENU_SUCCESS, MENU_TOGGLE } = menuSlice.actions

/*
 * Set up the redux-observable epic
 */
export const menuEpic = (action$) => action$.pipe(ofType(GET_MENU.type), mergeMap(menuService(action$)))

/*
 * Do API calls
 */
const menuService = (action$) => (action) => {
	switch (action.type) {
		case GET_MENU.type: {
			let storedUserInfo = secureLocalStorage.getItem('userInfo')
			let { country = '', userPackage = '' } = storedUserInfo ? storedUserInfo : ''
			let endpointUrl = ENV.GET_MENU.replace('{COUNTRY_CODE}', country).replace('{PACKAGE}', userPackage)
			return HTTP.GET(endpointUrl, {}, getMenuSuccess(action.payload), getMenuError(action), true)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const getMenuSuccess = () => (response) => {
	let menuArr = []
	let result = []
	const menuItems = [
		'home',
		'livetv',
		'tvshows',
		'movies',
		'sport',
		'kids',
		'channelgroups',
		'mystuff',
		...(localStorage.getItem('PRODUCT_TYPE') === 'STREAMA' ? ['apps'] : []),
		'settings',
	]

	const prepareMenuItems = (item) => {
		if (item.visible !== false) {
			result.push({
				displayName: menuDisplayName(item.id),
				displayType: item.displayType ? item.displayType : item.id === 'home' ? 'Editorial' : '',
				id: item.id || '',
				imageUrl: item.imageUrl || '',
				title: item.title || item.id,
				url: item.endpoint || '',
			})
		}
		return item
	}

	response.forEach((item) => {
		if (menuItems.indexOf(item.id) !== -1 && item.visible) {
			menuArr.push(item)
		}
	})

	menuArr.forEach((item) => {
		prepareMenuItems(item, '', '')
	})

	secureLocalStorage.setItem(StorageKey.SK_NAV_DATA, JSON.stringify(successResponse(result)))
	secureSessionStorage.setItem(StorageKey.SK_NAV_DATA, JSON.stringify(successResponse(result)))

	return { type: GET_MENU_SUCCESS.type, payload: successResponse(result) }
}

const getMenuError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: `menu/GET_MENU_ERROR`,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
