import moment from 'moment'
import { has } from './objHelper'
import { isObject, isString } from './isHelper'

export const formatTimeToHoursAndMinutes = (time) => {
	return time && moment(time).format('HH:mm')
}

export const toCloneable = (obj) => {
	if (Array.isArray(obj)) {
		return obj.map((v) => toCloneable(v))
	}
	if (!isObject(obj)) {
		return obj
	}
	let cloneable = {}
	if (obj instanceof Error) {
		cloneable = {
			message: obj.message,
			stack: obj.stack,
			name: obj.name,
			code: obj.code,
		}
	}
	for (let prop in obj) {
		if (has(obj, prop)) {
			cloneable[prop] = toCloneable(obj[prop])
		}
	}
	return cloneable
}
export const safeJSON = {
	stringify: (v, _replacer = undefined, _spaces = undefined) => {
		try {
			return isString(v) ? v : JSON.stringify(toCloneable(v), _replacer, _spaces)
		} catch (e) {
			return v
		}
	},
	parse: (v, _reviver = undefined) => {
		try {
			return isString(v) ? JSON.parse(v, _reviver) : v
		} catch (e) {
			return v
		}
	},
}
const REGEX_VIRTUAL_LINK = /virtual:\/\/dstv_now_(canary|production|staging|develop)/gi
const replaceMapping = {
	PRODUCTION: 'https://ssl.dstv.com/api/dstv_now',
	CANARY: 'https://beta-ssl.dstv.com/api/dstv_now',
	STAGING: 'https://alpha-ssl.dstv.com/api/dstv_now',
	DEVELOP: 'https://alpha-ssl.dstv.com/api/dstv_now',
}
export const replaceVirtualLinks = (link) => {
	if (REGEX_VIRTUAL_LINK.test(link)) {
		return link.replace(REGEX_VIRTUAL_LINK, (_matched, code) => {
			let replacement = replaceMapping[code.toUpperCase()] || replaceMapping.PRODUCTION
			return replacement
		})
	}
	return link
}

export const Logger = {}
export const createLogger = (label, bg = '#03ffeb', fg = '#000000') => {
	let logger = {
		log: (...args) =>
			console.log(
				`%c ${label}: `,
				`background: ${bg}; color: ${fg}; padding:2px 4px; border-radius:50rem; text-transform:capitalize`,
				...args
			),
		error: (...args) =>
			console.error(
				`%c ${label}: `,
				`background: ${bg}; color: ${fg}; padding:2px 4px; border-radius:50rem; text-transform:capitalize`,
				...args
			),
	}
	let tag = label.replace(/\s+/gm, '_')
	Object.defineProperty(Logger, tag, { value: logger, enumerable: true })
	return Logger[tag]
}

export const getAudioLanguages = (player) => {
	return player.getAudioLanguagesAndRoles()
}

export const changeBandwidth = (player, maxBandwidth) => {
	try {
		maxBandwidth && player.configure({ abr: { restrictions: { maxBandwidth } } })
	} catch (e) {
		console.error('Error while changing bandwidth ', e)
	}
}

export const selectAudioLanguage = (player, language) => {
	try {
		language && player.selectAudioLanguage(language)
	} catch (e) {
		console.error('Error while changing audio language ', e)
	}
}

export const isLiveEvent = (startTime, endTime) => {
	const currentTime = moment()
	return moment(startTime) < currentTime && currentTime < moment(endTime)
}

export const isFutureEvent = (startTime, endTime) => {
	const currentTime = moment()
	return currentTime < moment(startTime) && currentTime < moment(endTime)
}
