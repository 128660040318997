import { createSlice } from '@reduxjs/toolkit'

export const ONBOARDING_FEATURE_KEY = 'onboarding'

/*
 * Create slice
 */
export const onboardingSlice = createSlice({
	name: ONBOARDING_FEATURE_KEY,
	initialState: { step: 'welcome' },
	reducers: {
		SET_ONBOARDING_STEP: (state, action) => {
			state.step = action.payload.step
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const onboardingReducer = onboardingSlice.reducer

/**
 * Export actions
 */
export const { SET_ONBOARDING_STEP } = onboardingSlice.actions
