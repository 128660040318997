import { SAVE_AUDIO_LANGUAGE, SAVE_BANDWIDTH } from '@dstv-web-leanback/dstv-frontend-services'
import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PlayerSettingsSubOptionItem from '../PlayerSettingsSubOptionItem/PlayerSettingsSubOptionItem'
import styles from './PlayerSettingsSubOptions.module.scss'

export function PlayerSettingsSubOptions({
	option,
	subOptions,
	selectedSubOption,
	defaultSubOption,
	setSelectedSubOption,
	handleBack,
}) {
	const dispatch = useDispatch()
	const { ref, focusKey } = useFocusable({
		onBackPress: () => {
			handleBack(option)
		},
	})

	useEffect(() => {
		setFocus('SubOption-' + defaultSubOption?.id)
	}, [setFocus, defaultSubOption?.id])

	const saveAudioLanguageAndBandwidth = (option, subOption) => {
		if (option?.mainHeading === 'Audio Language') {
			dispatch(SAVE_AUDIO_LANGUAGE({ language: subOption.language }))
		} else if (option?.mainHeading === 'Streaming Quality') {
			dispatch(SAVE_BANDWIDTH({ bandwidth: subOption.value }))
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<>
				<div className={styles.mainItem}>{option.mainHeading}</div>
				<div ref={ref} className={styles.subOptions}>
					{subOptions &&
						subOptions.map((item, index) => (
							<PlayerSettingsSubOptionItem
								key={item + index}
								item={item}
								focusKey={'SubOption-' + item?.id}
								selectedItem={selectedSubOption || defaultSubOption}
								setSelectedItem={(i) => {
									saveAudioLanguageAndBandwidth(option, i)
									setSelectedSubOption(i)
								}}
								handleBack={handleBack}
							/>
						))}
				</div>
			</>
		</FocusContext.Provider>
	)
}
export default PlayerSettingsSubOptions
