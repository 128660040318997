import { segmentAnalytics, analyticsPrint } from '@dstv-web-leanback/dstv-frontend-services'

export const segmentReporter = ({ type = null, pageName = null, ...data }, callback) => {
	const suppressSegment = Boolean(process.env.NX_SUPPRESS_SEGMENT)
	if (type) {
		try {
			segmentAnalytics
				.track(type, data.contentPayload)
				.then((res) => (!suppressSegment ? analyticsPrint.log('TRACK', res) : undefined))
				.catch((err) => (!suppressSegment ? analyticsPrint.error('TRACK_ERROR', err) : undefined))
		} catch (e) {
			console.error(e)
		} finally {
			callback && callback()
		}
	}
	if (pageName) {
		try {
			segmentAnalytics
				.page(pageName, data.pagePayload)
				.then((res) => (!suppressSegment ? analyticsPrint.log('PAGE', res) : undefined))
				.catch((err) => (!suppressSegment ? analyticsPrint.error('PAGEERROR', err) : undefined))
		} catch (e) {
			console.error(e)
		}
	}
}
