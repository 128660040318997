import { useCallback, useEffect, useRef, useState } from 'react'
import { GET_ENTITLEMENT } from '@dstv-web-leanback/dstv-frontend-services'
import { useDispatch, useSelector } from 'react-redux'

export const useEntitlementRefresh = (player, onHandleRequest, onHandleResponse) => {
	const dispatch = useDispatch()
	const entitlementState = useSelector((state) => state.entitlement)
	const configState = useSelector((state) => state.config.data)
	const [shaka, setShaka] = useState(null)
	const sessionId = useRef()

	useEffect(() => {
		const loadShaka = async () => {
			const playerVersion = configState?.shaka_player_version?.payload?.player_version
			if (playerVersion) {
				let shakaModule
				if (playerVersion === '4.6.0') {
					shakaModule = await require('shaka-460')
				} else {
					shakaModule = await require('shaka-435')
				}
				setShaka(shakaModule)
			}
		}
		loadShaka()
	}, [])

	useEffect(() => {
		if (!entitlementState.data) {
			loadEntitlement()
		}
	}, [entitlementState.data])

	useEffect(() => {
		if (entitlementState.data && entitlementState.loading === false) {
			const { decoded } = entitlementState.data

			const expTime = decoded?.exp
			const currentTime = Math.floor(new Date().getTime() / 1000)
			const sessionExpiryTime = expTime - currentTime

			const refreshTimer = Math.max(sessionExpiryTime * 1000 - getBuffer(), 0)

			if (refreshTimer <= 0) {
				loadEntitlement()
			} else {
				const timeout = setTimeout(() => loadEntitlement(), refreshTimer)

				return () => {
					clearTimeout(timeout)
				}
			}
		}
	}, [entitlementState.data])

	useEffect(() => {
		if (player) {
			player.getNetworkingEngine().registerRequestFilter((type, request, context) => {
				if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
					handleLicenseRequest(request)
				}
			})

			player.getNetworkingEngine().registerResponseFilter((type, response, context) => {
				handleResponseFilters(response)
			})
		}
	}, [player])

	useEffect(() => {
		sessionId.current = entitlementState.data?.irdetoSession?.sessionId
	}, [entitlementState.data?.irdetoSession?.sessionId])

	const loadEntitlement = useCallback(() => {
		dispatch(GET_ENTITLEMENT())
	}, [])

	const getBuffer = () => {
		const hasBuffer = configState?.irdeto_ctr_pd_enabled?.flagValue === 'true'

		if (hasBuffer) {
			return +configState?.irdeto_ctr_pd_enabled?.payload?.buffer
		}

		return 0
	}

	const handleLicenseRequest = (request) => {
		onHandleRequest?.()
		if (sessionId.current) {
			const [baseUrl, requestParams] = request.uris[0].split('?')
			let requestParamsObject = {}

			requestParams.split('&').forEach((o) => {
				const [key, value] = o.split('=')
				requestParamsObject[key] = value
			})
			requestParamsObject['ls_session'] = sessionId.current

			const params = []
			Object.keys(requestParamsObject).forEach((key) => params.push(`${key}=${requestParamsObject[key]}`))
			request.uris[0] = `${baseUrl}?${params.join('&')}`
		}
	}

	const handleResponseFilters = (response) => {
		onHandleResponse?.(response)
	}

	return { entitlementState, loadEntitlement }
}

export default useEntitlementRefresh
