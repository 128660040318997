import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader, ProfileList } from '@dstv-web-leanback/dstv-frontend-components'
import {
	CLEAR_AVATARS,
	getSessionID,
	GET_PROFILES_LIST,
	PROFILE_FEATURE_KEY,
} from '@dstv-web-leanback/dstv-frontend-services'
import styles from './Profiles.module.scss'
import { useNavigate } from 'react-router-dom'
import dstvLogo from '../../../../assets/images/dstv_logo_fallback.png'

export function Profiles(props) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const profilesState = useSelector((state) => state[PROFILE_FEATURE_KEY])

	const profileCrud = useSelector((state) => state[PROFILE_FEATURE_KEY].profileCrud)

	const activeProfile = profilesState?.data?.items?.find((profile) => profile.active)

	const isFirstProfile =
		profilesState?.data?.items &&
		profilesState?.data?.items.length === 1 &&
		!profilesState?.data?.items[0].alias &&
		!profilesState?.data?.items[0].canDelete

	useEffect(() => {
		isFirstProfile && navigate(`/profiles/${profilesState?.data?.items[0].id}`)
	}, [isFirstProfile])

	useEffect(() => {
		if (getSessionID() && !profilesState?.data) {
			dispatch(GET_PROFILES_LIST())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (profileCrud) {
			dispatch(CLEAR_AVATARS())
			dispatch(GET_PROFILES_LIST())
		}
	}, [profileCrud, dispatch])

	return typeof profilesState?.loading === 'undefined' || profilesState?.loading ? (
		<Loader />
	) : (
		!isFirstProfile && profilesState?.data && profilesState?.data?.items && (
			<main className={styles['container']}>
				<section className={styles.content}>
					<img className={styles.dstv_logo} alt="DStv logo" src={dstvLogo} />

					<h3>Who's watching DStv?</h3>
					<ProfileList
						activeProfile={activeProfile}
						focusedProfileId={profilesState?.focusedProfileId}
						profileData={profilesState?.data}
					/>
				</section>
			</main>
		)
	)
}
export default Profiles
