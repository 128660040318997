import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import styles from './PlayerSettingsModalItem.module.scss'
export function PlayerSettingsModalItem({ item, defaultValue, setSelectedOption }) {
	const { ref, focused } = useFocusable({
		focusKey: 'PLAYER_MAIN_SETTINGS_' + item.id,
		onEnterPress: () => {
			setSelectedOption(item)
		},
	})

	return (
		<div ref={ref} className={[styles.mainItem, focused ? styles.focus : styles.white].join(' ')}>
			<div className={styles.textBlock}>
				<div className={styles.mainItem}>
					{item.mainHeading}
					<span className={[styles.caption, focused ? styles.focus : styles.white].join(' ')}>{defaultValue}</span>
				</div>
			</div>
		</div>
	)
}
export default PlayerSettingsModalItem
