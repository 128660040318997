// import { platform, TV_RESOLUTIONS } from '../constants/constants'
import { secureLocalStorage } from './secureStorage'
// import { irdetoCtrEnabled } from "../Utils/flagrHelper";

// export const getPlatFormType = () => {
//     return (localStorage.getItem('platformType') || platform.WEB)
// }

export const getProductType = () => {
	return localStorage.getItem('PRODUCT_TYPE')
}

export const getAdsPlatformName = () => {
	switch (getProductType()) {
		case 'TIZEN':
			return 'leanback_samsung'
		case 'WEBOS':
			return 'leanback_lg'
		case 'HISENSE':
			return 'leanback_hisense'
		case 'XBOX':
			return 'leanback_xbox'
		default:
			return 'leanback'
	}
}

// export const exitApplication = (showLauncher = false) => {
//     switch (getProductType()) {
//         case 'TIZEN':
//             tizen.application.getCurrentApplication().exit();
//             break;
//         case 'WEBOS':
//             if (showLauncher)
//                 webOS.platformBack()
//         case 'EXPLORA_ULTRA':
//             window.location.replace("about:blank");
//         default:
//             window.close();
//     }
// }

export const getDeviceName = () => {
	switch (getProductType()) {
		case 'TIZEN':
			try {
				return 'Samsung TV ' + webapis.productinfo.getRealModel()
			} catch (e) {
				return 'Samsung TV'
			}
		case 'WEBOS':
			try {
				var model = webOS.device.modelName || webOS.device.modelNameAscii
				return 'LG TV ' + model
			} catch (e) {
				return 'LG TV'
			}
		case 'HISENSE':
			try {
				return 'Hisense TV' + ' ' + Hisense_GetModelName() // eslint-disable-line no-undef
			} catch (e) {
				return 'Hisense TV'
			}
		case 'XBOX':
			try {
				var info =
					Windows.Security.ExchangeActiveSyncProvisioning.EasClientDeviceInformation().systemManufacturer +
					' ' +
					Windows.System.Profile.AnalyticsInfo.deviceForm
				return info
			} catch (e) {
				return 'XBOX'
			}
		case 'EXPLORA':
		case 'EXPLORA_ULTRA':
			return 'Explora Ultra'
		case 'STREAMA':
			return 'Streama MDMP100'
		default:
			try {
				if (navigator.userAgent.indexOf('Chrome') >= 0) {
					var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
					var version = raw ? parseInt(raw[2], 10) : false
					var deviceName = 'chrome' + ' ' + version
					return deviceName
				}
			} catch (e) {
				return 'chrome'
			}
			break
	}
}

export const getOSVersion = () => {
	switch (getProductType()) {
		case 'TIZEN':
			try {
				let tizenOSVersion

				if (tizen?.systeminfo?.getPlatformVersion) {
					tizenOSVersion = tizen.systeminfo.getPlatformVersion()
				} else {
					const match = navigator.userAgent.match(/Tizen\s([0-9]+\.[0-9]+)/)
					tizenOSVersion = match ? match[1] : 'NA'
				}
				return tizenOSVersion
			} catch (e) {
				return 'NA'
			}
		case 'WEBOS':
			try {
				return webOS.platform.version
			} catch (e) {
				const userAgentVersion = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/)

				if (userAgentVersion) {
					const version = parseInt(userAgentVersion[1])
					if (version >= 79) {
						return '6.0'
					} else if (version === 68) {
						return '5.0'
					} else if (version === 53) {
						return '4.0'
					} else {
						return 'NA'
					}
				} else {
					return 'NA'
				}
			}
		case 'HISENSE':
			try {
				if (navigator.userAgent.includes('NT72671D') || navigator.userAgent.includes('VIDAA/5.0')) return '5.0'
				else if (navigator.userAgent.includes('VIDAA/6.0')) return '6.0'
				else if (navigator.userAgent.includes('VIDAA/7.0')) return '7.0'
				else if (navigator.userAgent.includes('MSD6886') || navigator.userAgent.includes('MT9602')) return '4.0'
				else return 'NA'
			} catch (e) {
				return 'NA'
			}
		case 'XBOX':
			return 'NA'
		case 'EXPLORA_ULTRA':
			return 'NA'
		case 'STREAMA':
			return 'NA'
		case 'CHROME_CAST':
			return '1'
		default:
			return 'NA'
	}
}

export const getSecurityLevel = () => {
	if (getOSVersion() !== 'NA' && localStorage.getItem('PRODUCT_TYPE') === 'WEBOS') {
		return 'L1'
	} else if (getOSVersion() === 'NA' && localStorage.getItem('PRODUCT_TYPE') === 'WEBOS') {
		return 'NA'
	}
	if (localStorage.getItem('PRODUCT_TYPE') === 'XBOX') return 'SL2000'
	else if (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && parseInt(getOSVersion()) <= 4.0) return 'SL2000'
	if (localStorage.getItem('PRODUCT_TYPE') === 'STREAMA') return 'SL3000' //L1
	else if (getOSVersion() === 'NA') return 'NA'
	if (localStorage.getItem('PRODUCT_TYPE') === 'CHROME_CAST') return 'L1'
	else return 'SL3000'
}

export const getOsName = () => {
	switch (getProductType()) {
		case 'TIZEN':
			return 'Tizen'

		case 'WEBOS':
			return 'Web0S'

		case 'HISENSE':
			return 'Vidaa'

		case 'XBOX':
			return 'NA'

		case 'EXPLORA_ULTRA':
			return 'Explora Ultra'

		case 'STREAMA':
			return 'NA'

		case 'CHROME_CAST':
			return 'Chromecast'

		default:
			try {
				if (navigator.userAgent.indexOf('Chrome') >= 0) {
					var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
					var version = raw ? parseInt(raw[2], 10) : false
					var deviceName = 'chrome' + ' ' + version
					return deviceName
				}
			} catch (e) {
				return 'chrome'
			}
	}
}

export const getHdcpComplaince = () => {
	if (localStorage.getItem('PRODUCT_TYPE') === 'STREAMA') return 'Disconnected'
	if (getOSVersion() !== 'NA' && localStorage.getItem('PRODUCT_TYPE') === 'WEBOS') return 'Disconnected'
	else if (localStorage.getItem('PRODUCT_TYPE') === 'XBOX') return 'available'
	else if (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && parseInt(getOSVersion()) <= 4.0) return 'unavailable'
	else if (getOSVersion() === 'NA') return 'unavailable'
	else if (localStorage.getItem('PRODUCT_TYPE') === 'CHROME_CAST') return 'Connected'
	else return 'available'
}
export const getDrm = () => {
	if (localStorage.getItem('PRODUCT_TYPE') === 'WEBOS' || localStorage.getItem('PRODUCT_TYPE') === 'TIZEN')
		return 'widevine'
	switch (getProductType()) {
		case 'EXPLORA_ULTRA':
		case 'STREAMA':
		case 'CHROME_CAST':
			return 'widevine'
		default:
			return 'playready'
	}
}

export const getDeviceType = () => {
	switch (getProductType()) {
		case 'XBOX':
			return 'Xbox'
		case 'EXPLORA_ULTRA':
			return 'web'
		case 'STREAMA':
			return 'Streama MDMP100'
		case 'CHROME_CAST':
			return 'chromecast'
		default:
			return 'Smart TV'
	}
}
