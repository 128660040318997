import styles from './PlayerButton.module.scss'

import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function PlayerButton({
	focusKey,
	icon,
	onSelect,
	label,
	labelHeader,
	labelPosition = 'left',
	onNavigate,
	size = 'large',
	iconReversed = false,
	iconSize,
}) {
	const { ref, focused } = useFocusable({
		onArrowPress: onNavigate,
		onEnterPress: onSelect,
		focusKey: focusKey,
	})

	return (
		<div
			ref={ref}
			className={`${styles.player_button} ${focused ? styles.focus : ''} ${
				label || labelHeader ? styles.has_label : ''
			} ${size === 'small' && focused ? styles.small : ''}`}
		>
			{focused && labelPosition === 'left' && (label || labelHeader) ? (
				<div className={styles.label_left}>
					<span>{labelHeader && <div>{labelHeader}</div>}</span>
					<span className={styles[size]}>{label && <div>{label}</div>}</span>
				</div>
			) : undefined}

			{icon && (
				<img
					src={icon}
					className={[
						iconReversed ? styles.reversed : '',
						size === 'small' && focused ? styles.small : '',
						iconSize === 'large' && !focused ? styles.large_icon : '',
					].join(' ')}
				/>
			)}

			{focused && labelPosition === 'right' && (label || labelHeader) ? (
				<div className={styles.label_right}>
					<span>{labelHeader && <div>{labelHeader}</div>}</span>
					<span className={styles[size]}>{label && <div>{label}</div>}</span>
				</div>
			) : undefined}
		</div>
	)
}

export default PlayerButton
