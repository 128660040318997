export const validateImage = (url, callback) => {
	let img = new Image()
	img.onload = function () {
		callback('show', img)
	}
	img.onerror = function (err) {
		callback(err, null)
	}
	img.src = url
}
