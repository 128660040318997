import { PAGE_SIZE } from '../../utils/environment'

export const transformChannelgroups = (channelgroups) => {
	let result = []

	channelgroups?.items?.forEach((item) => {
		result.push({
			id: item.id,
			channelLogo: item?.images?.[0]?.href,
			channelName: item?.title,
			url: item?.links?.[0]?.href,
		})
	})

	return result
}

export const appendPageNumber = (url, page = 0) => {
	return `${url};page=${page};${PAGE_SIZE}`
}
