import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'

import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'

export const SEARCH_FEATURE_KEY = 'search'

/*
 * Create our slice
 */
export const searchSlice = createSlice({
	name: SEARCH_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_ALL_SEARCH_RESULTS: (state, action) => {
			delete state.error
			delete state.serverError
		},
		SEARCH_SUCCESS: (state, action) => {
			if (state.searchKey?.length) {
				state.items = action.payload.list
				if (action.payload.list.length) {
					state.notFound = false
				} else {
					state.suggestions = null
					state.notFound = true
				}
			}
		},
		UPDATE_SEARCH_KEY: (state, action) => {
			delete state.error
			delete state.serverError
			state.searchKey = action.payload.searchKey
			state.selectedChip = action.payload.suggestionSelected ? state.searchKey : null
		},
		SEARCH_ERROR: (state, action) => {
			state.isLoading = false
			state.error = action.payload?.error
			state.serverError = action.payload?.serverError
		},
		CLEAR_SEARCH: (state) => {
			state.items = null
			state.searchKey = ''
			state.suggestions = null
		},
		GET_SEARCH_LANDING_CONTENT: (state) => {
			state.isLoading = true
			delete state.error
			delete state.serverError
		},
		SEARCH_CONTENT_SUCCESS: (state, action) => {
			state.contents = action.payload.items
			state.isLoading = false
		},
		GET_SEARCH_SUGGESTIONS: (state) => {
			state.suggestions = null
			delete state.error
			delete state.serverError
		},
		SEARCH_SUGGESTION_SUCCESS: (state, action) => {
			state.suggestions = action.payload.list
		},
		SHOW_SEARCH_TOAST: (state, action) => {
			state.toastId = action.payload.id
			state.toastMessage = action.payload.message
		},
		SEARCHED: (state) => {
			return state
		},
		SEARCH_OPENED: (state) => {
			return state
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const searchReducer = searchSlice.reducer

/*
 * Export actions
 */

export const {
	SEARCH_SUCCESS,
	GET_ALL_SEARCH_RESULTS,
	UPDATE_SEARCH_KEY,
	SEARCH_ERROR,
	CLEAR_SEARCH,
	GET_SEARCH_LANDING_CONTENT,
	SEARCH_CONTENT_SUCCESS,
	GET_SEARCH_SUGGESTIONS,
	SEARCH_SUGGESTION_SUCCESS,
	SHOW_SEARCH_TOAST,
	SEARCHED,
	SEARCH_OPENED,
} = searchSlice.actions

/*
 * Set up the redux-observable epic
 */

export const searchEpic = (action$) =>
	action$.pipe(
		ofType(GET_ALL_SEARCH_RESULTS.type, GET_SEARCH_LANDING_CONTENT.type, GET_SEARCH_SUGGESTIONS.type),
		mergeMap(searchService(action$))
	)

const CAROUSEL_TYPES = ['events', 'vod', 'layouts', 'channels', 'vod_with_progress']

/*
 * Do API calls
 */

const searchService = (action$) => (action) => {
	const { payload } = action
	switch (action.type) {
		case GET_ALL_SEARCH_RESULTS.type: {
			return HTTP.GET(
				ENV.GET_SEARCH.replace('{SEARCH_TERM}', encodeURIComponent(payload.searchKey)),
				getHeaders(),
				showAll(payload),
				showError(action, SEARCH_ERROR.type),
				true
			)
		}
		case GET_SEARCH_SUGGESTIONS.type: {
			return HTTP.GET(
				ENV.GET_SEARCH_SUGGESTIONS.replace('{SEARCH_TERM}', encodeURIComponent(payload.searchKey)),
				getHeaders(),
				showSuggestion(payload),
				showError(action, SEARCH_ERROR.type),
				true
			)
		}
		case GET_SEARCH_LANDING_CONTENT.type: {
			return HTTP.GET(
				ENV.GET_SEARCH_LANDING_CONTENT,
				getHeaders(),
				showContent(payload),
				showError(action, SEARCH_ERROR.type),
				true
			)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
export const showAll = (action) => (response) => {
	let list = []
	const searchItems = response?.items
	searchItems?.length &&
		searchItems.reduce((acc, item) => {
			acc.push({
				...item,
				poster_image: getCardPosterImageURL(item, 'small_16x9'),
				poster_image_landscape: getCardPosterImageURL(item, 'small_16x9'),
			})
			return acc
		}, list)
	return { type: SEARCH_SUCCESS.type, payload: { list: list, searchKey: action.searchKey } }
}

export const showError = (action, type) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

export const showContent = (action) => (response) => {
	const nonEmptyRows = []
	response.items?.reduce((acc, row) => {
		const valid_row = Array.isArray(row.items) && row.items.length > 0 && CAROUSEL_TYPES.includes(row.type)
		let card_shown = false
		if (valid_row) {
			const { features } = row
			card_shown =
				(features.includes('small_16x9') || features.includes('poster')) &&
				!(features.includes('contained') || features.includes('play_in_card'))
		}
		if (card_shown) {
			acc.push({
				...row,
				items: row.items.map((item) => ({
					...item,
					poster_image: getCardPosterImageURL(item, 'poster'),
					poster_image_landscape: getCardPosterImageURL(item, 'poster-landscape'),
				})),
			})
		}
		return acc
	}, nonEmptyRows)

	return {
		type: SEARCH_CONTENT_SUCCESS.type,
		payload: { items: nonEmptyRows },
	}
}

export const showSuggestion = (action) => (response) => {
	let list = []
	const suggestedItems = response?.items || []

	list.push(...suggestedItems.map((item) => item))

	return {
		type: SEARCH_SUGGESTION_SUCCESS.type,
		payload: { list: list, searchKey: action.searchKey },
	}
}
