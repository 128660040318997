import { useState, useEffect } from 'react'

import styles from './SettingsItem.module.scss'
import { Button, ButtonGroup } from '@dstv-web-leanback/dstv-frontend-components'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function SettingsItem({
	button,
	buttonGroup,
	focusKey,
	inactive,
	index,
	isChecked,
	itemFocus,
	onSelect,
	showCheckbox,
	subtitle,
	title,
}) {
	const { ref, focused } = useFocusable({
		focusable: inactive ? false : true,
		onEnterPress: onSelect,
		onFocus: () => {
			ref && itemFocus && itemFocus(ref)
		},
		focusKey: `OPTION_${focusKey}_${index}`,
	})
	const [active, setActive] = useState(false)

	useEffect(() => {
		setActive(focused)
	}, [focused])

	return (
		<div
			ref={ref}
			className={`
				${styles.settings_item}
				${active ? styles.focus : ''}
				${showCheckbox ? styles.show_checkbox : ''}
				${isChecked ? styles.checked : ''}`}
		>
			<div>
				<h3>{title}</h3>
				<h5>{subtitle}</h5>
			</div>
			<div>
				{button && <Button id={button.title} title={button.title} active={focused ? true : false} />}
				{buttonGroup && <ButtonGroup buttons={buttonGroup.buttons} setActive={setActive} />}
			</div>
		</div>
	)
}
export default SettingsItem
