import { useRef, useState, useEffect } from 'react'

import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'

import {
	Card16x9,
	CardIplate,
	CardPortrait,
	CardChannelSubnav,
	CardVOD16x9,
	FloatingGuide,
	CardBillboard,
	SingleEventBillboard,
	ExpandableCard,
} from '@dstv-web-leanback/dstv-frontend-components'
import { getCardTypeByFeatures } from '@dstv-web-leanback/dstv-frontend-utils'
import styles from './Carousel.module.scss'

export function Carousel({
	cards,
	carouselIndex,
	onCarouselFocus,
	title,
	type,
	features,
	initialIndex = 0,
	initialFocus,
	initialScroll = 0,
	cardLimit,
	currentSection,
	viewAll,
	sidebarHidden,
	...rest
}) {
	const { focusKey, ref, hasFocusedChild } = useFocusable({
		preferredChildFocusKey: initialFocus,
		saveLastFocusedChild: rest.saveLastFocusedChild ? true : false,
		trackChildren: true,
	})

	const carouselWrapper = useRef()
	const [carouselScroll, setCarouselScroll] = useState(initialScroll)
	const [focusedIndex, setFocusedIndex] = useState(initialIndex)
	const [showFloatingGuide, setShowFloatingGuide] = useState(false)

	const changeCarouselFocus = (focusedRef, item, index) => {
		if (focusedRef?.current && ref?.current) {
			setTimeout(() => {
				if (focusedRef?.current && ref?.current) {
					setCarouselScroll(focusedRef.current.offsetLeft - Number.parseInt(getComputedStyle(ref.current)?.paddingLeft))
				}
			}, 1)
			setFocusedIndex(index)
			setShowFloatingGuide(false)
			onCarouselFocus &&
				onCarouselFocus(carouselWrapper.current, carouselIndex, item, rest.id, index, cardType, rest.offset)
		}
	}

	const shouldRenderCard = (index) => {
		if (cardLimit) {
			return index >= focusedIndex - 1 && index <= focusedIndex + (cardLimit - 1)
		} else {
			return true
		}
	}

	const cardType = getCardTypeByFeatures(features, type)
	const bigCard = cardType === 'big16x9'
	const cardContained = cardType === 'containedcard'
	const posterNumberedCard = cardType === 'posternumbered'
	const midrailbillboard = cardType === 'midrailbillboard'
	const singleEventBillboard = cardType === 'singleeventbillboard' && carouselIndex === 0
	const cardComponents = {
		iplate: CardIplate,
		channels: Card16x9,
		vod: CardPortrait,
		vod_with_progress: CardVOD16x9,
		channelgroups: CardChannelSubnav,
		small16x9: CardVOD16x9,
		big16x9: CardVOD16x9,
		poster3x4: CardPortrait,
		posterleadtitle: CardPortrait,
		posternumbered: CardPortrait,
		episodecard16x9: CardVOD16x9,
		containedcard: CardVOD16x9,
		contentpage: CardVOD16x9,
		livecard16x9: Card16x9,
		midrailbillboard: CardBillboard,
		singleeventbillboard: SingleEventBillboard,
		expandablecard16x9: ExpandableCard,
		recording: CardVOD16x9,
	}

	const Card = cardComponents[cardType] ? cardComponents[cardType] : CardPortrait

	useEffect(() => {
		if (
			(type === 'vod_with_progress' || type === 'layouts' || (currentSection === 'mystuff' && focusedIndex !== 0)) &&
			hasFocusedChild &&
			sidebarHidden
		) {
			const timer = setTimeout(() => {
				setShowFloatingGuide(true)
			}, 3000)
			return () => clearTimeout(timer)
		}
	}, [type, hasFocusedChild, focusedIndex, sidebarHidden])

	useEffect(() => {
		if (!hasFocusedChild) {
			setShowFloatingGuide(false)
		}
	}, [hasFocusedChild])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={carouselWrapper} className={styles.carousel_wrapper}>
				{!midrailbillboard && !(cardContained || singleEventBillboard) && title && (
					<h3 className={`${styles.title} ${hasFocusedChild && styles.active} ${bigCard && styles.big_card_title}`}>
						{title}
					</h3>
				)}
				<div
					ref={ref}
					className={`${styles.carousel} ${bigCard && styles.large} ${hasFocusedChild && styles.active} ${
						posterNumberedCard && styles.numberedCard
					} ${midrailbillboard && styles.midrailbillboard}
					${cardContained ? styles.contained_card_container : ''}`}
					style={{ transform: `translate3d(-${carouselScroll}px,0,0)` }}
				>
					{cards?.map(
						(item, index) =>
							shouldRenderCard(index) && (
								<Card
									index={index}
									item={item}
									itemFocus={changeCarouselFocus}
									rowId={rest.id}
									rowTitle={title}
									cardType={cardType}
									carouselIndex={carouselIndex}
									features={features}
									currentSection={currentSection}
									viewAll={viewAll}
									showFloatingGuide={showFloatingGuide}
									{...rest}
								/>
							)
					)}
				</div>
				{showFloatingGuide && <FloatingGuide />}
			</div>
		</FocusContext.Provider>
	)
}
export default Carousel
