import { useEffect, useState } from 'react'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
//import posterFallback from '../../../../assets/images/smarttv/poster_fallback.png'
import { useSelector } from 'react-redux'
import styles from './SingleEventBillboard.module.scss'
export function SingleEventBillboard({
	index,
	carouselIndex,
	features,
	rowTitle,
	item,
	rowId,
	key,
	cardType,
	itemFocus,
	type,
	from,
	...rest
}) {
	const billboardState = useSelector((state) => state?.billboard)
	let info = billboardState?.info
	const { getCardDetails, onSelect } = rest
	const { ref, focused } = useFocusable({
		onFocus: () => {
			if (ref) itemFocus(ref, item, index)
			if (rest.getCardDetails && !item.fetched_info) {
				getCardDetails(item, rowId, index)
			}
		},
		onEnterPress: () => {
			onSelect(
				{
					...item,
					carouselIndex,
					rowTitle,
					features,
					rowId,
					comingUp: info?.isComingUp,
				},
				item?.type?.toLowerCase() === 'event'
			)
		},
		focusKey: `CARD_${rowId ? rowId : ''}${index}`,
	})

	useEffect(() => {
		if (index === 0 && item?.tracking_info?.row === 0) getCardDetails(item, rowId)

		if (getCardDetails && !item.fetched_info && focused && item) {
			getCardDetails(item, rowId)
		}
	}, [item, focused])

	const singleEventBillboard = cardType === 'singleeventbillboard' && carouselIndex === 0

	return (
		<div
			ref={ref}
			className={`${styles.card_container} ${focused ? styles.active : ''} ${
				singleEventBillboard ? styles.hidden : ''
			}`}
		>
			<div className={`${styles['card_image_container']}`}>
				<img className={styles['card_image']} src={item?.poster_image_landscape} alt="Card Poster" />
			</div>
		</div>
	)
}
export default SingleEventBillboard
