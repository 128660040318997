import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getEpisodeDisplayItemTitle, getSeasonEpisodeDetails } from '../billboard/billboardTransformHelper'
import moment from 'moment'
import { isLiveEvent, isFutureEvent } from '../../utils/utils'
import { PLAY } from '../player/player.slice'
export const EVENTS_FEATURE_KEY = 'events'

export const initialState = {
	loading: false,
	reverseEpgChannelEvents: null,
	error: null,
	selectedChannelEventId: null,
	currentEventId: null,
	title: null,
	displayItemDetailedTitle: null,
	playerDetails: null,
	isLiveEvent: null,
	channelTag: null,
	channelLogo: null,
	channelNumber: null,
	channelName: null,
	channelCategory: null,
}

export const eventsSlice = createSlice({
	name: EVENTS_FEATURE_KEY,
	initialState,
	reducers: {
		CLEAR_REVERSE_EPG_CHANNEL_EVENTS: (state, action) => {
			Object.assign(state, initialState)
		},
		REVERSE_EPG_CHANNEL_EVENTS: (state, action) => {
			state.channelTag = action.payload.channelTag
			state.channelLogo = action.payload.channelLogo
			state.channelNumber = action.payload.channelNumber
			state.channelName = action.payload.channelName
			state.channelCategory = action.payload.channelCategory
		},
		REVERSE_EPG_CHANNEL_EVENTS_SUCCESS: (state, action) => {
			state.reverseEpgChannelEvents = action.payload.items
			state.refreshedDateTime = new Date()
		},
		REVERSE_EPG_CHANNEL_EVENTS_ERROR: (state, action) => {
			state.reverseEpgChannelEvents = null
		},
		SELECTED_CHANNEL_EVENT: (state, action) => {
			state.selectedChannelEventId = action.payload.selectedChannelEventId
			state.currentEventId = action.payload.currentEventId
			state.title = action.payload.title
			state.displayItemDetailedTitle = action.payload.displayItemDetailedTitle
			state.playerDetails = action.payload.playerDetails
			state.isLiveEvent = action.payload.isLiveEvent
		},
		UPDATE_LIVE_EVENT_ID: (state, action) => {
			state.reverseEpgChannelEvents = getTransformedItems(
				[...state.reverseEpgChannelEvents],
				action.payload.currentEventId
			)
			state.refreshedDateTime = new Date()
		},
		UPDATE_SELECTED_EVENT_TAG: (state, action) => {
			state.reverseEpgChannelEvents.forEach((item) => {
				item.tag = getTag(action.payload.selectedChannelEventId, item)
			})
		},
		UPDATE_FOCUSED_ITEM: (state, action) => {
			state.reverseEpgChannelEvents.forEach((item) => {
				if (action.payload.focusedEventId === item.id) {
					item.selected = true
				} else {
					delete item.selected
				}
			})
		},
		DELETE_SELECTED_ITEMS: (state, action) => {
			state.reverseEpgChannelEvents.forEach((item) => delete item.selected)
		},
		UPDATE_PREV_NEXT_EVENT: (state, action) => {
			state.nextEvent = action.payload.nextEvent
			state.prevEvent = action.payload.prevEvent
		},
		CHANNEL_LINEUP_BUTTON_CLICKED: (state, action) => state,
		REVERSE_EPG_EVENT_CARD_CLICKED: (state, action) => state,
		PLAY_EVENT_BUTTON_CLICKED: (state, action) => state,
		GET_RECORDING_STATUS: (state) => {
			delete state.correlation_id
			delete state.recording
		},
		GET_RECORDING_STATUS_SUCCESS: (state, action) => {
			if (state.reverseEpgChannelEvents) {
				const recording = {
					title: action.payload.title,
					recorded: action.payload.recorded,
					recordable: action.payload.recordable,
					links: action.payload.links,
					isFutureEvent: action.payload.isFutureEvent,
				}

				state.reverseEpgChannelEvents?.forEach((item) => {
					if (action.payload.correlation_id === item.correlation_id) {
						item.recording = recording
					}
				})

				state.recording = recording
				state.correlation_id = action.payload.correlation_id
			}
		},
		GET_RECORDING_STATUS_ERROR: (state, action) => {
			state.reverseEpgChannelEvents?.forEach((item) => {
				if (action.payload.correlation_id === item.correlation_id) {
					delete item.recording
				}
			})
		},
		TOGGLE_RECORDING: (state) => {
			delete state.toastError
		},
		TOGGLE_RECORDING_SUCCESS: (state, action) => {
			if (state.reverseEpgChannelEvents) {
				const recording =
					action.payload.method === 'POST'
						? {
								title: action.payload.title,
								recorded: action.payload.recorded,
								recordable: action.payload.recordable,
								links: action.payload.links,
								isFutureEvent: action.payload.isFutureEvent,
						  }
						: undefined

				state.reverseEpgChannelEvents.forEach((item) => {
					if (action.payload.correlation_id === item.correlation_id) {
						item.recording = recording
					}
				})

				state.recording = recording
				state.correlation_id = action.payload.correlation_id

				state.toastMessage =
					action.payload.title +
					(action.payload.method === 'POST'
						? recording.isFutureEvent
							? ' will be added to My Stuff once it finishes airing.'
							: ' has been added to My Stuff.'
						: ' was successfully removed.')
			}
		},
		TOGGLE_RECORDING_ERROR: (state, action) => {
			if (state.reverseEpgChannelEvents) {
				state.reverseEpgChannelEvents.forEach((item) => {
					if (action.payload.correlation_id === item.correlation_id) {
						delete item.recording
					}
				})

				state.toastMessage = `We're having trouble ${
					action.payload.method === 'POST' ? 'adding' : 'removing'
				} this event right now. Please try again later.`
				state.toastError = true
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(PLAY.type, (state) => {
			delete state.toastError
			delete state.toastMessage
			delete state.recording
			delete state.correlation_id
		})
	},
})
/*
 * Export reducer for store configuration.
 */
export const eventsReducer = eventsSlice.reducer

/*
 * Export actions
 */
export const {
	CLEAR_REVERSE_EPG_CHANNEL_EVENTS,
	REVERSE_EPG_CHANNEL_EVENTS,
	REVERSE_EPG_CHANNEL_EVENTS_SUCCESS,
	REVERSE_EPG_CHANNEL_EVENTS_ERROR,
	SELECTED_CHANNEL_EVENT,
	UPDATE_SELECTED_EVENT_TAG,
	UPDATE_PREV_NEXT_EVENT,
	CHANNEL_LINEUP_BUTTON_CLICKED,
	REVERSE_EPG_EVENT_CARD_CLICKED,
	UPDATE_FOCUSED_ITEM,
	DELETE_SELECTED_ITEMS,
	UPDATE_LIVE_EVENT_ID,
	PLAY_EVENT_BUTTON_CLICKED,
	GET_RECORDING_STATUS,
	GET_RECORDING_STATUS_SUCCESS,
	GET_RECORDING_STATUS_ERROR,
	TOGGLE_RECORDING,
	TOGGLE_RECORDING_ERROR,
	TOGGLE_RECORDING_SUCCESS,
} = eventsSlice.actions

/*
 * Set up the redux-observable epic
 */
export const eventsEpic = (action$) =>
	action$.pipe(
		ofType(REVERSE_EPG_CHANNEL_EVENTS.type, GET_RECORDING_STATUS.type, TOGGLE_RECORDING.type),
		mergeMap(eventsService(action$))
	)

/*
 * Do API calls
 */
const eventsService = (action$) => (action) => {
	switch (action.type) {
		case REVERSE_EPG_CHANNEL_EVENTS.type: {
			const endpoint = ENV.REVERSE_EPG.replace('{channel_tag}', action.payload.channelTag)
				.replace('{past_hours}', action.payload.pastHours)
				.replace('{future_hours}', action.payload.futureHours)

			return HTTP.GET_WITH_CANCEL(
				endpoint,
				getHeaders(),
				reverseEpgSuccess(action),
				reverseEpgError(action),
				true,
				action$.pipe(ofType(REVERSE_EPG_CHANNEL_EVENTS.type))
			)
		}
		case GET_RECORDING_STATUS.type: {
			return HTTP.GET_WITH_CANCEL(
				action.payload.url,
				getHeaders(),
				getRecordingStatusSuccess(action),
				getRecordingStatusError(action),
				true,
				action$.pipe(ofType(GET_RECORDING_STATUS))
			)
		}
		case TOGGLE_RECORDING.type: {
			if (action.payload.method === 'POST') {
				return HTTP.POST(
					action.payload.url,
					null,
					getHeaders(),
					toggleRecordingSuccess(action),
					toggleRecordingError(action),
					true
				)
			} else if (action.payload.method === 'DELETE') {
				return HTTP.DELETE(
					action.payload.url,
					null,
					getHeaders(),
					toggleRecordingSuccess(action),
					toggleRecordingError(action),
					true
				)
			}
			break
		}
	}
}

const getTag = (currentEventId, item) => {
	let tag = ''
	if (currentEventId === item.id) {
		tag = 'Watching'
	} else if (isLiveEvent(item.start_date_time, item.end_date_time)) {
		tag = 'On Now'
	}
	return tag
}

const getTransformedItems = (items, currentEventId) => {
	const newItems = items.map((item, index) => {
		return {
			...item,
			tag: getTag(currentEventId, item),
			allowPastEventRewatch: item?.features?.allowPastEventRewatch,
			displayTitle: item.main_title,
			displayItemDetailedTitle: getEpisodeDisplayItemTitle(item.season_number, item.episode_number, item.episode_title),
			episodeSeason: getSeasonEpisodeDetails(item.season_number, item.episode_number),
			genres: item.primary_genre.split('|'),
			shortSynopsis: item.synopsis,
			ratingDetails: `${item.rating}  ${item.maturity_classification.split(',').join('')}`,
			image: item.images?.[0]?.href,
			startTime: moment(item.start_date_time).format('HH:mm'),
			endTime: moment(item.end_date_time).format('HH:mm'),
			timeShiftUrl: item.time_shift_stream?.dash,
			reverseEpgEventUrl: item.replay_stream?.dash,
			title: item.main_title,
			episode_title: getEpisodeDisplayItemTitle(item.season_number, item.episode_number, item.episode_title),
			isLive: isLiveEvent(item.start_date_time, item.end_date_time),
			isFutureEvent: isFutureEvent(item.start_date_time, item.end_date_time),
			channelTag: item.channel_tag,
			index,
		}
	})
	return newItems
}
const reverseEpgSuccess = (action) => (response) => {
	if (response) {
		let currentEventId = action.payload.currentEventId
		if (action.payload.isPlayingEventLive) {
			// highlight current live event
			const liveEvent = response?.items.find((item) => isLiveEvent(item.start_date_time, item.end_date_time))
			currentEventId = liveEvent?.id || currentEventId
		}

		const items = response?.items && getTransformedItems(response.items, currentEventId)

		if (action.payload.isPlayingEventLive) {
			items.forEach((item) => {
				if (isLiveEvent(item.start_date_time, item.end_date_time)) {
					item.selected = true
				} else {
					delete item.selected
				}
			})
		}
		return {
			type: REVERSE_EPG_CHANNEL_EVENTS_SUCCESS.type,
			payload: {
				items: items,
			},
		}
	} else {
		return of({
			type: REVERSE_EPG_CHANNEL_EVENTS_ERROR.type,
		})
	}
}
const reverseEpgError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: REVERSE_EPG_CHANNEL_EVENTS_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const getRecordingStatusSuccess = (action) => (response) => {
	return {
		type: GET_RECORDING_STATUS_SUCCESS.type,
		payload: {
			...response,
			title: action.payload.title,
			correlation_id: action.payload.correlation_id,
			isFutureEvent: action.payload.isFutureEvent,
		},
	}
}

const getRecordingStatusError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_RECORDING_STATUS_ERROR.type,
				payload: {
					...errorResponse(response, action),
					correlation_id: action.payload.correlation_id,
				},
			},
			action
		)
	)
}

const toggleRecordingSuccess = (action) => (response) => {
	return {
		type: TOGGLE_RECORDING_SUCCESS.type,
		payload: {
			...response,
			correlation_id: action.payload.correlation_id,
			method: action.payload.method,
			title: action.payload.title,
			isFutureEvent: action.payload.isFutureEvent,
		},
	}
}

const toggleRecordingError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: TOGGLE_RECORDING_ERROR.type,
				payload: {
					...errorResponse(response, action),
					correlation_id: action.payload.correlation_id,
					method: action.payload.method,
				},
			},
			action
		)
	)
}
