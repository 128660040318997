import CryptoJS from 'crypto-js'
import SecureStorage from 'secure-web-storage'

let SECRET_KEY = '@#$2345678@#$%^'

let hashStorage = {
	hash: function hash(key) {
		key = CryptoJS.SHA256(key, SECRET_KEY)
		return `${key.toString()}`
	},
	encrypt: function encrypt(data) {
		data = CryptoJS.AES.encrypt(data, SECRET_KEY)
		data = data.toString()
		return data || null
	},
	decrypt: function decrypt(data) {
		data = CryptoJS.AES.decrypt(data, SECRET_KEY)
		data = data.toString(CryptoJS.enc.Utf8)
		return data || null
	},
}
export const secureLocalStorage = new SecureStorage(localStorage, hashStorage)

export const secureSessionStorage = new SecureStorage(sessionStorage, hashStorage)

// export const secureBookmarkStorage = new SecureStorage(bookmarkStorage, hashStorage)
