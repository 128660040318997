import styles from './Card16x9.module.scss'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { getCardLogoURL } from '@dstv-web-leanback/dstv-frontend-services'
import { useEffect, useState } from 'react'

import play_icon from '../../../../assets/images/smarttv/play_cw_icon.svg'
import play_icon_active from '../../../../assets/images/smarttv/play_cw_focus_icon.svg'
import thumbFallback from '../../../../assets/images/thumb_fallback.png'
import { useIntersectionObserver, dateUtils, channelLive } from '@dstv-web-leanback/dstv-frontend-utils'
import Image from '../../../core/Image/LazyImage'

export function Card16x9({ index, item, rowId, rowTitle, itemFocus, type, carouselIndex, ...rest }) {
	const { getCardDetails, onSelect, features } = rest

	const eventType = type === 'event'
	const dateText = eventType && item?.start_date_time && dateUtils.formatDate(item.start_date_time)
	const [isLive, labelId, labelText] = channelLive(item)
	const [shouldShowLabel] = useState(['coming_up', 'expired'].includes(labelId))
	const [canAction, setCanAction] = useState(false)

	const { ref, focused } = useFocusable({
		onFocus: () => {
			if (ref) itemFocus(ref, item, index)

			if (rest.getCardDetails && !item.fetched_info) {
				getCardDetails(item, rowId, index)
			}
		},
		onEnterPress: () => {
			setTimeout(() => {
				setCanAction(true)
			}, 250)
		},
		onEnterRelease: () => {
			if (canAction) {
				onSelect({
					...item,
					carouselIndex,
					rowTitle,
					features,
					rowId,
					comingUp: shouldShowLabel,
				})
			}
		},
		focusKey: `CARD_${rowId}${index}`,
	})

	const isIntersecting = useIntersectionObserver(ref)

	const [channelLogoSrc, setChannelLogoSrc] = useState(
		item?.type === 'channels' ? getCardLogoURL(item, 'logo') : getCardLogoURL(item, 'channel_logo')
	)

	useEffect(() => {
		if (index === 0 && item?.tracking_info?.row === 0) {
			getCardDetails?.(item, rowId, index)
		}
	}, [])

	const handleImageError = (e, channel_logo) => {
		e.target.onerror = null
		if (channel_logo) {
			setChannelLogoSrc(null)
		}
	}

	return (
		<div
			ref={ref}
			className={`${styles.container} ${focused ? styles.active : ''} ${eventType ? styles.card_meta : ''}`}
		>
			{isIntersecting && (
				<div className={styles.card}>
					<div className={styles.overlay}>
						<div className={styles.gradient_overlay}></div>
						<div className={styles.channel_logonumber}>
							{channelLogoSrc ? (
								<img
									className={styles.channel_logo_icon}
									alt="Channel logo"
									src={channelLogoSrc}
									loading="lazy"
									onError={(e) => handleImageError(e, true)}
								/>
							) : null}
							<div className={styles.channel_no}>{item?.number ? item.number : item.channel_number}</div>
						</div>

						{shouldShowLabel && item?.label ? (
							<p className={`${styles.event_label} ${item.label?.id === 'expired' ? styles.expired_label : ''}`}>
								{labelText}
							</p>
						) : null}

						{!shouldShowLabel ? (
							<div className={styles.play_buttonsmall}>
								<img className={styles.play_icon} alt="" src={focused ? play_icon_active : play_icon} />
							</div>
						) : null}
						<Image
							className={styles.image}
							src={item.poster_image || thumbFallback}
							alt="Event thumbnail"
							fallback={thumbFallback}
						/>
					</div>
				</div>
			)}
			{eventType && (
				<div className={styles.card_info}>
					<p className={styles.card_title}> {item.title}</p>
					<p className={styles.time_info}>
						{!isLive ? `${dateText} • ` : ''}
						{item.start_date_time && dateUtils.formatTimeToHoursAndMinutes(item.start_date_time)} {'-'}{' '}
						{item.end_date_time && dateUtils.formatTimeToHoursAndMinutes(item.end_date_time)}
						{isLive && item.duration_in_seconds && ` • ${dateUtils.convertSecsToHrsMins(item.duration_in_seconds)}`}
					</p>
				</div>
			)}
		</div>
	)
}
export default Card16x9
