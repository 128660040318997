import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { PULSE_CONSTANTS } from '../constants'
import { useEffect } from 'react'

function PulsePauseControls({ styles, closeAd, handleFocusableVal }) {
	const { focusKey, ref } = useFocusable({
		focusKey: PULSE_CONSTANTS.FOCUSABLE.PULSE_PAUSE_CONTROLS,
		forceFocus: true,
		isFocusBoundary: true,
		onEnterPress: closeAd,
	})

	useEffect(() => {
		handleFocusableVal(PULSE_CONSTANTS.FOCUSABLE.PULSE_PAUSE_CONTROLS)
	}, [])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.skipControlsWrapper}>
				<div className={`${styles.pulseBtn} ${styles.pulsePauseButton} ${styles.effect2} ${styles.active}`}>
					<span>{PULSE_CONSTANTS.TEXT.CLOSE_BTN}</span>
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default PulsePauseControls
