import { setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

export const focusMainNavigation = () => {
	setFocus(`MENU_${window.location.hash.split('/')[1].toUpperCase()}`)
}

export const getErrorDetails = (stateSlices) => {
	const errorSlice = stateSlices.find((state) => state.error !== null && state.error !== undefined)

	return {
		error: errorSlice.error || '',
		serverError: errorSlice.serverError || '',
	}
}

export const DEFAULT_CLOUD_DVR_EVENT_WINDOW = 24
