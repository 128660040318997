import { useMemo } from 'react'
import { Carousel } from '@dstv-web-leanback/dstv-frontend-components'

import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'

import styles from './Iplate.module.scss'

export function Iplate({ active, cards = [], onSelect, initialFocus, handleBack, onCarouselFocus }) {
	const { focusKey, ref } = useFocusable({
		focusKey: 'IPLATE',
		focusable: active,
		isFocusBoundary: true,
		onBackPress: handleBack,
	})

	const initialScroll = useMemo(() => {
		const index = cards.findIndex((card) => initialFocus.indexOf(card.id) > -1)
		if (index > 0) {
			return index * 427
		}

		return 0
	}, [cards, initialFocus])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.iplate} ${active && styles.expanded}`}>
				<Carousel
					cards={cards}
					type="iplate"
					onSelect={onSelect}
					visibleScroll={true}
					initialFocus={initialFocus}
					initialScroll={initialScroll}
					onCarouselFocus={onCarouselFocus}
				/>
			</div>
		</FocusContext.Provider>
	)
}
export default Iplate
