import { useEffect, useCallback, useRef, useState } from 'react'

/**
 *
 * @param {number} delay
 * @param {(completed: boolean) => void} onDelayComplete
 * @returns {[function, function]}
 */
export const useCancelDebounce = (delay, onDelayComplete) => {
	const timer = useRef(null)
	const [canHold, setCanHold] = useState(true)
	const [held, setHeld] = useState(false)
	const [hasDebounced, setHasDebounced] = useState(false)

	const cancelDebounce = useCallback(() => {
		if (!held && hasDebounced) {
			onDelayComplete?.(false)
			clearTimeout(timer.current)
			timer.current = null
		}
		setCanHold(true)
		setHeld(false)
	}, [held, hasDebounced])

	const debounce = useCallback(() => {
		if (!timer.current && canHold) {
			setHasDebounced(true)
			setCanHold(false)
			timer.current = setTimeout(() => {
				setHeld(true)
				onDelayComplete?.(true)
				timer.current = null
			}, delay)
		}
	}, [delay, canHold, onDelayComplete])

	const reset = useCallback(() => {
		setHeld(false)
		setCanHold(true)
		if (timer.current) {
			clearTimeout(timer.current)
			timer.current = null
		}
	}, [])

	useEffect(() => {
		return () => {
			setCanHold(true)
			setHeld(false)
			clearTimeout(timer.current)
			timer.current = null
		}
	}, [])
	return [debounce, cancelDebounce, reset]
}

export default useCancelDebounce
