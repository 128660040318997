import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'

import { useSelector } from 'react-redux'

export const ShakaPlayer = forwardRef(({ url, config, onLoaded, onError, onBuffer, ...rest }, ref) => {
	const videoRef = useRef(null)

	const [player, setPlayer] = useState(null)
	const { reloadStream } = useSelector((state) => state.adPlayer)
	const { bookmark } = useSelector((state) => state.player)
	const configState = useSelector((state) => state.config.data)
	const [shaka, setShaka] = useState(null)
	const playerRef = useRef()
	useEffect(() => {
		const loadShaka = async () => {
			const playerVersion = configState?.shaka_player_version?.payload?.player_version

			if (playerVersion) {
				let shakaModule
				if (playerVersion === '4.6.0') {
					shakaModule = await require('shaka-460')
				} else {
					shakaModule = await require('shaka-435')
				}
				setShaka(shakaModule)
			}
		}

		loadShaka()

		return () => {
			if (!playerRef.current) return
			playerRef.current.removeEventListener('error', handleStreamingError)
			playerRef.current.removeEventListener('buffering', onBuffer)
			playerRef.current.removeEventListener('stalldetected', onStallDetected)
			playerRef.current.destroy()
		}
	}, [])

	useEffect(() => {
		if (!shaka) return

		playerRef.current = new shaka.Player(videoRef.current)
		shaka.polyfill?.installAll()
		setPlayer(playerRef.current)
		playerRef.current.addEventListener('error', handleStreamingError)
		playerRef.current.addEventListener('buffering', onBuffer)
		playerRef.current.addEventListener('stalldetected', onStallDetected)
	}, [shaka])

	// configure shaka
	useEffect(() => {
		if (player && config) {
			player.configure(config)
		}
	}, [player, config])

	// load stream
	useEffect(() => {
		if (player && url) {
			loadUrl(url, bookmark)
		}
	}, [player, url])

	// reload stream
	useEffect(() => {
		if (!reloadStream) return
		const { time } = reloadStream
		if (player && url) {
			loadUrl(url, time)
		}
	}, [reloadStream])

	useImperativeHandle(
		ref,
		() => ({
			get player() {
				return player
			},
			get videoElement() {
				return videoRef.current
			},
		}),
		[player]
	)

	const loadUrl = async (url, time) => {
		try {
			await player.load(url, time)
			if (onLoaded) {
				onLoaded(videoRef.current)
			} else {
				await videoRef.current.play()
			}
		} catch (e) {
			throwError?.(e)
		}
	}

	function throwError(error) {
		onError(error)
	}

	function onStallDetected(evt) {
		console.warn(evt)
	}

	function handleStreamingError(error) {
		if (error instanceof Error) {
			// shaka crashed with an unhandled native error
			throwError(error)
		}

		if (error.detail.severity === shaka.util.Error.Severity.CRITICAL) {
			// handle fatal error, playback can not continue
			throwError(error)
		} else {
			// handle non-fatal error, playback can continue
			player.retryStreaming()
		}
	}

	return <video ref={videoRef} {...rest} />
})

export default ShakaPlayer
