import { FocusContext, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { HOUR_WIDTH, BREAKPOINT_ICON_ONLY, BREAKPOINT_NO_CONTENT } from '@dstv-web-leanback/dstv-frontend-services'
import { dateUtils } from '@dstv-web-leanback/dstv-frontend-utils'

import playicon from '../../../assets/images/play.png'
import styles from './ChannelEvent.module.scss'

export function ChannelEvent({ currentTime, event, gridStart, itemFocus, playChannel, rowIndex }) {
	const { ref, focused, focusKey } = useFocusable({
		onFocus: () => {
			ref && itemFocus(ref, event, rowIndex)
		},
		onEnterPress: () => {
			if (isCurrentEvent()) {
				playChannel()
			}
		},
		focusable: event.end > currentTime,
		focusKey: `EVENT_${event?.channelTag}_${event?.index}`,
	})

	const getEventTimeLabel = () => {
		return dateUtils.formatTimeToHoursAndMinutes(event.start)
	}

	const isCurrentEvent = () => {
		return event.start < currentTime && currentTime < event.end
	}

	const getRemainingTimeInMins = () => {
		return Math.floor(dateUtils.getTimeBetweenDates(event.end, currentTime))
	}

	const isRemainingTimeLessThanOneHour = () => {
		return getRemainingTimeInMins() < 60
	}

	const getRemainingTimeText = () => {
		return `${getRemainingTimeInMins()}m remaining`
	}

	const shouldRenderText = () => {
		let minWidth = isCurrentEvent() && focused ? BREAKPOINT_ICON_ONLY : BREAKPOINT_NO_CONTENT
		return event.position.width >= minWidth
	}

	const shouldRenderIcon = () => {
		return isCurrentEvent() && focused && event.position.width >= BREAKPOINT_NO_CONTENT
	}

	const getEventPosition = () => {
		let positionLeft = event.position.left - gridStart.hours() * HOUR_WIDTH
		let elementWidth = event.position.width

		if (gridStart.isAfter(event.start)) {
			const startHours = gridStart.hours() + gridStart.minutes() / 60
			positionLeft = startHours * HOUR_WIDTH - gridStart.hours() * HOUR_WIDTH
			elementWidth = (event.end.diff(gridStart, 'minutes') / 60) * HOUR_WIDTH
		}

		return {
			left: `${positionLeft}px`,
			width: `${elementWidth}px`,
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.channel_event_wrapper} style={{ ...getEventPosition() }}>
				<div className={`${styles.channel_event} ${focused ? styles.focus : ''}`}>
					{shouldRenderText() && (
						<div className={styles.channel_event_left}>
							<h3 className={focused ? styles.active : ''}>{event.title}</h3>
							<p className={focused ? styles.active : ''}>
								{isCurrentEvent() && isRemainingTimeLessThanOneHour() ? getRemainingTimeText() : getEventTimeLabel()}
							</p>
						</div>
					)}
					{shouldRenderIcon() && (
						<div className={`${styles.channel_event_right} ${!shouldRenderText() ? styles.center : ''}`}>
							<img src={playicon} />
						</div>
					)}
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default ChannelEvent
