import { useEffect } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { Button, Countdown } from '@dstv-web-leanback/dstv-frontend-components'

import playIcon from '../../../assets/images/icons/icon-play.svg'
import overlayGradient from '../../../assets/images/overlay_gradient.svg'
import styles from './AutoPlayNextPopup.module.scss'

export function AutoPlayNextPopup({
	title,
	heading,
	subHeading,
	primaryButtonTitle,
	primaryButtonAction,
	secondaryButtonTitle,
	secondaryButtonAction,
	backAction,
	countDownCounter,
	withIcon = true,
	initAction,
	size = 'small',
	withAnimation = false,
	showOverlay = false,
	withBackground = true,
}) {
	const { focusKey, focusSelf, ref } = useFocusable({
		isFocusBoundary: true,
		onBackPress: () => {
			backAction && backAction()
		},
	})

	const buttons = [
		{
			icon: withIcon ? playIcon : null,
			onSelect: primaryButtonAction,
			title: primaryButtonTitle,
			withAnimation,
		},
		{
			title: secondaryButtonTitle,
			onSelect: secondaryButtonAction,
		},
	]

	useEffect(() => {
		initAction()
		focusSelf()
	}, [])

	return (
		<FocusContext.Provider value={focusKey}>
			{showOverlay && <img src={overlayGradient} className={styles.overlay} />}
			<div
				ref={ref}
				className={[styles.autoplay_popup, styles[size], withBackground ? styles.background : ''].join(' ')}
			>
				<div className={styles.autoplay_header}>
					<p>{title}</p>
					{countDownCounter && <Countdown countDownTimer={Math.max(countDownCounter, 0)} />}
				</div>
				<div className={styles.autoplay_body}>
					<h2>{heading}</h2>
					<h3>{subHeading}</h3>
				</div>
				<div className={styles.buttons_wrapper}>
					{buttons.map((button, index) => (
						<Button id={index} key={index} {...button} />
					))}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default AutoPlayNextPopup
