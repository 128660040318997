import styles from './BootupAnimation.module.scss'
// TODO: lottie animation reference for future
// import data from '../../../assets/images/smarttv/bootup_animation.json'
import bootupImage from '../../../assets/images/dstv_login_logo.png'
import { useEffect } from 'react'

export function BootupAnimation({ onComplete }) {

	useEffect(() => {
		if (onComplete) {
			setTimeout(onComplete, 1000)
		}
	}, [onComplete])

	return (
		<div className={styles['container']}>
			{/* <LottieAnimation loop={false} onComplete={onComplete} animationData={data} style={{ height: '35vw' }} /> */}
			<img src={bootupImage} alt="" />
		</div>
	)
}
export default BootupAnimation
