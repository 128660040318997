import React from 'react'
import styles from './ProgressBar.module.scss'

export function ProgressBar({ progress, active = true }) {
	const progressBarClassName = active ? `${styles.progress_bar} ${styles.active}` : styles.progress_bar
	const progressClassName = active ? `${styles.progress} ${styles.active}` : styles.progress

	return (
		<div className={progressBarClassName}>
			<div className={progressClassName} style={{ width: `${Math.min(progress, 100)}%` }}></div>
		</div>
	)
}

export default ProgressBar
