import { useEffect } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'

import { ChannelBlock, ChannelEventsRow, Timeline } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './ChannelsContainer.module.scss'

export function ChannelsContainer({
	changeGridFocus,
	channelIndex,
	channels,
	channelScroll,
	currentTime,
	eventScroll,
	eventWindow,
	focusable,
	getDayEvents,
	gridFocused,
	gridStart,
	playChannel,
	setGridFocused,
}) {
	const { focusKey, hasFocusedChild, ref } = useFocusable({
		focusKey: 'CHANNELS_CONTAINER',
		trackChildren: true,
		saveLastFocusedChild: false,
		isFocusBoundary: true,
		focusBoundaryDirections: ['left'],
		focusable: focusable,
	})

	useEffect(() => {
		setGridFocused(hasFocusedChild)
	}, [hasFocusedChild])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.channels_container} ${gridFocused ? styles.active : ''}`}>
				<div className={styles.scroll_container} style={{ transform: `translate3d(0, -${channelScroll}px, 0)` }}>
					<aside className={styles.grid_sidebar}>
						<header>
							<h3 className={styles.day_label}>Today</h3>
						</header>
						<div className={styles.channel_logos}>
							{channels.map((channel, index) => {
								if (index >= channelIndex - 1 && index <= channelIndex + 5) {
									return (
										<ChannelBlock
											channel={channel}
											getDayEvents={getDayEvents}
											active={gridFocused && index === channelIndex}
										/>
									)
								}
							})}
						</div>
					</aside>
					<div className={styles.events_wrapper}>
						<div style={{ transform: `translate3d(-${eventScroll}px, 0, 0)` }}>
							<header>
								<Timeline gridStart={gridStart} currentTime={currentTime} />
							</header>
							{channels.map((channel, index) => {
								if (index >= channelIndex - 1 && index <= channelIndex + 5) {
									return (
										<div className={styles.channels_row}>
											<ChannelEventsRow
												currentTime={currentTime}
												eventWindow={eventWindow}
												channel={channel}
												gridStart={gridStart}
												itemFocus={changeGridFocus}
												playChannel={playChannel}
												rowIndex={index}
											/>
										</div>
									)
								}
							})}
						</div>
					</div>
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default ChannelsContainer
