import { useEffect, useState } from 'react'
import styles from './SectionNavItem.module.scss'

import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function SectionNavItem({
	active,
	index,
	title,
	itemFocus,
	item,
	id,
	handleArrow,
	isFocused,
	currentSeasonId,
	onLoadSeasonChange,
	onSelect,
}) {
	const { ref, focused, onFocus, focusKey } = useFocusable({
		onFocus: () => {
			if (ref) itemFocus(index, ref, item)
		},
		focusKey: `SUBMENU_${index}`,
		onArrowPress: handleArrow,
		onEnterPress: () => {
			onSelect?.(undefined, item)
		},
	})

	return (
		<div
			ref={ref}
			className={`${styles.section_nav_item} ${active ? styles.active : ''} ${focused ? styles.focus : ''} ${
				!focused ? styles.seasonTray : ''
			} ${isFocused && !focused ? styles.trayHighlight : ''}`}
			onFocus={onFocus}
		>
			{title}
		</div>
	)
}
export default SectionNavItem
